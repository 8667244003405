<template>
  <div class="bim-qmodel">
    <qmviewer
      :modelName="name"
      :isCompont="isCompont"
      :isLocal="isLocal"
      @callFather_loaded="callFather_loaded"
    >
    </qmviewer>
  </div>
</template>

<script>
import qmviewer from "./components/qmviewer.vue"
export default {
  components: { qmviewer },
  data() {
    return {
      isLocal: true,
      isCompont: true,
    }
  },
  props: {
    name: String,
  },
  methods: {
    callFather_loaded() {},
  },
}
</script>

<style lang="less" scoped>
.bim-qmodel {
  width: 100%;
  height: 100%;
}
#qmodel-div {
  // inset: unset !important;
  left: unset !important;
  top: 0px !important;
  transform: none;
  transform-style: flat;
}
</style>
