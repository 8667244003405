<template>
  <div
    class="inspect-quality"
    :style="`background-image: url('${backgroundImage}')`"
  >
    <div class="inspect-quality-detail">
      <div class="inspect-quality-detail-name">空气质量（AQI）</div>
      <div class="inspect-quality-detail-value">
        <div class="inspect-quality-detail-value-num">{{ currenApi }}</div>
        <div class="inspect-quality-detail-value-unit">μg/m³</div>
      </div>
      <div
        class="inspect-quality-detail-label"
        :style="`background: ${background}`"
      >
        {{ tipName }}
      </div>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web"
export default {
  props: {
    currenApi: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tipName() {
      if (this.currenApi >= 0 && this.currenApi <= 50) return "优秀"
      else if (this.currenApi > 50 && this.currenApi <= 100) return "良好"
      else if (this.currenApi > 100 && this.currenApi <= 150) return "轻度污染"
      else if (this.currenApi > 150 && this.currenApi <= 200) return "中度污染"
      else if (this.currenApi > 200 && this.currenApi <= 300) return "重度污染"
      else if (this.currenApi > 300 && this.currenApi <= 500) return "严重污染"
    },
    background() {
      if (this.currenApi >= 0 && this.currenApi <= 50) return "#56D6C7"
      else if (this.currenApi > 50 && this.currenApi <= 100) return "#FBD22C"
      else if (this.currenApi > 100 && this.currenApi <= 150) return "#FB8802"
      else if (this.currenApi > 150 && this.currenApi <= 200) return "#FB0001"
      else if (this.currenApi > 200 && this.currenApi <= 300) return "#970454"
      else if (this.currenApi > 300 && this.currenApi <= 500) return "#62001E"
    },
    backgroundImage() {
      if (this.currenApi >= 0 && this.currenApi <= 50) return require("../../../../../assets/image/env/aqi_bg.gif")
      else if (this.currenApi > 50 && this.currenApi <= 100) return require("../../../../../assets/image/env/lianghao_icon.gif")
      else if (this.currenApi > 100 && this.currenApi <= 150) return require("../../../../../assets/image/env/qingdu_icon.gif")
      else if (this.currenApi > 150 && this.currenApi <= 200) return require("../../../../../assets/image/env/zhongdu_icon.gif")
      else if (this.currenApi > 200 && this.currenApi <= 300) return require("../../../../../assets/image/env/zhongdu2_icon.gif")
      else if (this.currenApi > 300 && this.currenApi <= 500) return require("../../../../../assets/image/env/yanzhong_icon.gif")
    },
  },
  // mounted () {
  //     setTimeout(() => {
  //         debugger
  //         lottie.loadAnimation({
  //             container: this.$refs.bg, // 包含动画的dom元素
  //             renderer: 'svg', //渲染出来的是什么格式
  //             loop: true,  //循环播放
  //             autoplay: true, //自动播放
  //             path: '../../../plugins/data.json' // 动画json的路径
  //         })
  //     }, 20)
  // }
}
</script>

<style lang="less" scoped>
.inspect-quality {
  width: 488px;
  height: 489px;
  // background-image: url('../../../assets/image/env/aqi_bg.gif');
  &-detail {
    width: 314px;
    height: 301px;
    padding: 94px 87px;
    &-name {
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      padding: 71px 80px 0px 96px;
    }
    &-value {
      display: flex;
      text-align: center;
      margin-top: 9px;
      justify-content: center;
      align-items: flex-end;
      margin-left: 20%;
      &-num {
        font-size: 64px;
        font-family: Bebas;
        color: #ffffff;
        line-height: 85px;
      }
      &-unit {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        padding-bottom: 10px;
      }
    }
    &-label {
      margin-top: 15px;
      width: 120px;
      height: 40px;
      border-radius: 20px;
      margin: 15px 97px 63px 97px;
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }
}
</style>
