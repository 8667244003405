<template>
  <div class="bim">
    <qmodel
      v-if="show"
      :name="name"
      :key="name"
    />
    <div class="bim-select">
      <data-select v-model="name" />
    </div>
  </div>
</template>

<script>
import DataSelect from "./data-select.vue"
import qmodel from "./qmodel.vue"
export default {
  components: { DataSelect, qmodel },
  data() {
    return {
      name: "珠海市斗门区保障性住房二期工程_5栋结构",
      show: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.show = true
    })
  },
}
</script>

<style lang="less" scoped>
.bim {
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  &-select {
    position: absolute;
    top: 10px;
    right: 0px;
    z-index: 1000;
  }
}
</style>
