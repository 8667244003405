import { render, staticRenderFns } from "./gauge.vue?vue&type=template&id=522c9089&scoped=true"
import script from "./gauge.vue?vue&type=script&lang=js"
export * from "./gauge.vue?vue&type=script&lang=js"
import style0 from "./gauge.vue?vue&type=style&index=0&id=522c9089&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522c9089",
  null
  
)

export default component.exports