<template>
  <div
    class="select"
    :class="{ 'select--expanded': visible }"
    v-click-outside="onClickOutside"
  >
    <div
      class="select-input"
      @click="onClick"
    >
      <input
        :value="displayLabel"
        placeholder="请选择"
        readonly
      />
      <img
        class="icon"
        src="../../../../../assets/image/arrow.png"
      />
    </div>
    <ul class="list scrollbar">
      <li
        class="list-item"
        v-for="(item, index) in data"
        :key="'overall' + index"
        :class="{ 'list-item--active': currentValue == item.type }"
        @click="onSelect(item)"
      >
        <span>{{ item.label }}</span>
      </li>
    </ul>
    <div class="border lt"></div>
    <div class="border rt"></div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside"
export default {
  props: {},
  watch: {
    value(val) {
      if (this.currentValue !== val) this.currentValue = val
    },
    data(newData) {
      if (newData && this.currentValue) {
        let index = newData.findIndex((it) => it.type === this.currentValue)
        if (index !== -1) return
        this.currentValue = ""
      }
    },
  },
  computed: {
    displayLabel() {
      let index = this.data.findIndex((it) => it.type === this.currentValue)
      if (this.data && this.data.length) {
        if (index == -1) {
          this.currentValue = this.data[0].type
        }
        return index == -1 ? `${this.data[0].label}` : `${this.data[index].label}`
      } else {
        return index == -1 ? "请选择" : `${this.data[index].label}`
      }
    },
  },
  data() {
    return {
      visible: false,
      currentValue: "1",
      data: [
        {
          type: "year",
          label: "本年",
        },
        {
          type: "month",
          label: "近30天",
        },
        {
          type: "week",
          label: "近7天",
        },
      ],
    }
  },
  methods: {
    onClick() {
      if (this.data && this.data.length) {
        this.visible = !this.visible
      }
    },
    onSelect(item) {
      this.currentValue = item.type
      this.visible = false
      this.$emit("currentType", item.type)
    },
    onClickOutside() {
      this.visible = false
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>
<style lang="less" scoped>
.select {
  height: 36px;
  background: rgba(6, 41, 57, 1);
  box-sizing: border-box;
  width: 114px;
  position: relative;
  padding: 0 14px;
  cursor: pointer;
  &::before {
    position: absolute;
    content: "";
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(64, 213, 255, 1);
  }
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(64, 213, 255, 1);
  }
  &-input {
    height: 100%;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      flex: 1;
      user-select: none;
      background: rgba(6, 41, 57, 1);
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #40d5ff;
      cursor: pointer;
      text-overflow: ellipsis;
      /*去除阴影*/
      box-shadow: none;
      /*聚焦input的蓝色边框*/
      outline: none;
      /*textarea 禁止拖拽*/
      resize: none;
      /*去除边框*/
      border: none;
      /*常用于IOS下移除原生样式*/
      -webkit-appearance: none;
      /*点击高亮的颜色*/
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      &::placeholder {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #40d5ff;
      }
    }
    .icon {
      flex: none;
      width: 18px;
      height: 18px;
      transition: 0.3s;
    }
  }
  .list {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    background: rgba(6, 41, 57, 0.7);
    border: 1px solid rgba(64, 213, 255, 1);
    visibility: hidden;
    box-sizing: border-box;
    opacity: 0;
    transition: 0.3s;
    padding: 6px 0;
    transform: translateY(35px);
    max-height: 187px;
    overflow: auto;
    &-item {
      padding: 0 14px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #40d5ff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: transparent;
      &:hover {
        background: rgba(7, 51, 72, 1);
      }
      &.list-item--active {
        color: #fff;
        background: rgba(7, 51, 72, 1);
      }
    }
  }
  &.select--expanded {
    .list {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
    }
    .icon {
      transform: rotate(180deg);
    }
  }
}
.border {
  position: absolute;
  content: "";
  width: 1px;
  top: 0;
  bottom: 0;
  background: rgba(64, 213, 255, 1);
  &.lt {
    left: 0;
  }
  &.rt {
    right: 0;
  }
}
</style>
