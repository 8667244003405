<template>
    <div class="container warning">
        <div class="container__content">
            <div class="container__border"></div>
            <div class="container__title">今日异常报警</div>
        </div>
        <div class="table-list">
            <div>
                <ul class="table hd">
                    <li class="table-row">
                        <div class="table-row__cell type">报警设备</div>
                        <div class="table-row__cell content">报警内容</div>
                        <div class="table-row__cell time">报警时间</div>
                    </li>
                </ul>
            </div>
            <vue-seamless-scroll class="recordlist"
                v-if="data && data.length"
                :data="data"
                :class-option="classOption">
                <ul  class="table">
                    <li class="table-row" v-for="item in data" :key="item.id">
                        <div class="table-row__cell type">{{item.machineName}}</div>
                        <div class="table-row__cell content">{{item.contentTitle}}</div>
                        <div class="table-row__cell time">{{item.createTime}}</div>
                    </li>
                </ul>
            </vue-seamless-scroll>
            <div v-else class="empty">
                暂无数据
            </div>
        </div>
    </div>
</template>

<script>
import { fetchEnvironmentalMonitoringalarm } from '@/api'
import { mapGetters } from 'vuex'
export default {
    props: {
        machineCode: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            data: [],
            classOption: {
                singleHeight: 42,
            },
        }
    },
    computed: mapGetters(['projectId']),
    watch: {
        projectId: {
            immediate: true,
            handler (val) {
                if (val && this.machineCode) {
                    return fetchEnvironmentalMonitoringalarm(val, this.machineCode).then(({data}) => {
                        if (data) {
                            this.data = data
                        } 
                    })
                }
            }
        },
        machineCode: {
            immediate: true,
            handler (val) {
                if (val && this.projectId) {
                    return fetchEnvironmentalMonitoringalarm(this.projectId, val).then(({data}) => {
                        if (data) {
                            this.data = data
                        } 
                    })
                }
            }
        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.warning {
    height: 307px;
    .recordlist {
        height: 168px;
        overflow: hidden;
    }
    .empty {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 168px;
        text-align: center;
    }
    .table-list {
        padding: 18px 16px 23px 16px;
    }
    .table {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #40D5FF;
        .table-row {
            display: flex;
            height: 42px;
            align-items: center;
            background: rgba(7, 51, 72, 1);
            padding: 0 12px;
            &:nth-child(odd) {
                background: rgba(6, 41, 57, 1);
            }
            &__cell {
                white-space: nowrap;
                overflow: hidden;
                flex: none;
                &.type {
                    width: 95px;
                }
                &.content {
                    width: 135px;
                    padding-left: 24px;
                }
                &.time {
                    width: 111px;
                    padding-left: 24px;
                }
            }
        }
        &.hd {
            .table-row {
                background: rgba(7, 51, 72, 1)!important;
                color: #fff;
            }
        }
    }
}
</style>