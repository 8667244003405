<template>
    <vue-seamless-scroll class="recordlist"
        v-if="data && data.length"
        :data="data"
        :class-option="classOption">
        <ul  class="table">
            <li class="table-row" v-for="item in data" :key="item.id">
                <div class="table-row__cell time">{{item.time}}</div>
                <div class="table-row__cell type">{{item.type}}</div>
                <div class="table-row__cell name">{{item.name}}</div>
                <div class="table-row__cell team">
                    <marquee-text v-if="!isLoading" :text="item.team"></marquee-text>
                    <span v-else>{{ item.team }}</span>
                </div>
                <div class="table-row__cell entry">{{item.entry}}</div>
            </li>
        </ul>
    </vue-seamless-scroll>
    <div v-else class="empty">
        暂无数据
    </div>
</template>

<script>
import MarqueeText from '../../marquee-text'
export default {
    components: {
        MarqueeText
    },
    props: {
        data: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            classOption: {
                singleHeight: 44,
            },
            isLoading: false
        }
    }
}
</script>

<style lang="less" scoped>
.recordlist {
    height: 176px;
    overflow: hidden;
}
.table {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #40D5FF;
    .table-row {
        display: flex;
        height: 44px;
        align-items: center;
        background: rgba(6, 41, 57, 1);
        padding: 0 12px;
        &:nth-child(odd) {
            background: rgba(7, 51, 72, 1);
        }
        &__cell {
            white-space: nowrap;
            overflow: hidden;
            flex: none;
            &.time {
                width: 114px;
                padding-left: 0;
            }
            &.type {
                width: 24px;
                padding-left: 23px;
            }
            &.name {
                width: 60px;
                padding-left: 23px;
            }
            &.team {
                width: 135px;
                padding-left: 23px;
            }
            &.entry {
                flex: 1;
                padding-left: 23px;
            }
        }
    }
}
</style>