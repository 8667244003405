<template>
    <div class="spray"
        :class="{'spray--active': toggled}">
        <div class="spray-auto">
            <div class="spray-auto-word">自动喷淋</div>
            <label :class="['switch', {toggled}]" >
                <input type="checkbox" v-model="toggled" @click="toggle">
                <div class="slider round"></div>
            </label>
        </div>
        <div class="spray-handle" v-if="!toggled" @click="openSpray">
            <div class="spray-handle-word">启动喷淋</div>
            <div class="openTip" v-if="openTip">点击开启喷淋5分钟</div>
        </div>
        <div class="massage" v-if="isShow">未找到喷淋设备</div>
    </div>
</template>

<script>
import { getFindAutomaticSpray} from '@/api'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    data () {
        return {
            id: '',
            hasClose: '',
            toggled: false,
            hasClose: '',
            hasAutomatic: '',
            messgge: '',
            isShow: false,
            openTip: false
        }
    },
    computed: mapGetters(['projectId']),
    watch: {
        projectId: {
            immediate: true,
            handler (val) {
                if (val) {
                    // this.request(() => {
                        this.id = val
                        return getFindAutomaticSpray(val).then(({data}) => {
                            if (data) {
                                if (data.hasClose == '0') {
                                    this.toggled = false
                                } else {
                                    this.toggled = true
                                }
                                this.hasClose = data.hasClose
                                this.hasAutomatic = data.hasAutomatic
                                this.hasClose = data.hasClose
                            } else {
                                this.toggled = false
                            }
                        })
                    // })
                }
            }
        }
    },
    methods: {
        toggle (status) {
            if (this.hasClose) {
                this.hasAutomatic = '0'
                if (this.hasClose == '0') {
                    this.hasClose == '1'
                } else {
                    this.hasClose == '0'
                }
            }
            this.$nextTick(() => {
                axios.get(`/hero/ent/wss/environmentalMonitoring/editAutomaticSpray?projectId=${this.id}&hasAutomatic=${this.hasAutomatic}&hasClose=${this.hasClose}`).then(res => {
                    if (res.data.code == 200) {
                        this.openTip = true
                        var self = this
                        setTimeout(() => {
                            self.openTip = false
                        }, 2000)
                    } else {
                        this.isShow = true
                        this.toggled = !this.toggled
                        var self = this
                        setTimeout(() => {
                            self.isShow = false
                        }, 2000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            })
        },
        openSpray () {
            if (this.hasClose) {
                this.hasAutomatic = '1'
                if (this.hasClose == '0') {
                    this.hasClose == '1'
                } else {
                    this.hasClose == '0'
                }
            }
            this.$nextTick(() => {
                axios.get(`/hero/ent/wss/environmentalMonitoring/editAutomaticSpray?projectId=${this.id}&hasAutomatic=${this.hasAutomatic}&hasClose=${this.hasClose}`).then(res => {
                    if (res.data.code == 200) {
                        this.openTip = true
                        var self = this
                        setTimeout(() => {
                            self.openTip = false
                        }, 2000)
                    } else {
                        this.isShow = true
                        var self = this
                        setTimeout(() => {
                            self.isShow = false
                        }, 2000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.spray {
    display: flex;
    height: 36px;
    width: 220px;
    position: relative;
    &.spray--active {
        width: 120px;
    }
    &-handle {
        width: 76px;
        height: 26px;
        margin: 5px 14px 5px 22px;
        background-color:rgba(64,213,255,0.7);
        border-radius: 2px;
        cursor: pointer;
        &-word {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0px 10px;
            line-height: 26px;
        }
    }
    &-auto {
        display: flex;
        &-word {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40D5FF;
            padding: 11px 6px 11px 14px;
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 14px;
        margin: 11px 0px;
    }

    .switch input {display:none;}

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: -4px;
        bottom: 0px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background: rgba(64, 213, 255, 0.3);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #40D5FF;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 14px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}
.massage {
    position: fixed;
    top: 76px;
    left: 50%;
    margin-left: -76px;
    width: 152px;
    height: 36px;
    background: #FEF0F0;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FF0000;
    text-align: center;
    line-height: 36px;
}
.openTip {
    position: absolute;
    top: -40px;
    right: -10px;
    width: 123px;
    height: 36px;
    line-height: 36px;
    background: #061B27;
    opacity: 0.9;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
}
</style>