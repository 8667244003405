<template>
    <!-- 和风天气 -->
    <div class="he-plugin">
        <div class="weather">
            <div class="temp">{{temp}}℃</div>
            <div class="status">{{status}}</div>
        </div>
        <img class="icon" :src="`https://cdn.gongjiangyi.com/he/128/${icon}.png`"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { fetchWeather } from '@/api'
export default {
    data () {
        return {
            /// 温度
            temp: '20',
            /// 天气状况
            status: '多云',
            /// 图标
            icon: '100',
            /// 城市
        }
    },
    computed: mapGetters(['latlng']),
    watch: {
        latlng: {
            immediate: true,
            handler (val) {
                if (val) {
                    this.request(() => {
                        return fetchWeather(val).then(resp => {
                            if (resp.code === '200') {
                                const {icon, temp, text} = resp.now
                                console.log(resp.now)
                                this.icon = icon
                                this.temp = temp
                                this.status = text
                            }
                        })
                    }, 5 * 60 * 1000) /// 天气5分钟轮询一次
                }
            }
        }
    },
    destroyed() {

    }
}
</script>

<style lang="less" scoped="less">
.he-plugin {
    display: flex;
    align-items: center;
    .weather {
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        .temp {
            margin-bottom: 16px;
        }
    }
    .icon {
        display: block;
        margin-left: 8px;
        width: 64px;
        height: 64px;
    }
}
</style>