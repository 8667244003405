<template>
  <div class="container attendance">
    <div class="container__dataOverall">
      <div class="container__border"></div>
      <div class="container__title">今日考勤信息</div>
    </div>
    <div class="attendance-charts padding-h16">
      <div class="attendance-gauge h center">
        <div class="h center fixed">
          <gauge
            :max="totalBuilderNumber"
            :value="builderNumber"
            :color="builderColor"
            title="劳务工人"
            desc="当前在场劳务工人"
          />
          <div class="h-gaps24"></div>
          <gauge
            :max="totalEmpolyeeNumber"
            :value="empolyeeNumber"
            :color="empolyeeColor"
            title="项目人员"
            desc="当前在场项目人员"
            title-text-color="rgba(255, 165, 0, 1)"
          />
        </div>
        <div class="h-gaps24"></div>
        <div class="expanded">
          <bar :data="columnData" />
        </div>
      </div>
      <v-gaps :height="24" />
      <list :data="records" />
    </div>
  </div>
</template>

<script>
import Gauge from "./gauge"
import Bar from "./bar"
import List from "./list"
import VGaps from "../../v-gaps"
import { fetchAttendanceInfo } from "@/api"
import { mapGetters } from "vuex"
export default {
  components: {
    Gauge,
    Bar,
    List,
    VGaps,
  },
  data() {
    return {
      totalBuilderNumber: 0,
      totalEmpolyeeNumber: 0,
      empolyeeNumber: 0,
      builderNumber: 0,
      records: [],
      columnData: [],
      records: [],
      builderColor: ["#56D6C7", "#409BFF", "#40D5FF"],
      empolyeeColor: ["#FFEC00", "#FFA500", "#FFA500"],
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchAttendanceInfo(val).then(({ data }) => {
              Object.assign(this._data, data)
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.attendance {
  width: 616px;
  height: 468px;
}
</style>
