<template>
  <div class="container env">
    <div class="container__dataOverall">
      <div class="container__border"></div>
      <div class="container__title">环境监测</div>
      <div class="env-select">
        <equip-select
          v-model="equip"
          :data="equipList"
          @equipChange="equipChange"
        />
      </div>
    </div>
    <div class="grid">
      <div class="grid-item">
        <env-item
          :icon="icon"
          :value="temp"
          :threshold="tempThreshold"
          unit="℃"
          title="温度"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon2"
          :value="wet"
          :threshold="wetThreshold"
          unit="%"
          title="湿度"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon4"
          :value="wind"
          :threshold="wetThreshold"
          unit="级"
          title="风速"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon5"
          :value="direction"
          :text="direction"
          :threshold="directionThreshold"
          title="风向"
          font-size="22px"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon3"
          :value="noise"
          :threshold="noiseThreshold"
          unit="分贝"
          title="噪音"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon6"
          :value="pm2"
          :threshold="pm2Threshold"
          unit="μg/m³"
          title="PM 2.5"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon7"
          :value="tsp"
          :threshold="tspThreshold"
          unit="μg/m³"
          title="TSP"
        ></env-item>
      </div>
      <div class="grid-item">
        <env-item
          :icon="icon8"
          :value="pm10"
          :threshold="pm10Threshold"
          unit="μg/m³"
          title="PM 10"
        ></env-item>
      </div>
    </div>
  </div>
</template>

<script>
import EquipSelect from "./equipselect"
import EnvItem from "./env-item.vue"
import { fetchEnvMonitorData, fetchFindDeviceForComBox } from "@/api"
import { mapGetters } from "vuex"
// 环境监测
export default {
  components: {
    EquipSelect,
    EnvItem,
  },
  data() {
    return {
      icon: require("../../../../../assets/image/env/icon_temp.png"),
      icon2: require("../../../../../assets/image/env/icon_humidity.png"),
      icon3: require("../../../../../assets/image/env/icon_noise.png"),
      icon4: require("../../../../../assets/image/env/icon_wind.png"),
      icon5: require("../../../../../assets/image/env/icon_direction.png"),
      icon6: require("../../../../../assets/image/env/icon_pm2.5.png"),
      icon7: require("../../../../../assets/image/env/icon_pm10.png"),
      icon8: require("../../../../../assets/image/env/icon_tsp.png"),
      noise: 0,
      noiseThreshold: 0,
      pm2: 0,
      pm2Threshold: 0,
      pm10: 0,
      pm10Threshold: 0,
      temp: 0,
      tempThreshold: 0,
      tsp: 0,
      tspThreshold: 0,
      wet: 0,
      wetThreshold: 0,
      wind: 0,
      windThreshold: 0,
      direction: "暂无",
      directionThreshold: 0,
      equip: "",
      equipList: [],
      id: "",
      proId: "",
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.request(() => {
          //     return fetchEnvMonitorData(val).then(({data}) => {
          //         Object.assign(this._data, data)
          //     })
          // })
          this.proId = val
          return fetchFindDeviceForComBox(val).then(({ data }) => {
            if (data && data.length) {
              this.id = data[0].id
              this.equipList = data
              this.request(() => {
                return fetchEnvMonitorData(val, this.id).then(({ data }) => {
                  Object.assign(this._data, data)
                })
              })
            } else {
              this.equipList = []
            }
          })
        }
      },
    },
  },
  methods: {
    equipChange(id) {
      this.id = id
      fetchEnvMonitorData(this.proId, id).then(({ data }) => {
        Object.assign(this._data, data)
      })
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.env {
  height: 468px;
  display: flex;
  flex-direction: column;
  position: relative;
  &-select {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 171px;
  }
  .title {
    flex: none;
  }
  .grid {
    flex: 1;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 25% 25% 25% 25%;
    &-item {
      border-top: 1px solid rgba(64, 155, 255, 0.2);
      box-sizing: border-box;
      &:nth-child(2n-1) {
        border-right: 1px solid rgba(64, 155, 255, 0.2);
      }
    }
  }
}
</style>
