<template>
  <div class="grid">
    <div class="grid-item">
      <project-info />
      <v-gaps :height="16" />
      <attendance />
    </div>
    <div class="grid-item">
      <monitor />
      <v-gaps :height="16" />
      <issue-rectify :screenId="currentId" />
    </div>
    <div class="grid-item">
      <env />
      <v-gaps :height="16" />
      <rank-list :screenId="currentId" />
    </div>
  </div>
</template>
<script>
import Env from "@/view/project/components/data-overall/env"
import ProjectInfo from "@/view/project/components/data-overall/project-info"
import Attendance from "@/view/project/components/data-overall/attendance"
import Monitor from "@/view/project/components/data-overall/monitor"
import IssueRectify from "@/view/project/components/data-overall/issue-rectify"
import RankList from "@/view/project/components/data-overall/rank-list"
import VGaps from "@/view/project/components/v-gaps"
export default {
  name: "App",
  components: {
    Env,
    ProjectInfo,
    Attendance,
    VGaps,
    Monitor,
    IssueRectify,
    RankList,
  },
  props: {
    currentId: {
      type: String,
      default: "1",
    },
  },
  computed: {
    name() {
      return []
    },
  },
}
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 616px 800px 440px;
  grid-column-gap: 16px;
}
</style>
