<template>
  <div class="container trend-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">问题数据趋势</div>
      <div class="selector">
        <data-select
          v-model="dataTime"
          @currentType="currentType"
        />
      </div>
    </div>
    <div class="trend-info-line">
      <!-- <v-chart
        class="chart"
        :option="option"
      /> -->
      <div ref="charts"></div>
    </div>
  </div>
</template>

<script>
import DataSelect from "./data-select"
import { fetchProblemTrend } from "@/api"
import { mapGetters } from "vuex"
import Charts from "@/mixins/charts"
export default {
  mixins: [Charts],
  components: {
    DataSelect,
  },
  data() {
    return {
      dataType: 7,
      data: [],
      dataTime: "",
      id: "",
    }
  },
  methods: {
    currentType(type) {
      this.dataType = type
      return fetchProblemTrend(this.id, type).then(({ data }) => {
        this.data = data
      })
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            this.id = val
            return fetchProblemTrend(val, this.dataType).then(({ data }) => {
              this.data = data
            })
          })
        }
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  computed: {
    ...mapGetters(["projectId"]),
    option() {
      return {
        title: {
          text: "数量",
          textStyle: {
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFFFFF",
          },
          left: "0px",
        },
        dataset: {
          source: [["日期", "一般问题", "严重问题"], ...this.data],
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#FF4040",
              type: "solid",
            },
          },
          // formatter(params) {
          //     const item1 = params[0];//一般问题
          //     const item2 = params[1];//严重问题
          //     return  `
          //         <p style="margin-bottom: 10px">${item2.axisValueLabel}<p>
          //         <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data}<p>
          //         <p>${item2.marker}${item2.seriesName} ${item2.data}<p>
          //     `;
          // }
        },
        legend: {
          right: "5px",
          top: "-5px",
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          textStyle: {
            fontSize: 10,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFF",
          },
          data: [{ name: "一般问题" }, { name: "严重问题" }],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
            // onZero: false,
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        // grid: {
        //     top: '37.5px',
        //     bottom: '0',
        //     left: '0px',
        //     right: '17px',
        //     containLabel: true// grid 区域是否包含坐标轴的刻度标签。(如果true的时候，上下左右可以为0了)
        // },
        grid: {
          show: false,
          top: 42,
          // width: '100%',
          x: 35,
          x2: 20,
          height: "82%",
        },
        series: [
          {
            name: "一般问题",
            type: "line",
            itemStyle: {
              color: "#40D5FF",
            },

            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(116, 236, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(116, 236, 255, 0.0)",
                  },
                ],
              },
            },
          },
          {
            name: "严重问题",
            type: "line",
            itemStyle: {
              color: "#FBA401",
            },
            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255, 165, 0, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 165, 0, 0)",
                  },
                ],
              },
            },
          },
        ],
      }
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.trend-info {
  height: 468px;
  position: relative;
  &-line {
    width: 901px;
    height: 355px;
    margin: 33px 15px 24px 20px;
  }
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }
  .empty {
    position: absolute;
    left: 0;
    right: 0;
    top: 74px;
    bottom: 0;
    line-height: 355px;
  }
}
</style>
