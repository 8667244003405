<template>
  <div class="container weather">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">天气预报</div>
    </div>
    <div
      class="he-plugin"
      v-if="this.historyWeather && this.historyWeather.length"
    >
      <div
        v-for="(item, index) in historyWeather"
        :key="'historyWeather' + index"
        class="he-weather"
      >
        <div class="dataTime">{{ item.date }}</div>
        <div class="week">{{ item.week }}</div>
        <img
          class="icon"
          :src="item.icon"
        />
        <div class="cloud">
          <div class="temp">{{ item.tempMin }}~{{ item.tempMax }}℃</div>
          <div class="status">{{ item.textDay }}</div>
        </div>
        <div class="wind">{{ item.windDirDay }}</div>
      </div>
      <div
        v-for="(item, index) in weather"
        :key="'weather' + index"
        class="he-weather"
      >
        <div class="dataTime">
          {{ item.fxDate }}
          <div v-if="index == 0">（今天）</div>
        </div>
        <div class="week">{{ item.week }}</div>
        <img
          class="icon"
          :src="`https://cdn.gongjiangyi.com/he/128/${item.iconDay}.png`"
        />
        <!-- <img class="icon" :src="icon"/> -->
        <div class="cloud">
          <div class="temp">{{ item.tempMin }}~{{ item.tempMax }}℃</div>
          <div class="status">{{ item.textDay }}</div>
        </div>
        <div class="wind">{{ item.windDirDay }}</div>
      </div>
    </div>
    <div
      v-else
      class="weather-empty"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchThreeWeather, fetchHistoricalWeather } from "@/api"
import TimeSelect from "../time-select"
import EnvCharts from "../env-charts"
import moment from "moment"
export default {
  components: {
    TimeSelect,
    EnvCharts,
  },
  props: {},
  data() {
    return {
      weather: [
        {
          fxDate: moment().format("YYYY-MM-DD"),
          week: "",
          iconDay: "101",
          tempMin: "28",
          tempMax: "35",
          textDay: "多云",
          windDirDay: "微风",
        },
        {
          fxDate: moment().add(1, "days").format("YYYY-MM-DD"),
          week: "",
          iconDay: "305",
          tempMin: "26",
          tempMax: "33",
          textDay: "小雨",
          windDirDay: "西风",
        },
        {
          fxDate: moment().add(2, "days").format("YYYY-MM-DD"),
          week: "",
          iconDay: "104",
          tempMin: "30",
          tempMax: "36",
          textDay: "晴",
          windDirDay: "东南风",
        },
      ],
      historyWeather: [],
    }
  },
  computed: mapGetters(["latlng", "projectId"]),
  mounted() {},
  watch: {
    latlng: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchThreeWeather(val).then((resp) => {
              if (resp.code === "200") {
                const list = resp.daily
                console.log(resp)
                this.weather = list
                list.forEach((item) => {
                  this.getWeekDay(item)
                })
              } else {
                this.weather.forEach((item) => {
                  this.getWeekDay(item)
                })
              }
            })
          }, 5 * 60 * 1000) /// 天气5分钟轮询一次
        }
      },
    },
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          return fetchHistoricalWeather(val).then(({ data }) => {
            if (data) {
              this.historyWeather = data
              data.forEach((item) => {
                this.getWeekDay(item)
                var iconDay = require(`../../../../../assets/image/weather-icon-64/${item.iconDay}.png`)
                this.$set(item, "icon", iconDay)
              })
            }
          })
        }
      },
    },
  },
  methods: {
    getWeekDay(item) {
      if (item.fxDate) {
        var fxDate = item.fxDate
      } else if (item.date) {
        var fxDate = item.date
      }
      var myDate = new Date(fxDate)
      if (myDate.getDay() == 0) {
        var weekDay = "星期日"
      } else if (myDate.getDay() == 1) {
        var weekDay = "星期一"
      } else if (myDate.getDay() == 2) {
        var weekDay = "星期二"
      } else if (myDate.getDay() == 3) {
        var weekDay = "星期三"
      } else if (myDate.getDay() == 4) {
        var weekDay = "星期四"
      } else if (myDate.getDay() == 5) {
        var weekDay = "星期五"
      } else if (myDate.getDay() == 6) {
        var weekDay = "星期六"
      }
      this.$set(item, "week", weekDay)
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.weather {
  height: 306px;
  .he-plugin {
    display: flex;
    align-items: center;
    width: 976px;
    height: 231px;
    padding: 19px 13px 0px 13px;
    .he-weather {
      width: 184px;
      height: 231px;
      margin: 0 3px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      .dataTime {
        width: 184px;
        text-align: center;
        margin-top: 6px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .week {
        text-align: center;
        margin-top: 10px;
      }
      .icon {
        display: block;
        margin: 24px auto;
        width: 64px;
        height: 64px;
      }
      .cloud {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .temp {
          margin-right: 16px;
        }
      }
      .wind {
        text-align: center;
      }
    }
  }
  .weather-empty {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 220px;
    text-align: center;
  }
}
</style>
