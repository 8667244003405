import { render, staticRenderFns } from "./canvasround.vue?vue&type=template&id=c88a2b72&scoped=true"
import script from "./canvasround.vue?vue&type=script&lang=js"
export * from "./canvasround.vue?vue&type=script&lang=js"
import style0 from "./canvasround.vue?vue&type=style&index=0&id=c88a2b72&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c88a2b72",
  null
  
)

export default component.exports