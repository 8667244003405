<template>
    <div class="container project-info">
        <div class="container__dataOverall">
            <div class="container__border"></div>
            <div class="container__title">项目概要信息</div>
        </div>
        <div class="project-info-content">
          <div class="project-info-content-gird">
            <item label="项目名称" :value="name" color="rgba(64, 155, 255, 1)"/>
            <item label="建设单位" :value="buildOrg" color="rgba(64, 155, 255, 1)"/>
            <item label="项目类型" :value="type" color="rgba(111, 201, 248, 1)"/>
            <item label="设计单位" :value="designOrg" color="rgba(111, 201, 248, 1)"/>
            <item label="工程造价" :value="cost" color="rgba(174, 222, 248, 1)"/>
            <item label="监理单位" :value="supervisorOrg" color="rgba(174, 222, 248, 1)"/>
            <item label="建筑面积" :value="buildArea" color="rgba(219, 242, 254, 1)"/>
            <item label="总承包单位" :value="contractOrg" color="rgba(219, 242, 254, 1)"/>
            <item label="项目经理" :value="manager" color="rgba(255, 255, 255, 1)"/>
            <item label="勘查单位" :value="surveyOrg" color="rgba(255, 255, 255, 1)"/>
          </div>
          <div class="v-gaps12"></div>
            <div class="progressbar-wrapper">
                <progress-bar :value="progress * 100">
                    <div class="date" slot="start">
                        <div class="t">开工日期</div>
                        <div>{{startTime}}</div>
                    </div>
                    <div class="date" slot="end">
                        <div class="t">计划竣工日期</div>
                        <div>{{estimatedTime}}</div>
                    </div>
                </progress-bar>
            </div>
            <div class="total">
                <counter title="在册劳务工人" :value="builderNum" unit="人" id='1' :tableHeader="tableHeader"/>
                <counter title="在册劳务班组" :value="teamNum" unit="个" id='2' :tableHeader="teamTableHeader"/>
                <counter title="在册项目人员" :value="managerNum" unit="人" id='3' :tableHeader="tableHeader"/>
            </div>
        </div>
    </div>
</template>

<script>
import Item from './item'
import ProgressBar from './progress'
import Counter from './counter'
import { mapGetters } from 'vuex'
import { fetchProjectInfo } from '@/api'
export default {
    components: {
        Item,
        ProgressBar,
        Counter
    },
    data () {
        return {
            buildArea: "23434㎡",
            buildOrg: "某某建设公司",
            surveyOrg:'某某建设公司',
            builderNum: 0,
            contractOrg: "某某公司",
            cost: "32344万元",
            designOrg: "某某工程设计公司",
            estimatedTime: "2022年11月2日",
            id: "",
            manager: "",
            name: "茂名测试项目",
            progress: 0,
            startTime: "2020年11月2日",
            supervisorOrg: "某某建设项目管理有限公司",
            teamNum: 0,
            type: "隧道工程",
            managerNum: 0,
            isLoading: false,
            workList: [],
            teamList: [],
            staffList: [],
            tableHeader: [
                {
                    label: '姓名',
                    width: 100
                },
                {
                    label: '联系电话',
                    width: 120
                },
                {
                    label: '人员类型',
                    width: 120
                },
                {
                    label: '所属班组',
                    width: 200
                },
                {
                    label: '工种类型',
                    width: 120
                },
                {
                    label: '入场时间',
                    width: 160
                }
            ],
            teamTableHeader: [
                {
                    label: '班组名称',
                    width: 160
                },
                {
                    label: '班组负责人',
                    width: 100
                },
                {
                    label: '联系电话',
                    width: 130
                },
                // {
                //     label: '班组类型',
                //     width: 100
                // },
                {
                    label: '工种类型',
                    width: 130
                },
                {
                    label: '班组人数',
                    width: 120
                },
                {
                    label: '入场时间',
                    width: 167
                }
            ],
        }
    },
    computed: mapGetters(['projectId']),
    watch: {
        projectId: {
            immediate: true,
            handler  (val) {
                if (val) {
                    this.request(() => {
                        return fetchProjectInfo(val).then(({data}) => {
                            Object.assign(this._data, data)
                        })
                    });
                }
            }
        }
    },
    destroyed() {

    }
}
</script>

<style lang="less" scoped>
.project-info {
    width: 616px;
    height: 468px;
    &-content {
        &-gird {
            padding: 0 16px;
            display: grid;
            grid-template-columns: 50% 50%;
        }
        .progressbar-wrapper {
            padding: 0 16px;
        }
    }
    .date {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        padding: 10px 0;
        .t {
            text-align: center;
            margin-bottom: 8px;
        }
    }
    .total {
        position: relative;
        margin-top: 18px;
        display: flex;
        &::after {
            content: '';
            position: absolute;
            left: 16px;
            right: 16px;
            height: 1px;
            background: rgba(64, 155, 255, .2);
        }
    }
}
</style>
