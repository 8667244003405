<template>
    <div class="visited-line">
        <div class="tabs-pm">
            <div class="tab-pm" :class="{'active': pmType == 1}" @click="onPmData(1)">
                <div>PM2.5</div>
                <div class="tab-pm-line" :class="{'active': pmType == 1}"></div>
            </div>
            <div class="tab-pm" :class="{'active': pmType == 2}" @click="onPmData(2)">
                <div>PM10</div>
                <div class="tab-pm-line" :class="{'active': pmType == 2}"></div>
            </div>
            <div class="tab-pm" :class="{'active': pmType == 3}" @click="onPmData(3)">
                <div style="margin: 0 11px">TSP</div>
                <div class="tab-pm-line" :class="{'active': pmType == 3}"></div>
            </div>
        </div>
        <div class="tabs-time">
            <div class="tab-time" :class="{'active': type == 1}" @click="onTimeData(1)">天</div>
            <div class="tab-time" :class="{'active': type == 2}" @click="onTimeData(2)">月</div>
        </div>
        <div class="e-chart-line">
            <v-chart class="chart" :option="options"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        chartType: {
            type: String,
            default () {
                return 'line'
            }
        },
        pmTimeData: {
            type: Object,
            default () {
                return null
            }
        },
        limitMap: {
            type: Object,
            default () {
                return null
            }
        }
    },
    watch: {
        pmTimeData: {
            handler (val) {
                if (val) {
                    if (this.type == 1) {
                        if (this.pmType == 1) {
                            this.data = val.pm2point5DayData.rows
                        } else if (this.pmType == 2) {
                            this.data = val.pm10DayData.rows
                        } else {
                            this.data = val.tspDayData.rows
                        }
                    } else {
                        if (this.pmType == 1) {
                            this.data = val.pm2point5MonthData.rows
                        } else if (this.pmType == 2) {
                            this.data = val.pm10MonthData.rows
                        } else {
                            this.data = val.tspMonthData.rows
                        }
                    }
                }
            }
        },
        limitMap: {
            handler (val) {
                if (val) {
                    if (this.type == 1) {
                        if (this.pmType == 1) {
                            this.limitData = val.pm2point5HighLimit
                        } else if (this.pmType == 2) {
                            this.limitData = val.pm10HighLimit
                        } else {
                            this.limitData = val.tspHighLimit
                        }
                    } else {
                        if (this.pmType == 1) {
                            this.limitData = val.pm2point5HighLimit
                        } else if (this.pmType == 2) {
                            this.limitData = val.pm10HighLimit
                        } else {
                            this.limitData = val.tspHighLimit
                        }
                    }
                } 
            }
        }
    },
    computed: {
        options () {
            return {
                title:{
                    text: 'ug/m³',
                    textStyle: {
                        fontSize: 16,
                        fontFamily: 'SourceHanSansCN-Regular, SourceHanSansCN',
                        fontWeight: 400,
                        color: '#FFFFFF',
                    },
                    left: '20px',
                },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        align: 'left',
                    },
                    axisPointer: {
                        type: 'line',
                        label: {
                            show: true
                        },
                        lineStyle: {
                            width: 1,
                            type: 'solid',
                        },
                    },
                    formatter(params) {
                        const item1 = params[0];
                        return `
                            <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                            <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data} ug/m³<p>
                        `
                    }
                },
                visualMap: {
                    show: false,
                    // type: 'continuous',
                    dimension: 1,
                    // pieces: [{gt: 0, lte: this.limitData ? this.limitData : 0, color: '#40D5FF'},{gt: this.limitData ? this.limitData : 0, color: this.chartType == 'bar' ? '#F15050' : '#40D5FF'}],
                    pieces: [
                        {
                            min: -1,
                            max: this.limitData,
                            label: -1,
                        }
                    ],
                    // min: 0,
                    // max: this.limitData,
                    inRange: {
                        color: '#40D5FF',
                    },
                    outOfRange: {
                        color: this.chartType == 'bar' ? '#F15050' : '#40D5FF',
                    },
                    // outOfRange: {
                    //     color: '#40D5FF',
                    // },
                },
                grid: {
                    show: false,
                    top: 56,
                    // width: '100%',
                    // x: 0,
                    height: '77%',
                    left: '4.6%',
                    right: 80,
                },
                xAxis: {
                    name: '时间',
                    data: this.data.map(item => {
                        let a = Object.keys(item)
                        return item[a[1]]
                    }),
                    nameTextStyle: {
                        fontSize: 16,
                        color: '#FFFFFF',
                    },
                    splitArea: false,
                    axisLabel: {
                        show: true,
                        margin: 10,// 与刻度线间距
                        textStyle: {
                            color: '#C7C7C7',  //更改坐标轴文字颜色
                            fontSize : 16     //更改坐标轴文字大小
                        }
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false
                    },
                    // boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    show: true,
                    // scale : true,
                    // max: 100,
                    min: 0,
                    splitNumber: 4,
                    nameTextStyle: {
                        fontSize: 16,
                        padding: [0, 0, 24, 18]
                    },
                    axisLine: {
                        show: true,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        margin: 10,// 与刻度线间距
                        textStyle: {
                            color: '#C7C7C7',  //更改坐标轴文字颜色
                            fontSize : 16     //更改坐标轴文字大小
                        }
                    },

                },
                series: {
                    name: `${this.pmName}`,
                    data: this.data.map(item => {
                        let a = Object.keys(item)
                        return item[a[0]]
                    }),
                    type: this.chartType,
                    itemStyle: {
                        color: '#40D5FF',
                    },
                    markLine : {
                        symbol:"none",               //去掉警戒线最后面的箭头
                        silent:true,
                        label:{
                            position:"end",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                            formatter: this.limitData ? `预警线${this.limitData}` : '',
                            color: "#F15050",
                            fontSize: 16
                        },
                        data : [{
                            lineStyle:{               //警戒线的样式  ，虚实  颜色
                                type: "dashed",
                                color: this.limitData ? "#FF3939" : '',
                            },
                            yAxis: this.limitData,
                        }]
                    }

                }
            }
        }
    },
    data () {
        return {
            pmType: 1,
            type: 1,
            pmName: 'PM2.5',
            data: [],
            limitData: 0
        }
    },
    // mounted () {
    //     this.pmData = this.pmpointMonthData
    // },
    methods: {
        onTimeData (type) {
            this.type = type
            if (this.type == 1) {
                if (this.pmType == 1) {
                    this.data = this.pmTimeData.pm2point5DayData.rows
                    this.limitData = this.limitMap.pm2point5HighLimit
                } else if (this.pmType == 2) {
                    this.data = this.pmTimeData.pm10DayData.rows
                    this.limitData = this.limitMap.pm10HighLimit
                } else {
                    this.data = this.pmTimeData.tspDayData.rows
                    this.limitData = this.limitMap.tspHighLimit
                }
            } else {
                if (this.pmType == 1) {
                    this.data = this.pmTimeData.pm2point5MonthData.rows
                    this.limitData = this.limitMap.pm2point5HighLimit
                } else if (this.pmType == 2) {
                    this.data = this.pmTimeData.pm10MonthData.rows
                    this.limitData = this.limitMap.pm10HighLimit
                } else {
                    this.data = this.pmTimeData.tspMonthData.rows
                    this.limitData = this.limitMap.tspHighLimit
                }
            }
        },
        onPmData (pmType) {
            this.pmType = pmType
            if (this.pmType == 1) {
                this.pmName = 'PM2.5'
                if (this.type == 1) {
                    this.data = this.pmTimeData.pm2point5DayData.rows
                    this.limitData = this.limitMap.pm2point5HighLimit
                } else {
                    this.data = this.pmTimeData.pm2point5MonthData.rows
                    this.limitData = this.limitMap.pm2point5HighLimit
                }
            } else if (this.pmType == 2) {
                this.pmName = 'PM10'
                if (this.type == 1) {
                    this.data = this.pmTimeData.pm10DayData.rows
                    this.limitData = this.limitMap.pm10HighLimit
                } else {
                    this.data = this.pmTimeData.pm10MonthData.rows
                    this.limitData = this.limitMap.pm10HighLimit
                }
            } else {
                this.pmName = 'TSP'
                if (this.type == 1) {
                    this.data = this.pmTimeData.tspDayData.rows
                    this.limitData = this.limitMap.tspHighLimit
                } else {
                    this.data = this.pmTimeData.tspMonthData.rows
                    this.limitData = this.limitMap.tspHighLimit
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.visited-line {
    margin-top: 30px;
    margin-left: 26px;
    .tabs-pm {
        display: flex;
        float: left;
        .tab-pm {
            width: 55px;
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #ffffff;
            line-height: 27px;
            margin-right: 40px;
            cursor: pointer;
            &.active {
                color: #40D5FF;;
            }
            &-line.active {
                width: 34px;
                margin-left: 10.5px;
                border: 1px solid #40D5FF;;
            }
        }
    }
    .tabs-time {
        display: flex;
        float: right;
        margin-right: 37px;
        .tab-time {
            width: 52px;
            height: 26px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            line-height: 26px;
            &.active {
                color: #333333;
                background: linear-gradient(180deg, #409CFE 0%, #57D5C7 100%);
            }
        }
    }
    .e-chart-line {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 382px;
        padding-top: 13px;
    }
}
</style>