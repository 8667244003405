<template>
  <div class="progress">
    <div class="progressbar">
      <div
        class="tip"
        :style="{
          left: `${value}%`,
        }"
      >
        <count-to
          :start-val="0"
          :end-val="value"
          :duration="0.3"
        />%
      </div>
      <div
        class="bar"
        :style="{
          width: `${value}%`,
        }"
      ></div>
    </div>
    <div class="desc">
      <div class="start">
        <slot name="start" />
      </div>
      <div class="end">
        <slot name="end" />
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
export default {
  components: {
    CountTo,
  },
  props: {
    /// 百分比
    value: {
      type: [Number, String],
      default: 0,
    },
  },
}
</script>

<style lang="less" scoped>
.progress {
  padding: 34px 0 10px;
  .progressbar {
    position: relative;
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin: 0 37px;
    .tip {
      position: absolute;
      top: 0;
      transform: translateX(-50%) translateY(calc(-100% - 6px));
      margin-bottom: 2px;
      padding: 3px 11px;
      background: rgba(64, 155, 255, 1);
      font-size: 14px;
      font-family: Bebas;
      color: #ffffff;
      letter-spacing: 1px;
      line-height: 18px;
      border-radius: 2px;
      transition: 1s;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 5px 4px 0px;
        border-style: solid;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        border-color: rgba(64, 155, 255, 1) transparent transparent;
      }
    }
    .bar {
      position: absolute;
      top: 0;
      left: 0;
      transition: 1s;
      background: linear-gradient(90deg, #56d6c7 0%, #409bff 100%);
      border-radius: 5px;
      height: 6px;
      cursor: pointer;
      &:hover {
        top: -1px;
        height: 8px;
      }
    }
  }
  .desc {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
}
</style>
