<template>
  <div class="tabs">
    <ul class="tabs-list">
      <li
        v-for="(item, index) in tabsList"
        :key="item.screenId"
        class="tabs-list-item"
        :class="{ 'tabs-list-item--active': item.screenId === currentId }"
        @click="onSelect(item, index)"
      >
        <span
          class="label"
          :title="item.name"
        >
          {{ item.screenName }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchScreenList } from "@/api"

export default {
  props: {
    isScroll: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      currentId: "1", //当前所在id
      tabsList: [], //tab列表
      timer: null,
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchTabList()
        }
      },
    },
    isScroll: {
      immediate: true,
      handler: function (val) {
        // 当允许轮播，并且定时器被清除
        if (val && !this.timer) {
          this.setTime()
        } else {
          if (this.timer) {
            this.clearTime()
          }
        }
      },
    },
    value: {
      immediate: true,
      handler(val) {
        console.log(val, "aaaaaa")
        this.currentId = val
      },
    },
  },
  created() {
    this.fetchTabList()
  },
  methods: {
    onSelect(item, index) {
      if (item) {
        this.currentId = item.screenId
        if (this.isScroll) {
          if (this.timer) {
            this.clearTime()
          }
          this.setTime()
        }

        this.$emit("currentTabsId", this.currentId)
      }
    },
    setTime() {
      this.timer = setInterval(() => {
        let currentIndex = this.tabsList.findIndex((tab) => tab.screenId == this.currentId)
        currentIndex += 1
        if (currentIndex > this.tabsList.length - 1) {
          currentIndex = 0
        }
        this.onSelect(this.tabsList[currentIndex], currentIndex)
      }, 20000)
    },
    clearTime() {
      clearInterval(this.timer)
      this.timer = null
    },
    fetchTabList() {
      fetchScreenList(this.projectId).then(({ data }) => {
        if (this.projectId == "35389235866519552") {
          data.push({
            id: "25495975685469193",
            screenId: "1001",
            projectId: "35389235866519552",
            projectName: "斗门基地",
            screenName: "BIM模型",
            companyId: "8253907045187585",
            status: 0,
          })
        }
        this.tabsList = data
      })
    },
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style lang="less" scoped>
.tabs {
  align-items: center;

  &-list {
    display: flex;

    &-item {
      width: 84px;
      height: 80px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 80px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      line-height: 80px;
      text-align: center;
      margin: 0 6px;

      &:hover {
        // color: #40D5FF;
        cursor: pointer;
      }

      &.tabs-list-item--active {
        height: 80px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #40d5ff;
        line-height: 80px;
        text-align: center;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        background: linear-gradient(180deg, rgba(64, 155, 255, 0.8) 0%, rgba(86, 214, 199, 0) 100%);
      }
    }
  }
}
</style>
