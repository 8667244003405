<template>
  <div class="container out-infor-wrap">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">实时出场车辆信息</div>
    </div>
    <div class="out-infor">
      <div class="out-infor-bg">
        <img
          v-if="outInfo && outInfo.url"
          :src="outInfo.url"
          alt=""
        />
        <img
          v-else
          src="../../../../../assets/image/vehicle/img-null.png"
          class="none"
          alt=""
        />
      </div>
      <div class="out-infor-detail">
        <div class="icon">
          <img
            :src="outImg"
            alt=""
          />
        </div>
        <div
          v-if="outInfo && outInfo.url"
          class="item"
        >
          出口位置：{{ outInfo.position }}
        </div>
        <div
          v-else
          class="item"
        >
          出口位置：暂无数据
        </div>
        <div
          v-if="outInfo && outInfo.url"
          class="item"
        >
          车牌号码：{{ outInfo.carCode }}
        </div>
        <div
          v-else
          class="item"
        >
          车牌号码：暂无数据
        </div>
        <div
          v-if="outInfo && outInfo.url"
          class="item"
        >
          出场时间：{{ outInfo.recordTime }}
        </div>
        <div
          v-else
          class="item"
        >
          出场时间：暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gif from "../.././../../../assets/image/vehicle/out.gif"
import { mapState } from "vuex"
export default {
  data() {
    return {
      outImg: gif,
    }
  },
  computed: {
    ...mapState({
      outInfo: (state) => (state.outInfo ? state.outInfo : []),
    }),
  },
  watch: {
    outInfo(v, o) {
      this.outImg = gif
      setTimeout(() => {
        this.outImg = png
      }, 1500)
    },
  },
}
</script>

<style lang="less" scoped>
.out-infor-wrap {
  height: 468px;
  position: relative;
}
.out-infor {
  height: 412px;
  padding: 20px 16px 22px;
  box-sizing: border-box;
  &-bg {
    position: relative;
    height: 226px;
    width: 383px;
    padding: 8px;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-image: url("../../../../../assets/image/vehicle/inout.png");
    img {
      width: 100%;
      height: 100%;
    }
    .none {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
    }
  }
  &-detail {
    padding: 17px 0px 17px 32px;
    margin-top: 16px;
    box-sizing: border-box;
    .icon {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item {
      margin-bottom: 18px;
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
