<template>
  <div class="project">
    <div
      class="project__name"
      :class="{ 'project__name--default': !!defaultProjectId }"
      :title="!defaultProjectId ? '切换项目' : ''"
      @click="onOpen"
    >
      {{ projectName }}
    </div>
    <img
      class="project__switch"
      v-if="projectList.length > 0 && !defaultProjectId"
      title="切换项目"
      @click="onOpen"
      src="../../../assets/image/switch.png"
    />
    <div
      class="dialog"
      :class="{ 'dialog--show': visible }"
    >
      <div class="dialog-content">
        <div class="container">
          <div class="container-header">
            <div class="container-header__title">请选择项目</div>
            <img
              class="container-header__icon"
              title="关闭"
              @click="visible = false"
              src="../../../assets/image/close.png"
            />
          </div>
          <div class="container-body">
            <!-- 搜索框 -->
            <div class="input-wrapper">
              <div class="input-wrapper-input">
                <input
                  placeholder="请输入项目名称"
                  v-model="query"
                />
                <img
                  class="icon"
                  src="../../../assets/image/search.png"
                />
              </div>
              <div class="border lt"></div>
              <div class="border rt"></div>
            </div>
            <div class="project-list scrollbar">
              <ul
                class="list"
                v-if="queryList.length"
              >
                <li
                  v-for="item in queryList"
                  :key="item.id"
                  class="list-item"
                  :class="{ 'list-item--active': item.id === projectId }"
                  @click="onSelect(item)"
                >
                  <span class="radio"></span>
                  <span
                    class="label"
                    :title="item.name"
                    >{{ item.name }}</span
                  >
                </li>
              </ul>
              <div
                class="empty"
                v-else
              >
                抱歉没有找到与“{{ query }}”相关的项目
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dialog-mask"
        @click="visible = false"
      ></div>
    </div>
  </div>
</template>

<script>
import { fetchProjectListByOrgId } from "@/api"
import { SET_PROJECT, SET_PROJECT_LIST, SET_LOADING } from "@/store"
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      visible: false,
      query: "",
      defaultProjectId: "",
    }
  },
  computed: {
    ...mapGetters(["projectList", "projectId", "projectName"]),
    queryList() {
      return this.projectList.filter((it) => it.name.indexOf(this.query.trim()) !== -1)
    },
  },
  methods: {
    onSelect(project) {
      this.visible = false
      if (project.id === this.projectId) return
      this.$store.dispatch(SET_PROJECT, project)
    },
    onOpen() {
      /// 如果传入了默认项目 那么不可进行切换
      if (this.defaultProjectId) return
      this.visible = this.projectList.length > 0
    },
  },
  mounted() {
    let search = window.location.search || ""
    let query = {}
    if (search) {
      search = search.slice(1).split("&")
      search.forEach((s) => {
        const [k, v] = s.split("=")
        query[k] = v
      })
    }
    this.defaultProjectId = query.projectId
    if (query.orgId) {
      fetchProjectListByOrgId(query.orgId).then(({ data }) => {
        this.$store.dispatch(SET_PROJECT_LIST, {
          data,
          projectId: query.projectId, /// url可以指定项目
        })
      })
    } else {
      this.$store.dispatch(SET_PROJECT, {
        name: "未指定企业",
      })
      this.$store.dispatch(SET_LOADING, false)
    }
  },
}
</script>

<style lang="less" scoped>
.project {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 465px;
  &__name {
    margin-right: 14px;
    font-size: 40px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &--default {
      cursor: default;
    }
  }
  &__switch {
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
  }
  .dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2000;
    cursor: default;
    visibility: hidden;
    &-content {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      // transform: translate(-50%, -40%);
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      .container {
        &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px 16px;
          &__title {
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
          }
          &__icon {
            width: 18px;
            height: 18px;
            display: block;
            cursor: pointer;
            transition: 0.4s;
            &:hover {
              transform: rotate(180deg);
            }
          }
        }
        &-body {
          padding-bottom: 24px;
        }
      }
    }
    &-mask {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(6, 27, 39, 0.8);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
    }
    &.dialog--show {
      visibility: visible;
      .dialog-mask {
        opacity: 1;
        visibility: visible;
      }
      .dialog-content {
        opacity: 1;
        visibility: visible;
        // transform: translate(-50%, -50%);
      }
    }
  }

  /// 搜索框
  .input-wrapper {
    height: 36px;
    background: rgba(6, 41, 57, 1);
    box-sizing: border-box;
    width: 280px;
    position: relative;
    padding: 0 14px;
    cursor: pointer;
    margin: 0 16px 16px;
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(64, 213, 255, 1);
    }
    &::after {
      position: absolute;
      content: "";
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(64, 213, 255, 1);
    }
    &-input {
      height: 100%;
      display: flex;
      align-items: center;
      input {
        width: 100%;
        flex: 1;
        user-select: none;
        background: rgba(6, 41, 57, 1);
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #40d5ff;
        cursor: pointer;
        text-overflow: ellipsis;
        /*去除阴影*/
        box-shadow: none;
        /*聚焦input的蓝色边框*/
        outline: none;
        /*textarea 禁止拖拽*/
        resize: none;
        /*去除边框*/
        border: none;
        /*常用于IOS下移除原生样式*/
        -webkit-appearance: none;
        /*点击高亮的颜色*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &::placeholder {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #40d5ff;
        }
      }
      .icon {
        flex: none;
        width: 18px;
        height: 18px;
        transition: 0.3s;
      }
    }
    .border {
      position: absolute;
      content: "";
      width: 1px;
      top: 0;
      bottom: 0;
      background: rgba(64, 213, 255, 1);
      &.lt {
        left: 0;
      }
      &.rt {
        right: 0;
      }
    }
  }
  .project-list {
    width: 523px;
    height: 434px;
    padding: 0 16px;
    box-sizing: border-box;
    overflow: auto;
    .list {
      &-item {
        height: 14px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #40d5ff;
        cursor: pointer;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 16px;
        }
        .radio {
          flex: none;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          width: 14px;
          height: 14px;
          background: #40d5ff;
          border-radius: 50%;
          margin-right: 12px;
          transition: 0.3s;
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            background: #40d5ff;
            margin-left: 5px;
            margin-top: 5px;
            transform: scale(0);
            border-radius: 50%;
            transition: 0.3s;
          }
        }
        &.list-item--active {
          color: #fff;
          .radio {
            background: #fff;
            &::before {
              transform: scale(1);
            }
          }
        }
        &:hover {
          color: #fff;
        }
        .label {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .empty {
      font-size: 14px;
      color: #fff;
      word-break: break-all;
    }
  }
}
</style>
