<template>
  <div class="issue-chart">
    <!-- <v-chart
      v-if="!isEmpty"
      :option="option"
      class="chart"
    ></v-chart> -->
    <template v-if="!isEmpty">
      <div
        ref="charts"
        key="charts"
      ></div>
    </template>

    <div
      v-else
      class="notData"
      key="notData"
    >
      <img src="../../../../assets/image/issue/defalutRound.png" />
      <div class="notData-word">暂无数据</div>
    </div>
  </div>
</template>

<script>
import charts from "@/mixins/charts"
export default {
  mixins: [charts],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    name: {
      type: String,
      default: "",
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        if (this.isEmpty) {
          this.disposeCharts()
          return
        }
        this.initCharts()
        this.$forceUpdate()
      },
    },
  },
  computed: {
    isEmpty() {
      return this.option.series[0].data.length === 0
    },
    option() {
      return {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Medium, SourceHanSansCN",
            fontWeight: 500,
          },
          formatter(params) {
            let item1 = params
            return `
                            <p style="margin-bottom: 10px">${item1.seriesName}<p>
                            <p>${item1.data.name} : ${item1.data.value} (${item1.percent}%)<p>
                        `
          },
        },
        avoidLabelOverlap: true,
        series: [
          {
            name: this.name,
            type: "pie",
            radius: ["49%", "73%"],
            avoidLabelOverlap: false, //防止标签重合
            startAngle: "100", //旋转，起始角度
            minAngle: "10", //最小角度
            label: {
              color: "#FFFFFF",
              fontSize: "12px",
              fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
              fontWeight: "400",
              show: true,
              formatter: "{b}\n {d}%",
            },
            labelLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
              length: 10,
              length2: 15,
            },
            data: this.data,
            itemStyle: {
              color: function (params) {
                let colors = ["#40D5FF", "#169BC1", "#076985"]
                return colors[params.dataIndex % 3]
              },
            },
            visualMap: {
              show: false,
              min: 80,
              max: 600,
              inRange: {
                colorLightness: [0, 1],
              },
            },
          },
        ],
      }
    },
  },
  methods: {
    compare(num) {
      return (obj1, obj2) => {
        let a = obj1[num]
        let b = obj2[num]
        return a - b
      }
    },
  },
}
</script>

<style lang="less" scoped>
.issue-chart {
  width: 449px;
  height: 303px;
  position: relative;
  .notData {
    width: 120px;
    height: 150px;
    position: absolute;
    top: 76px;
    left: 50%;
    margin-left: -60px;
    &-word {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      height: 16px;
      text-align: center;
      margin-top: 14px;
    }
    img {
      width: 120px;
      height: 120px;
    }
  }
}
</style>
