import { render, staticRenderFns } from "./issue-pie.vue?vue&type=template&id=530f2085&scoped=true"
import script from "./issue-pie.vue?vue&type=script&lang=js"
export * from "./issue-pie.vue?vue&type=script&lang=js"
import style0 from "./issue-pie.vue?vue&type=style&index=0&id=530f2085&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530f2085",
  null
  
)

export default component.exports