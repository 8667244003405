<template>
  <div class="title"><slot/></div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.title {
    font-size: 26px;
    color: #ffffff;
    padding-left: 32px;
    position: relative;
    margin-top: 24px;
    height: 26px;
    line-height: 24px;
    &::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        width: 6px;
        height: 26px;
        // background: #12B6E5;
        background: linear-gradient(360deg, rgba(86, 214, 199, 0) 0%, #409BFF 100%);
        left: 16px;
        // margin: 24px 0px 16px 16px;
    }
}
</style>