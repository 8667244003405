<template>
  <div class="container monitor">
    <div class="screen">
      <div
        v-if="item && item.cameraIndexCode"
        :style="{ width: width + 'px', height: height + 'px' }"
      >
        <hikvision-video
          :typeNum="0"
          :cameraIndexCode="item.cameraIndexCode"
          :channelNo="item && item.channelNo"
          :quality="item.quality"
        ></hikvision-video>
      </div>
      <div
        v-else
        class="empty"
      >
        <img src="../../../../../assets/image/monitoring.gif" />
      </div>
    </div>
  </div>
</template>

<script>
import Ezui from "@/view/project/Ezui"
import { mapGetters } from "vuex"
import MonitorSelect from "./select"
import { getBigFullCamera } from "@/api"

export default {
  components: {
    Ezui,
    MonitorSelect,
  },
  data() {
    return {
      url: "",
      // url: "http://163.179.125.37:83/openUrl/LZ77njO/live.m3u8",
      width: 800,
      height: 468,
      item: {},
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          return getBigFullCamera(val).then(({ data }) => {
            console.log("德哈卡德哈卡对哈对哈", data)
            this.item = data
          })
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.monitor {
  width: 800px;
  height: 468px;

  .screen {
    height: 468px;

    .empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;

      img {
        width: 580px;
        height: 396px;
      }
    }
  }
}
</style>
