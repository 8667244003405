<template>
    <div class="inspect-tip">
        <div v-for="(item, index) in tipStyle" :key="'tipStyle' + index">
            <div :style="`width: ${ item.width }px;`">
                <div class="inspect-tip-num" :style="`margin-left: ${ item.marginLeft }px`">{{ item.number }}</div>
                <div 
                    class="inspect-tip-round" 
                    :class="{'inspect-tip-round--active': index == 5}"
                    :style="`background: ${ item.background }; 
                    border-bottom-left-radius: ${ item.radius}px; 
                    border-top-left-radius: ${ item.radius}px` "
                ></div>
                <div class="inspect-tip-label">{{ item.label }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            tipStyle: [
                {
                    number: 0,
                    background: '#56D6C7',
                    label: '优秀',
                    width: 55,
                    radius: 5,
                },
                {
                    number: 50,
                    background: '#FBD22C',
                    label: '良好',
                    width: 54,
                    marginLeft: -12
                },
                {
                    number: 100,
                    background: '#FB8802',
                    label: '轻度污染',
                    width: 55,
                    marginLeft: -12
                },
                {
                    number: 150,
                    background: '#FB0001',
                    label: '中度污染',
                    width: 54,
                    marginLeft: -12
                },
                {
                    number: 200,
                    background: '#970454',
                    label: '重度污染',
                    width: 110,
                    marginLeft: -12
                },
                {
                    number: 300,
                    background: '#62001E',
                    label: '严重污染',
                    width: 160,
                    marginLeft: -12
                },
                {
                    number: 500,
                    marginLeft: -24
                },
            ]
        }
    } 
}
</script>

<style lang="less" scoped>
.inspect-tip {
    display: flex;
    justify-content:center;
    align-items:center;
    &-num {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 7px;
    }
    &-round {
        height: 8px;
        &.inspect-tip-round--active {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    &-label {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        margin-top: 6px;
    }
}
</style>