<template>
  <div class="screen">
    <header
      class="header"
      :style="{ transform: `scale(${scale})` }"
    >
      <div class="lt">
        <project />
      </div>
      <div class="tt">
        <screen-tabs
          :is-scroll="canScroll"
          @currentTabsId="currentTabsId"
          v-model="currentId"
        />
      </div>
      <div class="rt">
        <div class="switch-box">
          <el-switch
            v-model="scroll"
            active-color="#02A3F3"
            inactive-color="#393838"
            @change="scrollChange"
          >
          </el-switch>
          <div class="switch-title">轮播</div>
        </div>
        <h-gaps :width="30"></h-gaps>
        <fullscreen :disabled="canFullScreen" />
        <h-gaps :width="30" />
        <he-weather />
        <h-gaps :width="22" />
        <datetime />
      </div>
    </header>
    <v-gaps :height="16" />
    <slot />
  </div>
</template>

<script>
import Project from "../project.vue"
import ScreenTabs from "../screen-tabs.vue"
import Fullscreen from "../fullscreen.vue"
import HeWeather from "../he-weather.vue"
import Datetime from "../time"
import VGaps from "@/view/project/components/v-gaps.vue"
import HGaps from "@/view/project/components/h-gaps.vue"
export default {
  components: { Project, ScreenTabs, Fullscreen, HeWeather, Datetime, VGaps, HGaps },
  props: {
    isScroll: Boolean,
    value: {
      type: String,
      default: "1",
    },
  },
  computed: {
    canScroll() {
      return this.isScroll
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        console.log(val, "abcderr")
        this.currentId = val
      },
    },
    isScroll: {
      immediate: true,
      handler(val) {
        console.log("scroll", val)
        this.scroll = val
      },
    },
  },
  data() {
    return {
      currentId: "1",
      scroll: false,
      scale: 1,
    }
  },
  methods: {
    canFullScreen() {
      return this.currentId == "1001"
    },
    currentTabsId(id) {
      this.currentId = id
      this.$emit("tabsChange", id)
    },
    scrollChange(val) {
      this.$emit("update:isScroll", val)
    },
    updateScale(val) {
      this.scale = val
    },
  },
}
</script>

<style lang="less" scoped>
.screen {
  padding: 16px;
  height: 100%;

  .header {
    max-height: 80px;
    width: 1920px;
    background: url("../../../../assets/image/header-bg.gif");
    background-origin: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    position: relative;

    .rt {
      height: 80px;
      display: flex;
      align-items: center;
    }

    .tt {
      // position: absolute;
      // left: 50%;
      // margin-left: -288px;
    }

    .switch-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: white;

      .switch-title {
        margin-top: 15px;
      }
    }
  }
}
</style>
