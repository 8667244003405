<template>
  <div class="container statistic-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">历史报警统计</div>
      <div class="selector">
        <data-select
          v-model="dataTime"
          @currentType="currentType"
        />
      </div>
    </div>
    <div class="statistic-info-line">
      <!-- <v-chart class="chart" :option="option"/> -->
      <div ref="charts"></div>
    </div>
  </div>
</template>

<script>
import DataSelect from "./data-select"
import { mapGetters } from "vuex"
import { fetchHistoryCount } from "@/api"
import chart from "@/mixins/charts"
export default {
  mixins: [chart],
  components: {
    DataSelect,
  },
  data() {
    return {
      dataType: "1",
      data: [],
      dataTime: "",
      id: "",
    }
  },
  methods: {
    currentType(type) {
      this.dataType = type
      fetchHistoryCount(this.id, type).then(({ data }) => {
        this.data = data.tableDate
      })
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            this.id = val
            return fetchHistoryCount(val, this.dataType).then(({ data }) => {
              this.data = data.tableDate
            })
          })
        }
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  computed: {
    ...mapGetters(["projectId"]),
    option() {
      return {
        title: {
          text: "数量",
          textStyle: {
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFFFFF",
          },
          left: "0px",
        },
        dataset: {
          source: [["日期", "总报警"], ...this.data],
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#FF4040",
              type: "solid",
            },
          },
          formatter(params) {
            const item1 = params[0] //总报警
            return `
                            <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                            <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[1]}次<p>
                        `
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: this.workerNum.map(item => {
          //     let a = Object.keys(item)
          //     return item[a[0]]
          // }),
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
            // onZero: false,
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        grid: {
          top: "37.5px",
          bottom: "0",
          left: "0",
          right: "17px",
          containLabel: true, // grid 区域是否包含坐标轴的刻度标签。(如果true的时候，上下左右可以为0了)
        },
        series: [
          {
            name: "总报警",
            // data: this.workerNum.map(item => {
            //     let a = Object.keys(item)
            //     return item[a[1]]
            // }),
            type: "line",
            itemStyle: {
              color: "rgba(0, 199, 255, 1)",
            },
            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(116, 236, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(116, 236, 255, 0.0)",
                  },
                ],
              },
            },
          },
        ],
      }
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.statistic-info {
  height: 366px;
  position: relative;
  &-line {
    width: 493px;
    height: 271px;
    margin: 20px 21px 16px 16px;
  }
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
  }
  .empty {
    position: absolute;
    left: 0;
    right: 0;
    top: 74px;
    bottom: 0;
    line-height: 220px;
  }
}
</style>
