export class Gauge {

    $el;
    startAngle;
    endAngle;
    options;
    value;
    threshold;
    data;
    title;
    subtitle;
    /// 样式
    backgroundColor;
    color;
    ctx;
    width;
    height;


    constructor(options) {
        this.startAngle = 135
        this.endAngle = 405
        this.value = options.value
        this.threshold = options.threshold
        this.data = options.data
    }

    mount(el) {
        /// 判断el是否为dom节点
        if (typeof el === 'string') {
            this.$el = document.querySelector(el)
        } else {
            this.$el = el
        }
        const parent = this.$el.parentElement
        const wrapper = document.createElement('div')
        wrapper.style.cssText = 'display:inline-block;position:relative;font-size:0;'
        const canvas = document.createElement('canvas')
        canvas.width = this.$el.width
        canvas.height = this.$el.height
        canvas.style.margin = '0px'
        parent.insertBefore(wrapper, this.$el)
        wrapper.appendChild(canvas)
        parent.removeChild(this.$el)

        this.$el = canvas
        this.ctx = this.$el.getContext('2d')
        this.width = this.$el.width
        this.height = this.$el.height
        /// 解决锯齿严重的问题
        if (window.devicePixelRatio) {
            canvas.style.width = this.width + "px"
            canvas.style.height = this.height + "px"
            canvas.height = this.height * window.devicePixelRatio
            canvas.width = this.width * window.devicePixelRatio
            this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
        }
        /// 需要减去宽度
        this.radius = formatPercent('50%') *  (this.width > this.height ? this.height : this.width) - 20
        /// x偏移量
        this.circleX = formatPercent('50%') * this.width
        /// y偏移量
        this.circleY = formatPercent('50%') * this.height
        this.ctx.translate(this.circleX, this.circleY)
        /// 绘制外圆弧
        this._drawOuterArc()
        /// 绘制内圆
        this._drawInnerArc()
    }
    updateThreshold (threshold) {
        if (threshold) {
            this.ctx.clearRect(0, 0, this.$el.width, this.$el.height)
            this.threshold = threshold
            this._drawOuterArc()
            this._drawInnerArc()
        }
    }
    update(value) {
        if (value) {
            this.ctx.clearRect(0, 0, this.$el.width, this.$el.height)
            this.value = value
            this._drawOuterArc()
            this._drawInnerArc()
        }
    }

    _drawOuterArc() {
        this.ctx.beginPath()
        this.ctx.arc(0, 0, this.radius, angelToRadian(this.startAngle), angelToRadian(this.endAngle), false)
        // if (lineWidth) {
            this.ctx.lineWidth = 20
        // }
        this.ctx.strokeStyle = '#4D4C4D'
        this.ctx.lineCap = 'round'
        this.ctx.stroke()
        this.ctx.closePath()
    }

    _drawInnerArc() {
        this.ctx.beginPath()
        // 计算结束弧度
        let endAngle = ''
        if (this.value / this.threshold > 1) {
            endAngle = ((this.endAngle - this.startAngle) * 1) + this.startAngle
        } else {
            endAngle = ((this.endAngle - this.startAngle) * this.value / this.threshold) + this.startAngle
        }

        this.ctx.arc(0, 0, this.radius, angelToRadian(this.startAngle), angelToRadian(endAngle), false)
        // if (lineWidth) {
            this.ctx.lineWidth = 20
        // }
        this.ctx.lineCap = 'round'
        //圆环渐变色
        // var gradient = this.ctx.createLinearGradient(0, 10, 0, 90)
        // gradient.addColorStop("0", "#57D5C7")
        // gradient.addColorStop("1.0", "#409CFE")
        // if (this.value <= this.threshold) {
        //     this.ctx.strokeStyle = gradient
        // } else {
        //     this.ctx.strokeStyle = '#F15050'
        // }
        if (this.value <= this.threshold) {
            this.ctx.strokeStyle = '#40D5FF'
        } else {
            this.ctx.strokeStyle = '#F15050'
        }
        this.ctx.stroke()
        this.ctx.closePath()
    }

}

function formatPercent(param) {
    if (typeof param == 'string' && param.indexOf('%') == param.length - 1) {
        return param.slice(0, -1) / 100
    }
}

function angelToRadian(param,name) {     
    return param * Math.PI / 180
}