<template>
    <div class="inspect-chart" style="color:#fff">
        <div v-for="(data, index) in inspectList" class="inspect-chart-display" :key="'inspectList' + index">
            <canvas-round :value="data.value" :threshold="data.threshold" :data="data"></canvas-round>
        </div>
        <template slot="loding">
            <slot name="loding"/>
        </template>
    </div>
</template>

<script>
import CanvasRound from './canvasround'
export default {
    name:'inspectdata',
    components: {
        CanvasRound
    },
    props: {
        mainInfo: {
            type: Object,
            default () {
                return null
            }
        },
        limitMap: {
            type: Object,
        }
    },
    watch: {
        mainInfo: {
            deep: true,
            handler (val) {
                this.mainInfo.temp = Number(val.temp)
                this.mainInfo.wet = Number(val.wet)
                this.mainInfo.wind = Number(val.wind)
                this.mainInfo.noice = Number(val.noice)
                this.mainInfo.pm2point5 = Number(val.pm2point5)
                this.mainInfo.pm10 = Number(val.pm10)
                this.mainInfo.tsp = Number(val.tsp)
            }
        }
    },
    computed: {
        inspectList () {
            if (this.mainInfo === null) return []
            let list =  [
                {
                    title: '温度',
                    value: this.mainInfo.temp.toFixed(1),
                    unit: '°C',
                    threshold: this.limitMap.tempHighLevel,
                    color: '#179BC1',
                    tip: '预警'
                },
                {
                    title: '湿度',
                    value: this.mainInfo.wet.toFixed(2),
                    unit: '%RH',
                    threshold: this.limitMap.wetHighLimit,
                    color: '#179BC1',
                    tip: '预警'
                },
                {
                    title: '风速',
                    value: this.mainInfo.wind.toFixed(2),
                    unit: 'm/s',
                    threshold: this.limitMap.windHighLimit,
                    color: '#179BC1',
                    tip: '预警'
                },
                {
                    title: '风向',
                    value: this.mainInfo.direction,
                    unit: '',
                    color: '#179BC1'
                },
                {
                    title: '噪声',
                    value: this.mainInfo.noice.toFixed(2),
                    unit: 'db',
                    threshold: this.limitMap.noiceHighLimit,
                    color: '#179BC1',
                    tip: '预警'
                },
                {
                    title: 'PM2.5',
                    value: this.mainInfo.pm2point5.toFixed(2),
                    unit: 'ug/m³',
                    threshold: this.limitMap.pm2point5HighLimit,
                    color: '#179BC1',
                    tip: '预警'
                },
                {
                    title: 'PM10',
                    value: this.mainInfo.pm10.toFixed(2),
                    unit: 'ug/m³',
                    threshold: this.limitMap.pm10HighLimit,
                    color: '#179BC1',
                    tip: '预警'
                },
                {
                    title: 'TSP',
                    value: this.mainInfo.tsp.toFixed(2),
                    unit: 'ug/m³',
                    threshold: this.limitMap.tspHighLimit,
                    color: '#179BC1',
                    tip: '预警'
                }
            ]
            return list
               
        }
    },
    // computed: {
    //     inspectList () {
    //         return this.mainInfo.pm10
    //     }
    // },
    methods: {
        changeData (index) {
        }
    }
    // mounted () {
    //     debugger
    //     var o1= drawRing({
    //         id:'canvas1',
    //         color:'#45ffcd',
    //         total:1110000,
    //         variation:618456,
    //         numid:"num1"
    //     });
    // }
}
</script>

<style lang="less" scoped>
.inspect-chart{
    display: flex;
    flex-direction: row;
    margin-top: 45px;
    &-display {
    }
}
</style>>