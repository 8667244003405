<template>
    <div class="container safty-info">
        <div class="container__content">
            <div class="container__border"></div>
            <div class="container__title">今日车辆进出明细</div>
        </div>
        <list :data="recordList"/>
    </div>
</template>

<script> 
import { mapGetters } from 'vuex'
import List from './list'
export default {
    components: {
        List
    },
    computed: {
        ...mapGetters(['recordList']),
    }
}
</script>

<style lang="less" scoped>
.safty-info {
    height: 468px;
}
</style>

