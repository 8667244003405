<template>
    <div class="table-list" :style="`height: ${tableRowHeight*5}px`">
        <div>
            <ul class="table hd">
                <li class="table-row">
                    <div class="table-row__cell name">姓名</div>
                    <div class="table-row__cell team">班组名称</div>
                    <div class="table-row__cell time">入场时间</div>
                </li>
            </ul>
        </div>
        <vue-seamless-scroll class="recordlist"
            :style="`height: ${tableRowHeight*4}px`"
            v-if="data && data.length"
            :data="data"
            :class-option="classOption">
            <ul  class="table">
                <li class="table-row" v-for="item in data" :key="item.id" :style="`height: ${tableRowHeight}px`">
                    <div class="table-row__cell name">{{item.builderName}}</div>
                    <div class="table-row__cell team">{{item.teamName ? item.teamName : '项目人员'}}</div>
                    <div class="table-row__cell time">{{item.atteTime}}</div>
                </li>
            </ul>
        </vue-seamless-scroll>
        <div v-else class="empty">
            暂无数据
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default () {
                return []
            }
        },
        tableRowHeight: {
            type: Number,
            default: 38
        }
    },
    data () {
        return {
            classOption: {
                singleHeight: this.tableRowHeight,
            }
        }
    }
}
</script>

<style lang="less" scoped>
.recordlist {
    // height: 190px;
    overflow: hidden;
}
.empty {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 150px;
    text-align: center;
}
.table {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #40D5FF;
    .table-row {
        display: flex;
        height: 38px;
        align-items: center;
        background: rgba(7, 51, 72, 1);
        padding: 0 12px;
        &:nth-child(odd) {
            background: rgba(6, 41, 57, 1);
        }
        &__cell {
            white-space: nowrap;
            overflow: hidden;
            flex: none;
            &.name {
                width: 120px;
            }
            &.team {
                width: 220px;
                padding-left: 24px;
            }
            &.time {
                width: 120px;
                padding-left: 24px;
            }
        }
    }
    &.hd {
        .table-row {
            background: rgba(7, 51, 72, 1)!important;
            color: #fff;
        }
    }
}
</style>