<template>
  <div class="grid">
    <div class="grid-item">
      <env-overall :id="String(id)" />
      <v-gaps :height="16" />
      <pm10-info
        :pmTimeData="envTimeData"
        :limitMap="limitMap"
      />
      <v-gaps :height="16" />
      <pm2-info
        :pmTimeData="envTimeData"
        :limitMap="limitMap"
      />
    </div>
    <div class="grid-item">
      <inspect-data
        :mainInfo="mainInfo"
        :limitMap="limitMap"
        :serialId="String(id)"
      />
      <div class="selector">
        <equip-select
          :envEquipList="equipList"
          @currentEquipId="currentEquipId"
        />
      </div>
      <v-gaps :height="16" />
      <he-weather />
    </div>
    <div class="grid-item">
      <warning :machineCode="String(id)" />
      <v-gaps :height="16" />
      <tsp-info
        :tspTimeData="envTimeData"
        :limitMap="limitMap"
      />
      <v-gaps :height="16" />
      <noice-info
        :noiceTimeData="envTimeData"
        :limitMap="limitMap"
      />
    </div>
  </div>
</template>
<script>
import EquipSelect from "@/view/project/components/env/equip-select"
import EnvOverall from "@/view/project/components/env/env-overall"
import Pm10Info from "@/view/project/components/env/pm10-info"
import Pm2Info from "@/view/project/components/env/pm2.5-info"
import InspectData from "@/view/project/components/env/inspect-data"
import HeWeather from "@/view/project/components/env/he-weather"
import Warning from "@/view/project/components/env/warning"
import TspInfo from "@/view/project/components/env/tsp-info"
import NoiceInfo from "@/view/project/components/env/noice-info"
import VGaps from "@/view/project/components/v-gaps"
import ws from "websocketjs" //导入websocketjs
import { mapGetters } from "vuex"
import { fetchFindDeviceForComBox } from "@/api"
import { v4 as uuidv4 } from "uuid"
export default {
  name: "env",
  components: {
    EquipSelect,
    EnvOverall,
    Pm10Info,
    Pm2Info,
    InspectData,
    HeWeather,
    Warning,
    TspInfo,
    NoiceInfo,
    VGaps,
  },
  data() {
    return {
      ws: null,
      mainInfo: null,
      envTimeData: {},
      limitMap: {},
      serialNo: "", // 设备编号
      equipList: [],
      equip: "",
      id: "",
      ///心跳包定时
      timer: null,
      project: "",
    }
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.project = val
          this.close()
          fetchFindDeviceForComBox(val).then(({ data }) => {
            if (data && data.length) {
              this.id = data[0].id
              this.equipList = data
              this.$nextTick(() => {
                this.connectWs(val)
              })
            } else {
              this.id = 0
              this.equipList = []
              this.$nextTick(() => {
                this.connectWs(val)
              })
            }
          })
        }
      },
    },
  },
  methods: {
    connectWs(val) {
      console.log(val)
      //   this.ws = new ws(`ws://192.168.0.143:89/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, '')}`)
      // this.ws = new ws(`ws://192.168.1.230:8090/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, '')}`)
      this.close()
      this.ws = new ws(`${process.env.VUE_APP_WS_PATH}/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, "")}`)
      this.ws.onmessage = this.onWsmessage
      this.ws.onopen = this.openWS
      this.ws.onclose = this.closeWs
      // this.timer = setInterval(this.heartbeat, 1000)
    },
    currentEquipId(id) {
      this.id = id
      this.connectWs(this.projectId)
    },
    close() {
      if (this.ws != null) {
        this.ws.close()
        this.ws = null
      }
    },
    // heartbeat() {
    //     if(this.ws) this.ws.send(JSON.stringify({
    //         "token": this.id,
    //         "heartbeat":true,
    //     }))
    // },
    // cancelHearbeat() {
    //     if(this.timer != null) clearInterval(this.timer)
    // },
    closeWs(e) {
      // this.cancelHearbeat()
      console.log(e)
    },
    onWsmessage(msg) {
      let data = {}
      if (msg.data) data = JSON.parse(msg.data)
      if (data["heartbeat"] == true) return
      if (data.mainInfo != undefined) this.mainInfo = data.mainInfo
      if (data != undefined) this.envTimeData = data
      if (data.limitMap != undefined && data.limitMap != null) this.limitMap = data.limitMap
    },
    openWS() {
      if (this.ws)
        this.ws.send(
          JSON.stringify({
            msg: this.id,
            // "heartbeat":true
          })
        )
    },
    equipChange(id) {
      this.id = id
      this.connectWs(this.project)
    },
  },
  beforeDestroy() {
    this.close()
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 440px 976px 440px;
  grid-column-gap: 16px;
  &-item {
    position: relative;
    .selector {
      position: absolute;
      position: absolute;
      top: 18px;
      right: 16px;
    }
  }
}
</style>
