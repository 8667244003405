<template>
  <div class="issue-info">
    <!-- <v-chart
      v-if="!isEmpty"
      :option="option"
    ></v-chart> -->
    <div
      ref="charts"
      v-if="!isEmpty"
    ></div>
    <div
      v-else
      class="notData"
    >
      <img src="../../../../../assets/image/emptyRound.png" />
      <div class="notData-word">暂无数据</div>
    </div>
  </div>
</template>

<script>
import chart from "@/mixins/charts"
export default {
  mixins: [chart],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  methods: {
    //正序排序 pro - key
    compare(pro) {
      return (obj1, obj2) => {
        let a = obj1[pro]
        let b = obj2[pro]
        return a - b
      }
    },
  },
  computed: {
    isEmpty() {
      return this.option.series[0].data.length === 0
    },
    option() {
      return {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Medium, SourceHanSansCN",
            fontWeight: 500,
          },
          formatter(params) {
            let item1 = params
            return `
                            <p style="margin-bottom: 10px; ">${item1.seriesName}<p>
                            <p>${item1.data.name} : ${item1.data.value} (${item1.percent}%)<p>
                        `
          },
        },
        avoidLabelOverlap: true,
        series: [
          {
            name: this.name,
            type: "pie",
            radius: ["39%", "53%"],
            avoidLabelOverlap: false, //防止标签重合
            startAngle: "100", //旋转，起始角度
            minAngle: "10", //最小角度
            right: "7%",
            label: {
              color: "#FFFFFF",
              fontSize: "12px",
              fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
              fontWeight: "400",
              show: true,
              formatter: "{b}\n {d}%",
              textShadowColor: "transparent",
            },
            labelLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
              length: 10,
              length2: 15,
            },
            data: this.data,
            itemStyle: {
              color: function (params) {
                let colors = ["#40D5FF", "#169BC1", "#076985"]
                return colors[params.dataIndex % 3]
              },
            },
            visualMap: {
              show: false,
              min: 80,
              max: 600,
              inRange: {
                colorLightness: [0, 1],
              },
            },
          },
        ],
      }
    },
  },
}
</script>

<style lang="less" scoped>
.issue-info {
  position: relative;
  width: 326px;
  height: 192px;
  .notData {
    width: 72px;
    height: 96px;
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -36px;
    &-word {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      height: 12px;
      text-align: center;
      margin-top: 12px;
    }
    img {
      width: 72px;
      height: 72px;
    }
  }
}
</style>
