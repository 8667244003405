<template>
  <div class="container issue-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">问题概况</div>
      <div class="selector">
        <time-select
          v-model="dataTime"
          :data="timeList"
        />
      </div>
    </div>
    <div class="issue-info-content">
      <div class="issue-info-content-total">
        <div class="issue-info-content-total-word">
          <div class="issue-info-content-total-word-center">
            <div class="issue-info-content-total-word-center-num">
              <count-to
                :start-val="0"
                :end-val="total"
              />
            </div>
            <div class="issue-info-content-total-word-center-unit">个</div>
          </div>
          <div class="issue-info-content-total-word-tip">问题累积</div>
        </div>
      </div>
      <div class="issue-info-content-radio">
        <div class="issue-info-content-radio-word">按时整改率：</div>
        <div class="issue-info-content-radio-num">
          <count-to
            :start-val="0"
            :decimals="2"
            :end-val="Number(rate)"
            separator=""
          />
        </div>
        <div class="issue-info-content-radio-unit">%</div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchQueryCycle } from "@/api"
import TimeSelect from "./time-select"
import CountTo from "@/components/count-to.vue"
export default {
  components: {
    TimeSelect,
    CountTo,
  },
  props: {
    overview: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    overview(val) {
      this.total = val.total
      this.rate = val.rate
    },
  },
  data() {
    return {
      dataTime: 0,
      timeList: [],
      total: 0,
      rate: 0.0,
    }
  },
  created() {
    return fetchQueryCycle().then(({ data }) => {
      this.timeList = data
    })
  },
}
</script>

<style lang="less" scoped>
.issue-info {
  height: 468px;
  position: relative;
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }
  &-content {
    &-total {
      margin: 35px 58px 0px 57px;
      height: 275px;
      width: 275px;
      background-image: url("../../../../../assets/image/issue/round.gif");
      position: relative;
      &-word {
        width: 150px;
        height: 76px;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        &-center {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          &-num {
            font-size: 56px;
            font-family: Bebas;
            color: #40d5ff;
            padding-right: 2px;
          }
          &-unit {
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
            padding-bottom: 6px;
            padding-right: 2px;
          }
        }
        &-tip {
          font-size: 16px;
          margin-top: 8px;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }
    }
    &-radio {
      width: 274.56px;
      height: 75px;
      margin: 0px 58px 26px 57px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      &-word {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        margin-right: 8px;
      }
      &-num {
        font-size: 36px;
        font-family: Bebas;
        color: #ffa500;
      }
      &-unit {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>
