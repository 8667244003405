<template>
  <div class="flex">
    <div class="flex-part">
      <div class="flex-part-itemA">
        <epidemic-info />
      </div>
      <div class="flex-part-itemB">
        <epidemic-detail />
        <v-gaps :height="16" />
        <warning />
      </div>
    </div>
  </div>
</template>
<script>
import EpidemicInfo from "@/view/project/components/epidemic/epidemic-info"
import EpidemicDetail from "@/view/project/components/epidemic/epidemic-detail"
import Warning from "@/view/project/components/epidemic/warning"
import VGaps from "@/view/project/components/v-gaps"
export default {
  name: "App",
  components: {
    EpidemicInfo,
    EpidemicDetail,
    Warning,
    VGaps,
  },
  currentId: {
    type: String,
    default: "1",
  },
  computed: {
    name() {
      return []
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  width: 1888px;
  &-part {
    // display: flex;
    margin-right: 16px;
    &-itemA {
      display: flex;
    }
    &-itemB {
      display: flex;
      margin-top: 16px;
    }
  }
}
</style>
