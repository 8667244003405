<template>
  <div class="container ranklist">
    <div class="container__content title">
      <div class="container__border"></div>
      <div class="container__title">班组评分排名</div>
    </div>
    <ul class="table hd">
      <li class="table-row">
        <div class="table-row__cell team">班组名称</div>
        <div class="table-row__cell number">班组负责人</div>
        <div class="table-row__cell score">班组评分</div>
      </li>
    </ul>
    <vue-seamless-scroll
      class="recordlist"
      v-if="list && list.length > 5"
      :data="list"
      :class-option="classOption"
    >
      <ul
        v-if="list && list.length"
        class="table"
      >
        <li
          class="table-row"
          v-for="(item, index) in list"
          :key="'record' + index"
        >
          <div class="table-row__cell team">
            <marquee-text
              v-if="!isLoading"
              :text="item.teamName"
            ></marquee-text>
            <div v-else>{{ item.teamName }}</div>
          </div>
          <div class="table-row__cell number">{{ item.leaderName }}</div>
          <div class="table-row__cell score">{{ item.evalScore }}</div>
        </li>
      </ul>
    </vue-seamless-scroll>
    <ul
      v-else-if="list && list.length < 7 && list.length > 0"
      class="table"
    >
      <li
        class="table-row"
        v-for="item in list"
        :key="item.id"
      >
        <div class="table-row__cell team">
          <marquee-text
            v-if="!isLoading"
            :text="item.teamName"
          ></marquee-text>
          <div v-else>{{ item.teamName }}</div>
        </div>
        <div class="table-row__cell number">{{ item.leaderName }}</div>
        <div class="table-row__cell score">{{ item.evalScore }}</div>
      </li>
    </ul>
    <div
      v-else
      class="empty"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import MarqueeText from "../../marquee-text"
import { mapGetters } from "vuex"
import { fetchRankList } from "@/api"
export default {
  // inject: ['isLoading'],
  components: {
    MarqueeText,
  },
  data() {
    return {
      classOption: {
        singleHeight: 38,
      },
      list: [],
      isLoading: false,
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchRankList(val).then(({ data }) => {
              this.list = data
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.recordlist {
  height: 228px;
  overflow: hidden;
}
.ranklist {
  width: 339px;
  height: 339px;
  .title {
    margin-bottom: 17px;
  }
  .table {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #40d5ff;
    padding: 0 16px;
    .table-row {
      display: flex;
      height: 38px;
      align-items: center;
      background: rgba(6, 41, 57, 1);
      &:nth-child(even) {
        background: rgba(7, 51, 72, 1);
      }
      &__cell {
        white-space: nowrap;
        overflow: hidden;
        flex: none;
        box-sizing: border-box;
        &.team {
          width: 139px;
          padding-left: 12px;
          display: flex;
          align-items: center;
          .rank {
            line-height: 12px;
            width: 12px;
            background: url("../../../../../assets/image/ranklist/rank.png");
            background-size: cover;
            background-position: center;
            font-size: 18px;
            font-family: Bebas;
            color: #ffffff;
            text-shadow: 1px 1px 0px #288eff;
            text-align: center;
            margin-right: 8px;
            span {
              display: inline-block;
              line-height: 1;
              transform: scale(0.5);
            }
          }
        }
        &.number {
          width: 84px;
          text-align: left;
          padding-left: 24px;
        }
        &.score {
          flex: 1;
          padding-left: 24px;
          text-align: left;
        }
      }
    }
    &.hd {
      .table-row {
        background: rgba(7, 51, 72, 1) !important;
        color: #fff;
        &__cell {
          &.team {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .empty {
    height: 228px;
    line-height: 228px;
  }
}
</style>
