<template>
  <div class="container safety-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">安全问题情况</div>
    </div>
    <div class="safety-info-content">
      <div class="safety-info-content-issue">
        <div class="safety-info-content-issue-total">
          <div class="safety-info-content-issue-total-name">安全问题：</div>
          <div class="safety-info-content-issue-total-value">
            <count-to
              :start-val="0"
              :end-val="issueTotal"
            />
          </div>
          <div class="safety-info-content-issue-total-unit">个</div>
        </div>
        <div
          class="safety-info-content-issue-part"
          v-for="(item, index) in issueNumList"
          :key="'safety' + index"
        >
          <img :src="item.icon" />
          <div class="safety-info-content-issue-part-name">{{ item.name }}</div>
          <div class="safety-info-content-issue-part-value">
            <count-to
              :start-val="0"
              :end-val="item.value"
            />
          </div>
          <div class="safety-info-content-issue-part-unit">{{ item.unit }}</div>
        </div>
      </div>
      <div class="safety-info-content-analyse">
        <div class="safety-info-content-analyse-pie">
          <issue-pie
            :data="checkItemList"
            :name="name"
          />
          <!-- <div v-else class="notData">
                        <img src="../../../assets/image/emptyRound.png"/>
                        <div class="notData-word">暂无数据</div>
                    </div> -->
        </div>
        <div class="safety-info-content-analyse-name">安全问题类型分析</div>
      </div>
    </div>
  </div>
</template>

<script>
import IssuePie from "../issue-pie"
import hasDelete from "@/assets/image/issue/hasDelete.png"
import waitDelete from "@/assets/image/issue/waitDelete.png"
import waitChange from "@/assets/image/issue/waitChange.png"
import CountTo from "@/components/count-to.vue"
export default {
  components: {
    IssuePie,
    CountTo,
  },
  props: {
    typeItem: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    typeItem(val) {
      if (val && val.length) {
        this.checkItemList = val[1].checkItemList //问题类型分析列表
        this.issueTotal = val[1].total
        this.issueNumList[0].value = val[1].statistics[0].value
        this.issueNumList[1].value = val[1].statistics[1].value
        this.issueNumList[2].value = val[1].statistics[2].value
      } else {
        this.checkItemList = [] //问题类型分析列表
        this.issueTotal = 0
        this.issueNumList[0].value = 0
        this.issueNumList[1].value = 0
        this.issueNumList[2].value = 0
      }
    },
  },
  data() {
    return {
      checkItemList: [],
      issueTotal: 0,
      name: "安全问题类型分析",
      isShow: true,
      issueNumList: [
        {
          name: "已销项：",
          value: 0,
          unit: "个",
          icon: hasDelete,
        },
        {
          name: "待销项：",
          value: 0,
          unit: "个",
          icon: waitDelete,
        },
        {
          name: "待整改：",
          value: 0,
          unit: "个",
          icon: waitChange,
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.safety-info {
  height: 468px;
  &-content {
    padding: 47px 0px 28px 24px;
    display: flex;
    &-issue {
      height: 303px;
      width: 248px;
      &-total {
        display: flex;
        width: 200px;
        height: 73px;
        margin-left: 24px;
        &-name {
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          padding-top: 22px;
        }
        &-value {
          height: 43px;
          line-height: 45px;
          letter-spacing: 1px;
          font-size: 32px;
          font-family: Bebas;
          color: #40d5ff;
          margin-left: 8px;
        }
        &-unit {
          font-size: 18px;
          height: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          padding-top: 20px;
          margin-left: 2px;
        }
      }
      &-part {
        display: flex;
        width: 200px;
        height: 66px;
        align-items: center;
        margin: 0px 24px 16px 24px;
        background-image: url("../../../../../assets/image/issue/iconBackground.png");
        img {
          width: 20px;
          height: 20px;
          margin-left: 18px;
        }
        &-name {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          margin-left: 8px;
          padding-top: 2px;
        }
        &-value {
          height: 24px;
          font-size: 18px;
          font-family: Bebas;
          color: #ffffff;
          padding-top: 3px;
        }
        &-unit {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          margin-left: 2px;
          padding-top: 2px;
        }
      }
    }
    &-analyse {
      width: 449px;
      margin-bottom: 38px;
      &-pie {
        height: 303px;
        position: relative;
        .notData {
          width: 120px;
          height: 150px;
          position: absolute;
          top: 76px;
          left: 50%;
          margin-left: -60px;
          &-word {
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            height: 16px;
            text-align: center;
            margin-top: 14px;
          }
          img {
            width: 120px;
            height: 120px;
          }
        }
      }
      &-name {
        height: 24px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 34px;
      }
    }
  }
}
</style>
