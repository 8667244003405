<template>
  <div
    class="env-item"
    :class="{ 'env-item--warning': value > threshold }"
  >
    <div class="env-item-content">
      <div class="env-item-content__value">
        <span
          class="value"
          :style="{
            fontSize: fontSize,
          }"
          ><!--
                    --><template v-if="text">{{ text ? text : value }}</template
          ><!--
                    --><count-to
            v-else
            :start-val="0"
            :decimals="1"
            :end-val="Number(value)"
            separator=""
          />
        </span>
        <span class="unit">{{ unit }}</span>
      </div>
      <div class="env-item-content__modifier">
        <span class="title">{{ title }}</span>
      </div>
    </div>
    <img :src="icon" />
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
export default {
  components: {
    CountTo,
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    text: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default: 21,
    },
    threshold: {
      type: Number,
      default: Infinity,
    },
    unit: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "28px",
    },
  },
  computed: {
    isNumber() {
      return typeof this.value === "number"
    },
  },
}
</script>

<style lang="less" scoped>
.env-item {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  justify-content: flex-end;
  img {
    display: block;
    width: 70px;
    height: 70px;
    padding-right: 14px;
  }
  &.env-item--warning {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url("../../../../../assets/image/env/envwarning.png");
      background-position: center;
      // background-size: cover;
      animation: flash 2.5s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      z-index: 100;
    }
  }
  &-content {
    position: relative;
    margin-right: 10px;
    z-index: 2;
    &__value {
      text-align: left;
      margin-bottom: 12px;
      .value {
        font-size: 28px;
        font-family: Bebas;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
        margin-right: 2px;
      }
      .unit {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
      }
    }
    &__modifier {
      text-align: right;
      .title {
        padding: 0;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
