<template>
  <div class="hello-ezuikit-js">
    <div :id="name" :style="styleObject"></div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";

export default {
  props: {
    accessToken: String,
    autoplay:{
        type: Boolean,
        default: true
    },
    url: String,
    width:{
        type: Number,
    },
    height:{
        type: Number,
    },
    autoResize: {
      type: Boolean,
      default: false
    },
    name:{
      type: String,
      default:'video-container'
    }
  },
  watch:{
    url(url) {
      this._changeUrl()
    },
  },
  data(){
    return {
      player: null,
      widthScale: 1,
      heightScale: 1,
      viewWidth: 0,
      viewHeight: 0,
      styleObject: {
        color: 'red',
        width: this.viewWidth+'px',
        height: this.viewHeight+'px'
      }
    }
  },
  name:'EzuiVideo',
  methods:{
    ///更换监控窗口
    _changeUrl() {
      if (this.player != null) {
        ///切换视频流前先要关闭当前视频流
        this.close()
        this.player.play({url: this.url});
      }else {
        this.open()
      }
    },
    open() {
      if (this.player == null) {
        this.player =  new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: this.name,
        accessToken: this.accessToken,
        url: this.url,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        width: this.viewWidth,
        height: this.viewHeight
      });
      }else {
        this.player.play({url: this.url})
      }
    },
    close() {
      if(this.player != null) {
        this.player.stop();
      }
    },
},
created() {
  //计算当前大小与窗口的比例
  this.widthScale = this.width/document.documentElement.clientWidth
  this.heightScale = this.height/document.documentElement.clientHeight
  this.viewWidth = this.width
  this.viewHeight = this.height
},
mounted() {
  if (this.autoResize) {
    let that = this
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      that.viewWidth = document.documentElement.clientWidth*that.widthScale
      that.viewHeight = document.documentElement.clientHeight*that.heightScale
      if(this.player != null)this.player.reSize(that.viewWidth, that.viewHeight)
    }
  }
},
beforeDestroy() {
  this.close()
  this.player = null
}
};
</script>
