<template>
  <div class="h-gaps" :style="{
    width: `${width}px`
  }"></div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="less" scoped>
.h-gaps {
  flex: none;
}
</style>