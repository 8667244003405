<template>
  <div class="item">
    <div class="item-value">
      <div class="item-value-num">
        <count-to
          :start-val="0"
          :decimals="1"
          :end-val="value"
        />
      </div>
      <div
        class="item-value-unit"
        :class="{ 'item-value-unit--top': unitTop == true }"
      >
        {{ unit }}
      </div>
    </div>
    <div class="item-name">{{ name }}</div>
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
export default {
  components: {
    CountTo,
  },
  props: {
    value: {
      type: Number,
      default: "0",
    },
    name: {
      type: String,
      default: "名称",
    },
    unit: {
      type: String,
      default: "",
    },
    unitTop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  margin: 0px 18px;
  margin-bottom: 14px;
  background-image: url("../../../../../assets/image/crane/bg2.gif");
  &-value {
    display: flex;
    width: 190px;
    height: 71px;
    // background: linear-gradient(360deg, rgba(64, 213, 255, 0.2) 0%, rgba(64, 213, 255, 0) 100%);
    font-size: 32px;
    font-family: Bebas;
    color: #ffffff;
    letter-spacing: 1px;
    align-items: center;
    justify-content: center;
    &-unit {
      font-size: 20px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      flex-direction: column;
      padding-left: 2px;
      padding-top: 12px;
      &.item-value-unit--top {
        margin-top: -30px;
      }
    }
  }
  &-name {
    width: 190px;
    height: 40px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #40d5ff;
    line-height: 40px;
    letter-spacing: 1px;
    text-align: center;
  }
}
</style>
