import * as echarts from "echarts"
export default {
  data() {
    return {
      myChart: null,
    }
  },
  computed: {
    option() {
      return {}
    },
  },
  methods: {
    initCharts() {
      this.$nextTick(() => {
        var chartDom = this.$refs.charts
        if (!this.myChart && chartDom) {
          if (!chartDom.style.width) chartDom.style.width = "100%"
          if (!chartDom.style.height) chartDom.style.height = "100%"
          this.myChart = echarts.init(chartDom, null, {
            devicePixelRatio: window.devicePixelRatio || 1,
          })
        }
        this.myChart.setOption(this.option)
      })
    },
    disposeCharts() {
      this.myChart.dispose() // 销毁图表
      this.myChart = null
    },
  },
}
