<template>
    <div class="container pm10">
        <div class="container__content">
            <div class="container__border"></div>
            <div class="container__title">噪音</div>
            <div class="selector">
                <time-select @currentValue='currentValue'/>
            </div>
        </div>
        <div class="pm10-info">
            <noice-charts :pmData="noiceData" :pmLimitData="noiceLimitData"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TimeSelect from '../time-select'
import NoiceCharts from './noice-charts'
export default {
    components: {
        TimeSelect,
        NoiceCharts
    },
    props: {
        noiceTimeData: {
            type: Object,
            default () {
                return null
            }
        },
        limitMap: {
            type: Object,
            default () {
                return null
            }
        }
    },
    data () {
        return {
            id: '1',
            noiceData: [],
            noiceLimitData: 0,
            noiceCurrenData: {},

        }
    },
    computed: mapGetters(['projectId']),
    watch: {
        limitMap (val) {
            if (val) {
                this.noiceLimitData = val.noiceHighLimit
            } else {
                this.noiceLimitData = ''
            }
        },
        noiceTimeData (val) {
            if (val) {
                this.noiceCurrenData = val
                if (this.id == '1') {
                    this.noiceData = val.noiceDayData.rows
                } else {
                    this.noiceData = val.noiceMonthData.rows
                }
            }
        }
    },
    methods: {
        currentValue (id) {
            this.id = id
            if (this.id == '1') {
                this.noiceData = this.noiceCurrenData.noiceDayData.rows
            } else {
                this.noiceData = this.noiceCurrenData.noiceMonthData.rows
            }
        }
    }
}
</script>

<style lang="less" scoped>
.pm10 {
    height: 307px;
    position: relative;
    .selector{
        position: absolute;
        top: 18px;
        right: 16px;
        z-index: 1000;
    }
    &-info {
        width: 424px;
        height: 228px;
        margin: 7px 16px 16px 16px;
    }
}
</style>