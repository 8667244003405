<template>
    <div class="health">
        <div class="health-header">健康码详情</div>
        <div class="health-main">
            <div v-for="item in qrCodeList" :key="item" style="display: flex; flex-wrap: wrap;">
                <img :key="item" :src="item.qrcodeUrl" class="info" :class="{go: rotate}"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { fetchEpidemicQrCodeList } from '@/api'
export default {
    data () {
        return {
            rotate: false,
            qrCodeList: [],
            total: 57,
        }
    },
    computed: mapGetters(['projectId']),
    watch: {
        projectId: {
            immediate: true,
            handler (val) {
                if (val) {
                    let num = 1
                    console.log('请求体温')
                    this.request(() => {
                        this.rotate = false
                        if (num <= this.total/3) {
                            num +=1
                        } else {
                            num = 1
                        }
                        console.log('请求体温1')
                        return fetchEpidemicQrCodeList(val, num, 3).then(({data}) => {
                            this.qrCodeList = data.page.list
                            this.total = data.page.total
                            let timer = setTimeout(() => {
                                this.rotate = !this.rotate
                                clearInterval(timer)
                            }, 200)
                        })
                    }, 6000)
                }
            }
        }
    },
    mounted () {
    },
    destroyed () {
       this.cancel()
    }
}
</script>
<style lang="less" scoped>
.health {
    width: 623px;
    height: 287px;
    margin-left: 18px;
    background: #0C1828;
    opacity: 0.89;
    margin-top: 12px;
    &-header {
        width: 290px;
        height: 40px;
        background: linear-gradient(316deg, rgba(48, 242, 255, 0.01) 0%, rgba(13, 157, 255, 0.5) 100%);
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 40px;
        padding-left: 24px;
    }
    &-main {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 50px 0 53px;
        position: relative;
        transform-style: preserve-3d;
        .info{
            height: 232px;
            width: 136px;
            margin: 0px 18px 0 15px;
            /*隐藏背面图片*/
            backface-visibility: hidden;
            transition: all 1s ease;
            /*防止跳变bug*/
            transform: translateZ(0px) rotateY(180deg);
        }
        .go {
            transform: perspective(800px) rotateY(0deg);
        }
    }
}
</style>