<template>
  <div class="container question">
    <div class="container__content">
      <div class="container__border"></div>
      <div
        class="container__title"
        v-if="moduleContent == 7"
      >
        安全通道监控
      </div>
      <div
        class="container__title"
        v-else-if="moduleContent == 5"
      >
        问题整改
      </div>
    </div>
    <safty-ailse v-if="moduleContent == 7"></safty-ailse>
    <div
      class="question-container"
      v-else-if="moduleContent == 5"
    >
      <!-- <v-chart class="chart" :option="option"/> -->
      <div class="question-sum">
        <div class="detail-wrap-total">
          <div class="title">
            问题累计
            <div class="num">
              <count-to
                :start-val="0"
                :end-val="overview.total"
              />
            </div>
            <span>个</span>
          </div>
          <div class="item">
            按时整改率
            <div class="num">
              <count-to
                :start-val="0"
                :decimals="2"
                :end-val="Number(overview.rate)"
              />
            </div>
            <span>%</span>
          </div>
        </div>
      </div>
      <div class="question-branch">
        <img
          :src="branch"
          alt=""
          ref="gif"
        />
      </div>
      <div class="question-detail-wrap">
        <div class="question-detail">
          <div class="detail-wrap">
            <div class="title">
              质量问题
              <div class="num">
                <count-to
                  :start-val="0"
                  :end-val="qualityTotal"
                />
              </div>
              <span>个</span>
            </div>
            <div
              v-for="(item, index) in qualityList"
              :key="'qualityList' + index"
              style="margin-bottom: 4px"
            >
              <div class="item">
                {{ item.name }}
                <div class="num">
                  <count-to
                    :start-val="0"
                    :end-val="item.value"
                  />
                </div>
                <span>个</span>
              </div>
            </div>
          </div>
          <div class="q-echarts">
            <chart
              :data="qualityPie"
              :name="qualityName"
            ></chart>
            <div class="q-echarts-title">问题类型分析</div>
          </div>
        </div>
        <div class="question-detail">
          <div class="detail-wrap">
            <div class="title">
              安全问题
              <div class="num">{{ saftyTotal }}</div>
              <span>个</span>
            </div>
            <div
              v-for="(item, index) in saftyList"
              :key="'saftyList' + index"
              style="margin-top: 4px"
            >
              <div class="item">
                {{ item.name }}
                <div class="num">
                  <count-to
                    :start-val="0"
                    :end-val="item.value"
                  />
                </div>
                <span>个</span>
              </div>
            </div>
          </div>
          <div class="q-echarts">
            <chart
              :data="saftyPie"
              :name="safetyName"
            ></chart>
            <div class="q-echarts-title">问题类型分析</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchOverview, fetchModuleList } from "@/api"
import { mapGetters } from "vuex"
import branch from "@/assets/image/allData/branch.gif"
import Chart from "./chart"
import CountTo from "@/components/count-to.vue"
import SaftyAilse from "./safty-ailse"
export default {
  components: {
    Chart,
    CountTo,
    SaftyAilse,
  },
  props: {
    screenId: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      ai: 0,
      env: 0,
      overtime: 0,
      tower: 0,
      branch: branch,
      queryCycle: 0,
      overview: {
        total: 0,
        rate: 0.0,
      },
      total: 0,
      qualityTotal: 0,
      saftyTotal: 0,
      safetyName: "安全问题类型分析",
      qualityName: "质量问题类型分析",
      qualityList: [
        {
          name: "已销项",
          value: 0,
        },
        {
          name: "待销项",
          value: 0,
        },
        {
          name: "待整改",
          value: 0,
        },
      ],
      saftyList: [
        {
          name: "已销项",
          value: 0,
        },
        {
          name: "待销项",
          value: 0,
        },
        {
          name: "待整改",
          value: 0,
        },
      ],
      qualityPie: [],
      saftyPie: [],
      time: 8890, //ms
      moduleContent: 0,
    }
  },
  mounted() {
    this.initLibgif(this.time) //初始gif
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  methods: {
    initLibgif(time) {
      var rub = new SuperGif({ gif: this.$refs.gif, progressbar_height: 0, loop_delay: time, auto_play: false })
      rub.load(function () {
        rub.play()
      })
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchOverview(val, this.queryCycle).then(({ data }) => {
              this.overview = data.overview
              if (data.typeItem && data.typeItem.length) {
                this.qualityTotal = data.typeItem[0].total
                this.saftyTotal = data.typeItem[1].total
                this.qualityList = data.typeItem[0].statistics
                this.saftyList = data.typeItem[1].statistics
                this.qualityPie = data.typeItem[0].checkItemList
                this.saftyPie = data.typeItem[1].checkItemList
              } else {
                this.qualityPie = []
                this.saftyPie = []
              }
            })
          })
          return fetchModuleList(val, this.screenId).then(({ data }) => {
            this.moduleContent = data[4].moduleContent
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.question {
  position: relative;
  height: 468px;
  .q-echarts {
    position: relative;
    width: 326px;
    height: 192px;
    .notData {
      width: 72px;
      height: 96px;
      position: absolute;
      top: 40px;
      left: 50%;
      margin-left: -36px;
      &-word {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        height: 12px;
        text-align: center;
        margin-top: 12px;
      }
      img {
        width: 72px;
        height: 72px;
      }
    }
    &-title {
      width: 326px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      position: absolute;
      bottom: 4px;
      left: 50%;
      margin-left: -162px;
    }
  }
  &-container {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    height: calc(100% - 60px);
    display: grid;
    grid-template-columns: 199px 59px 518px;
    grid-template-rows: 100%;
    padding: 0px 0px 5px 23px;
    // padding: 0 16px;
    box-sizing: border-box;
  }
  &-sum {
    width: 196px;
    height: 114px;
    background: linear-gradient(270deg, rgba(7, 51, 72, 0) 0%, #073348 100%);
    box-shadow: -3px 0px 0px 0px rgba(64, 213, 255, 0.3);
    margin-top: 147px;
    padding: 25px 0px 25px 15px;
    margin-left: 4px;
    box-sizing: border-box;
  }
  &-branch {
    width: 59px;
    height: 273px;
    margin-top: 68px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-detail-wrap {
    margin-left: 2px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &-detail {
    display: flex;
    box-sizing: border-box;
    background: linear-gradient(270deg, rgba(7, 51, 72, 0) 0%, #073348 100%);
    box-shadow: -3px 0px 0px 0px rgba(64, 213, 255, 0.3);
  }
  .detail-wrap {
    padding: 30px 14px 26px 30px;
    :last-child {
      margin-bottom: 0px;
    }
  }
  .title {
    padding: 0 12px;
    height: 30px;
    box-sizing: border-box;
    width: 180px;
    margin-bottom: 4px;
    background: url("../../../../../assets/image/allData/item-bg.png");
    background-size: 100% 100%;
    // border-radius: 4px;
    // background: linear-gradient(to bottom,#40B9FF 0%, #56D6C7 100%);
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #073348;
    display: flex;
    align-items: center;
    justify-self: center;
    white-space: nowrap;
    .num {
      width: 100%;
      text-align: right;
      font-size: 16px;
      font-family: Bebas;
      color: #073348;
    }
    span {
      margin-left: 4px;
      float: right;
    }
  }
  .item {
    padding: 0 12px;
    box-sizing: border-box;
    width: 180px;
    height: 30px;
    margin-bottom: 4px;
    background: #07425e;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-self: center;
    white-space: nowrap;
    .num {
      width: 100%;
      text-align: right;
      font-size: 16px;
      font-family: Bebas;
      color: #ffffff;
    }
    span {
      margin-left: 6px;
      float: right;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
