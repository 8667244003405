<template>
    <div class="epidemic-info">
        <div class="epidemic-info-header">防疫信息概况</div>
        <div class="epidemic-info-content">
            <div class="epidemic-info-content-gird">
                <item name="今日测温总人数" :value="Number(temperatureCount)"/>
                <item name="劳务工人测温数" :value="Number(temperatureBuilderCount)"/>
                <item name="项目人员测温数" :value="Number(temperatureMemberCount)"/>
                <item name="绿码持有人数" :value="Number(greenQrcodeCount)"/>
                <item name="完成疫苗接种人数" :value="Number(finishVaccineCount)"/>
            </div>
        </div>
    </div>
</template>

<script>
import Item from './item'
import { mapGetters } from 'vuex'
import { fetchEpidemicInfo } from '@/api'
export default {
    components: {
        Item,
    },
    computed: mapGetters(['projectId']),
    watch: {
        projectId: {
            immediate: true,
            handler (val) {
                if (val) {
                    this.request(() => {
                        return fetchEpidemicInfo(val).then(({data}) => {
                            if (data) {
                                Object.assign(this._data, data)
                            }
                        })
                    })
                }
            }
        }
    },
    data () {
        return {
            temperatureCount: "0",   //测温人数
            temperatureBuilderCount: "0", //测温工人数
            temperatureMemberCount: "0",      //测温项目人员数
            greenQrcodeCount: "0",         //绿码人数
            finishVaccineCount: "0",       //完成疫苗接种人数
        }
    },
}
</script>

<style lang="less" scoped>
.epidemic-info {
    width: 1888px;
    height: 241px;
    background: #0C1828;
    opacity: 0.89;
    &-header {
        width: 290px;
        height: 40px;
        background: linear-gradient(316deg, rgba(48, 242, 255, 0.01) 0%, rgba(13, 157, 255, 0.5) 100%);
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 40px;
        padding-left: 24px;
    }
    &-content {
        padding: 31px 89px 26px 89px;
        &-gird {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
</style>