<template>
  <div class="cert" id="certContent">
      <div class="cert-content" v-if="hidden">
          <div class="cert-content-left">
              <img src="./assets/images/waring.png" width="28" height="28">
              <span class="cert-content-left-title">缺少视频插件无法播放！</span>
              <span>
                  监控视频播放需要安装官方插件，
                  <el-button type="text" @click="openUrl">点击下载</el-button>
                  并安装，完成后请刷新重试。
              </span>
          </div>
          <el-button type="text" @click="close"><img class="cert-content-left-close" src="./assets/images/close.png" width="15"></el-button>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            url: 'https://gdjcywebdata001.oss-cn-shenzhen.aliyuncs.com/InstallRootCert.exe',
            hidden: true
        }
    },
    methods: {
        openUrl() {
            window.open(this.url)
        },
        close() {
            this.hidden = false
        }
    }
}
</script>

<style lang="less" scoped>

.cert {
    position: absolute;
    top: 0;
    left: calc(100vh - 813px);
    right: calc(100vh - 813px);
    z-index: 9999;
    background: #FEF1F0;
    opacity: 0.85;
    border-radius: 4px;
    border: 1px solid #FDE2E2;
    &-content {
    padding: 0 21px 0 57px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    &-left {
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       color: #F15050;
       font-size: 16px;
       &-title {
           margin-left: 13px;
           font-size: 24px;
           font-weight: 400;
           color: #F15050;
           line-height: 18px;
       }
       &-close {
           filter:brightness(100%);
       }
       &-close:hover {
           filter:brightness(50%);
       }
    }
    .el-button {
        font-size: 16px;
    }
    }
}

</style>