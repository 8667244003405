<template>
  <div class="gauge">
    <div class="gauge-title">
      <div class="gauge-title__text">{{ title }}</div>
      <div class="gauge-title__value">
        <span
          class="value"
          :style="{
            color: titleTextColor,
          }"
          ><count-to
            :start-val="0"
            :end-val="max"
        /></span>
        <span class="unit">人</span>
      </div>
    </div>
    <div class="gauge__chart">
      <!-- <v-chart
        class="chart"
        :option="option"
      /> -->
      <div ref="charts"></div>
      <div class="gauge__desc">{{ desc }}</div>
    </div>
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
import Charts from "@/mixins/charts"
export default {
  mixins: [Charts],
  components: {
    CountTo,
  },
  props: {
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 80,
    },
    title: {
      type: String,
      default: "标题",
    },
    titleTextColor: {
      type: String,
      default: "rgba(64, 213, 255, 1)",
    },
    desc: {
      type: String,
      default: "描述",
    },
    color: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  computed: {
    option() {
      return {
        tooltip: {
          show: false,
        },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            radius: `${(150 / 170) * 100}%`,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
              clip: false,
              itemStyle: {
                borderWidth: 0,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: this.color[0], // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: this.color[1], // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            axisLine: {
              lineStyle: {
                color: [[1, "rgba(255, 255, 255, 0.1)"]],
                width: 8,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            /// max也是0的话会显示50%
            max: !this.value ? 1 : this.max,
            data: [
              {
                value: this.value,
                detail: {
                  show: true,
                  color: this.color[2],
                  fontSize: 30,
                  /// 这里用自定义字体需要鼠标移入一次才生效
                  fontFamily: "Bebas",
                  fontWeight: 400,
                  offsetCenter: ["0%", "0%"],
                },
              },
            ],
          },
        ],
      }
    },
  },
}
</script>

<style lang="less" scoped>
.gauge {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 239px;
  height: 212px;
  &-title {
    width: 239px;
    display: flex;
    &__text {
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      padding-top: 22px;
      padding-left: 26px;
    }
    &__value {
      .value {
        font-size: 32px;
        font-family: Bebas;
        color: #40d5ff;
        letter-spacing: 1px;
        vertical-align: text-bottom;
        display: inline-block;
        margin-left: 8px;
        margin-right: 2px;
        padding-top: 11px;
      }
      .unit {
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  &__chart {
    width: 170px;
    height: 170px;
    margin: 15px 0px;
    position: relative;
    .chart {
      width: 170px;
      height: 170px;
    }
    .gauge__desc {
      position: absolute;
      left: 50%;
      margin-left: -45px;
      top: 61%;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
