<template>
  <div class="container monitor">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">车辆监控视频</div>
      <div class="env-select">
        <list-select
          :list="monitorList"
          @currentType="onCurrentType"
          :currentKey="currentKey"
        />
      </div>
    </div>
    <div class="screen">
      <div
        class="wrap"
        v-if="monitor"
      >
        <!-- <ezui ref="video" :url="url" :accessToken="accessToken" :height="height" :width="width"></ezui> -->
        <div :style="{ width: '100%', height: '510px', marginTop: '20px' }">
          <hikvision-video
            v-if="reFresh"
            :item="item"
            :token="token"
            :typeNum="2"
            :src="monitor"
            :tips="0"
            autoplay
            poster="@/assets/image/monitoring.gif"
          ></hikvision-video>
        </div>
      </div>
      <div
        v-else
        class="empty"
      >
        <img src="../../../../../assets/image/monitoring.gif" />
        <!-- <div>暂无视频设备</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ListSelect from "./list-select"
import { fetchProjectCamera, fetchCameraUrl } from "@/api"
import { getTokenCamera } from "@/api"

export default {
  components: {
    ListSelect,
  },
  data() {
    return {
      monitor: "",
      monitorList: [],
      currentKey: "",
      item: "", //车辆视频参数
      token: "",
      reFresh: true,
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            // 获取视频列表
            return fetchProjectCamera(val).then(({ data }) => {
              this.monitorList = data.children
              if (this.monitorList.length >= 1) {
                this.getCameraUrl(this.monitorList[0])
              }
            })
          })
        }
      },
    },
  },
  mounted() {
    this.getToken()
  },
  methods: {
    onCurrentType(item) {
      this.getCameraUrl(item)
      this.currentKey = item.id
    },
    getCameraUrl(item) {
      console.log("这是获取当前播放的视频", item)
      this.item = item
      if (item) {
        this.request(() => {
          return fetchCameraUrl([item]).then((res) => {
            console.log(res)
            this.reFresh = false
            this.monitor = res.data[0].url
            this.$nextTick(() => {
              this.reFresh = true
            })
          })
        })
      }
    },
    // 获取token
    getToken() {
      getTokenCamera().then((data) => {
        this.token = data.data.accessToken
      })
    },
  },
}
</script>

<style lang="less" scoped>
.monitor {
  position: relative;
  width: 100%;
  height: 580px;

  .env-select {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }

  .screen {
    height: 530px;

    .empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;

      img {
        width: 580px;
        height: 396px;
      }
    }
  }
}
</style>
