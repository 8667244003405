<template>
    <div class="visited-bar">
        <div class="tabs-time">
            <div class="tab-time" :class="{'active': timeType == 1}" @click="onTimeData(1)">天</div>
            <div class="tab-time" :class="{'active': timeType == 2}" @click="onTimeData(2)">月</div>
        </div>
        <div class="e-chart-bar">
            <v-chart class="chart" :option="options"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        voiceChartType: {
            type: String,
            default () {
                return 'bar'
            }
        },
        voiceTimeData: {
            type: Object,
            default () {
                return null
            }
            
        },
        limitMap: {
            type: Object,
            default () {
                return null
            }
        }
    },
    watch: {
        voiceTimeData: {
            // deep: true,
            handler (val) {
                if (val) {
                    if (this.timeType == 1) {
                        this.data = val.noiceDayData.rows
                    } else {
                        this.data = val.noiceMonthData.rows
                    }
                }
            }
        },
        limitMap: {
            // deep: true,
            handler (val) {
                if (val) {
                    if (this.timeType == 1) {
                        this.limitData = val.noiceHighLimit
                    } else {
                        this.limitData = val.noiceHighLimit
                    }
                }
            }
        }
    },
    computed: {
        options () {
            return {
                title:{
                    text: 'db',
                    textStyle: {
                        fontSize: 16,
                        fontFamily: 'SourceHanSansCN-Regular, SourceHanSansCN',
                        fontWeight: 400,
                        color: '#FFFFFF',
                    },
                    left: '20px',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line',
                        label: {
                            show: true
                        },
                        lineStyle: {
                            width: 1,
                            type: 'solid',
                        },
                    },
                    formatter(params) {
                        const item1 = params[0];
                        return `
                            <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                            <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data} db<p>
                        `
                    }
                },
                // visualMap: {
                //     show: false,
                //     dimension: 1,
                //     pieces: [{gt: 0, lte: this.limitData, color: '#40D5FF'},{gt: this.limitData, color: this.voiceChartType == 'bar' ? '#F15050' : '#40D5FF'}],
                //     outOfRange: {
                //         color:"#40D5FF",
                //     },
                // },
                visualMap: {
                    show: false,
                    dimension: 1,
                    pieces: [
                        {
                            min: -1,
                            max: this.limitData,
                            label: -1,
                        }
                    ],
                    inRange: {
                        color: '#40D5FF',
                    },
                    outOfRange: {
                        color: this.voiceChartType == 'bar' ? '#F15050' : '#40D5FF',
                    },
                },
                grid: {
                    show: false,
                    top: 56,
                    // width: '100%',
                    // x: 0,
                    height: '77%',
                    left: '4%',
                    right: '80px',
                },
                xAxis: {
                    type: 'category',
                    data: this.data.map(item => {
                        let a = Object.keys(item)
                        return item[a[1]]
                    }),
                    name: '时间',
                    nameTextStyle: {
                        fontSize: 16,
                        color: '#FFFFFF',
                    },
                    splitArea: false,
                    axisLabel: {
                        show: true,
                        margin: 10,// 与刻度线间距
                        textStyle: {
                            color: '#C7C7C7',  //更改坐标轴文字颜色
                            fontSize : 16     //更改坐标轴文字大小
                        }
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false
                    },
                    // boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    show: true,
                    // scale : true,
                    // max: 100,
                    min: 0,
                    splitNumber: 4,
                    boundaryGap : [ 0.2, 0.2 ],
                    nameTextStyle: {
                        fontSize: 16,
                        padding: [0, 0, 24, 0]
                    },
                    axisLine: {
                        show: true,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        margin: 10,// 与刻度线间距
                        textStyle: {
                            color: '#C7C7C7',  //更改坐标轴文字颜色
                            fontSize : 16     //更改坐标轴文字大小
                        }
                    },

                },
                series: [{
                    name: `噪声`,
                    data: this.data.map(item => {
                        let a = Object.keys(item)
                        return item[a[0]]
                    }),
                    type: this.voiceChartType,
                    itemStyle: {
                        color: '#40D5FF',
                    },
                    markLine : {   //添加警戒线
                        symbol:"none",               //去掉警戒线最后面的箭头
                        name:"预警线88",
                        silent:true,
                        label:{
                            position: "end",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                            formatter: this.limitData ? `预警线${this.limitData}` : '',
                            color: "#F15050",
                            fontSize: 16
                        },
                        data : [{
                            silent:true,             //鼠标悬停事件  true没有，false有
                            lineStyle:{               //警戒线的样式  ，虚实  颜色
                                type: "dashed",
                                color: this.limitData ? "#FF3939" : '',
                            },
                            name: '警戒线',
                            yAxis: this.limitData
                        }]
                    }
                }]
            }
        }
    },
    data () {
        return {
            timeType: 1,
            data: [],
            limitData: ''
        }
    },
    methods: {
        onTimeData (timeType) {
            this.timeType = timeType
            if (this.timeType == 1) {
                this.data = this.voiceTimeData.noiceDayData.rows
                this.limitData = this.limitMap.noiceHighLimit
            } else {
                this.data = this.voiceTimeData.noiceMonthData.rows
                this.limitData = this.limitMap.noiceHighLimit
            }
        },
    }
}
</script>

<style lang="less" scoped>
.visited-bar {
    margin-top: 30px;
    margin-left: 26px;
    .tabs-pm {
        display: flex;
        float: left;
        .tab-pm {
            width: 55px;
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #ffffff;
            line-height: 27px;
            margin-right: 40px;
            cursor: pointer;
            &.active {
                color: #E67F02;
            }
        }
    }
    .tabs-time {
        display: flex;
        float: right;
        margin-right: 37px;
        .tab-time {
            width: 52px;
            height: 26px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            line-height: 26px;
            &.active {
                color: #333333;
                background: linear-gradient(180deg, #409CFE 0%, #57D5C7 100%);
            }
        }
    }
    .e-chart-bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 382px;
        padding-top: 13px;
    }
}
</style>