<template>
    <div class="datetime">
        <div class="time">{{time}}</div>
        <div class="date">{{date}}</div>
    </div>
</template>

<script>

function covert(dt) {
    let h= dt.getHours()
    let i= dt.getMinutes()
    let s= dt.getSeconds()
    return `${h < 10 ? '0' + h : h}:${i < 10 ? '0' + i : i}:${s < 10 ? '0' + s : s}`
}

function covertYear(dt) {
    let y= dt.getFullYear()
    let m= dt.getMonth()
    let d= dt.getDate()
    return `${y}/${(m + 1) < 10 ? '0' + (m + 1) : (m + 1)}/${d < 10 ? '0' + d : d}`
}

export default {
    created () {
        this.setTime()
    },
    data () {
        const now = new Date()
        return {
            date: covertYear(now),
            time: covert(now)
        }
    },
    methods: {
        setTime() {
            const now = new Date()
            this.time = covert(now)
            this.date = covertYear(now)
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.setTime()
            }, 1000)
        }
    },
    beforeDestroy () {
        clearTimeout(this.timer)
    }
}
</script>

<style lang="less" scoped>
.datetime {
    width: 106px;
    text-align: center;
    .time {
        font-size: 26px;
        font-family: Bebas;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-bottom: 8px;
    }
    .date {
        font-size: 14px;
        font-family: Bebas;
        color: #40D5FF;
        letter-spacing: 1px;
    }
}
</style>