<template>
  <div class="container inspect">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">实时监测数据详情</div>
      <div class="spray-equip">
        <spray-equip />
      </div>
    </div>
    <div class="inspect-info">
      <div class="inspect-info-item left">
        <div class="env-left">
          <env-item-left
            :icon="icon"
            :value="temp"
            :threshold="tempHighLevel"
            unit="℃"
            title="温度"
          ></env-item-left>
        </div>
        <div class="env-left">
          <env-item-left
            :icon="icon4"
            :value="wind"
            :threshold="windHighLimit"
            unit="级"
            title="风速"
          ></env-item-left>
        </div>
        <div class="env-left">
          <env-item-left
            :icon="icon3"
            :value="noice"
            :threshold="noiceHighLimit"
            unit="分贝"
            title="噪音"
          ></env-item-left>
        </div>
        <div class="env-left">
          <env-item-left
            :icon="icon7"
            :value="tsp"
            :threshold="tspHighLimit"
            unit="μg/m³"
            title="TSP"
          ></env-item-left>
        </div>
      </div>
      <div class="inspect-info-quality">
        <inspect-quality :currenApi="currenApi" />
      </div>
      <div class="inspect-info-item right">
        <div class="env-right">
          <env-item-right
            :icon="icon2"
            :value="wet"
            :threshold="wetHighLimit"
            unit="%"
            title="湿度"
          ></env-item-right>
        </div>
        <div class="env-right">
          <env-item-right
            :icon="icon5"
            :value="direction"
            :text="direction"
            :threshold="directionThreshold"
            title="风向"
            font-size="22px"
          ></env-item-right>
        </div>
        <div class="env-right">
          <env-item-right
            :icon="icon6"
            :value="pm2point5"
            :threshold="pm2point5HighLimit"
            unit="μg/m³"
            title="PM 2.5"
          ></env-item-right>
        </div>
        <div class="env-right">
          <env-item-right
            :icon="icon8"
            :value="pm10"
            :threshold="pm10HighLimit"
            unit="μg/m³"
            title="PM 10"
          ></env-item-right>
        </div>
      </div>
    </div>
    <div class="inspect-tip">
      <inspect-tip />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchGetEnvironmentAQI } from "@/api"
import EnvItemLeft from "./env-item-left"
import EnvItemRight from "./env-item-right"
import InspectTip from "./inspect-tip"
import InspectQuality from "./inspect-quality"
import SprayEquip from "../../env-inspect/env/spray-equip"
export default {
  components: {
    EnvItemLeft,
    EnvItemRight,
    InspectTip,
    InspectQuality,
    SprayEquip,
  },
  props: {
    mainInfo: {
      type: Object,
      default() {
        return []
      },
    },
    limitMap: {
      type: Object,
      default() {
        return []
      },
    },
    serialId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      icon: require("../../../../../assets/image/env/icon_temp.png"),
      icon2: require("../../../../../assets/image/env/icon_humidity.png"),
      icon3: require("../../../../../assets/image/env/icon_noise.png"),
      icon4: require("../../../../../assets/image/env/icon_wind.png"),
      icon5: require("../../../../../assets/image/env/icon_direction.png"),
      icon6: require("../../../../../assets/image/env/icon_pm2.5.png"),
      icon7: require("../../../../../assets/image/env/icon_pm10.png"),
      icon8: require("../../../../../assets/image/env/icon_tsp.png"),
      noice: 0,
      noiceHighLimit: 0,
      pm2point5: 0,
      pm2point5HighLimit: 0,
      pm10: 0,
      pm10HighLimit: 0,
      temp: 0,
      tempHighLevel: 0,
      tsp: 0,
      tspHighLimit: 0,
      wet: 0,
      wetHighLimit: 0,
      wind: 0,
      windHighLimit: 0,
      direction: "暂无",
      directionThreshold: 0,
      equip: "",
      id: "",
      currenApi: 0,
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    mainInfo(val) {
      if (val) {
        Object.assign(this._data, val)
      }
    },
    limitMap(val) {
      if (val) {
        Object.assign(this._data, val)
      }
    },
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            fetchGetEnvironmentAQI(val, this.serialId).then(({ data }) => {
              this.currenApi = data.aqi
            })
          })
        }
      },
    },
    serialId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            fetchGetEnvironmentAQI(this.projectId, val).then(({ data }) => {
              this.currenApi = data.aqi
            })
          })
        }
      },
    },
  },
  methods: {
    currentEquipId(id) {
      this.equipId = id
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.inspect {
  height: 630px;
  position: relative;
  .spray-equip {
    display: flex;
    position: absolute;
    top: 19px;
    left: 254px;
    background: #073348;
    border-radius: 2px;
  }
  &-info {
    display: flex;
    &-item {
      .env-left {
        width: 220px;
        height: 98px;
        margin-bottom: 8px;
        background: linear-gradient(270deg, rgba(7, 51, 72, 0) 0%, #0a4866 100%);
      }
      .env-right {
        width: 220px;
        height: 98px;
        margin-bottom: 8px;
        background: linear-gradient(90deg, rgba(7, 51, 72, 0) 0%, #0a4866 100%);
      }
    }
    .left {
      padding: 37px 0px 0px 24px;
    }
    .right {
      padding: 37px 24px 0px 0px;
    }
    &-quality {
      width: 488px;
      height: 489px;
    }
  }
  &-tip {
    width: 976px;
    height: 62px;
  }
}
</style>
