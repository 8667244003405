<template>
  <div
    v-if="showFullScreenBtn"
    class="fullscreen-btn-con"
  >
    <img
      v-if="!value"
      @click="handleChange"
      src="../../../assets/image/fullscreen.png"
    />
    <img
      v-else
      @click="handleChange"
      src="../../../assets/image/leavefullscreen.png"
    />
    <div>{{ value ? "退出全屏" : "全屏" }}</div>
  </div>
</template>

<script>
export default {
  name: "Fullscreen",
  props: {
    disabled: Function,
  },
  computed: {
    showFullScreenBtn() {
      return window.navigator.userAgent.indexOf("MSIE") < 0
    },
  },
  data() {
    return {
      value: false,
    }
  },
  methods: {
    handleFullscreen() {
      let main = document.body
      if (this.value) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (main.requestFullscreen) {
          main.requestFullscreen()
        } else if (main.mozRequestFullScreen) {
          main.mozRequestFullScreen()
        } else if (main.webkitRequestFullScreen) {
          main.webkitRequestFullScreen()
        } else if (main.msRequestFullscreen) {
          main.msRequestFullscreen()
        }
      }
    },
    async handleChange() {
      if (this.disabled) {
        const result = await this.disabled()
        if (result) {
          return
        }
      }
      this.handleFullscreen()
      this.value = !this.value
    },
  },
  mounted() {
    let isFullscreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
    isFullscreen = !!isFullscreen
    this.value = isFullscreen
  },
}
</script>

<style lang="less">
.fullscreen-btn-con {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
    margin-bottom: 16px;
  }
  div {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
