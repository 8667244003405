<template>
    <div class="item">
        <div class="item__mark" :style="{background: color}"></div>
        <div class="item__label">{{label}}：</div>
        <div class="item__value">
            <marquee-text v-if="!isLoading" :text="value"></marquee-text>
            <span class="placeholder-value" v-else>{{value}}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarqueeText from '../../marquee-text'
export default {
    // inject: ['isLoading'],
    components: {
        MarqueeText
    },
    props: {
        color: {
            type: String,
            default: 'rgba(64, 155, 255, 1)'
        },
        label: {
            type: String,
            default: '名称'
        },
        value: {
            type: String,
            default: '值'
        }
    },
    data () {
        return {
            isLoading: false
        }
    }
}
</script>

<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 16px;
    &__mark {
        flex: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
    }
    &__label {
        flex: none;
    }
    &__value {
        flex: 1;
        padding-right: 10px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
    }
}
</style>