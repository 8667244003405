<template>
  <div class="container attendance-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">出勤人数趋势</div>
      <div class="selector">
        <data-select
          v-model="dataTime"
          @currentType="currentType"
        />
      </div>
    </div>
    <div class="attendance-info-line">
      <!-- <v-chart class="chart" :option="option"/> -->
      <div ref="charts"></div>
    </div>
  </div>
</template>

<script>
import DataSelect from "./data-select"
import { fetchAttendanceTrend } from "@/api"
import { mapGetters } from "vuex"
import charts from "@/mixins/charts"
export default {
  mixins: [charts],
  components: {
    DataSelect,
  },
  data() {
    return {
      dataType: "7",
      data: [],
      dataTime: "",
      id: "",
      myChart: null,
    }
  },
  methods: {
    currentType(type) {
      this.dataType = type
      fetchAttendanceTrend(this.id, type).then(({ data }) => {
        this.data = data.columnData
      })
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            this.id = val
            fetchAttendanceTrend(val, this.dataType).then(({ data }) => {
              this.data = data.columnData
            })
          })
        }
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  computed: {
    ...mapGetters(["projectId"]),
    option() {
      let barStyle = {
        barWidth: 8,
        barGap: 0,
        barCategoryGap: 28,
        clip: false,
      }
      return {
        title: {
          text: "数量",
          textStyle: {
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFFFFF",
          },
          left: "0px",
        },
        dataset: {
          source: [["日期", "劳务工人", "项目人员"], ...this.data],
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              width: "20",
            },
          },
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          formatter(params) {
            if (params.length > 1) {
              const item1 = params[0] //劳务工人
              const item2 = params[1] //项目人员
              return `
                                <p style="margin-bottom: 10px">${item2.axisValueLabel}<p>
                                <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[1]}<p>
                                <p>${item2.marker}${item2.seriesName} ${item2.data[2]}<p>
                            `
            } else if ((params.length = 1)) {
              const item1 = params[0] //劳务工人
              if (params[0].seriesName == "劳务工人") {
                return `
                                    <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                                    <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[1]}<p>
                                `
              } else {
                return `
                                    <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                                    <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[2]}<p>
                                `
              }
            } else {
              return {
                axisPointer: {
                  type: "none",
                },
              }
            }
          },
        },
        legend: {
          right: "5px",
          top: "-5px",
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          textStyle: {
            fontSize: 10,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFF",
          },
          data: [{ name: "劳务工人" }, { name: "项目人员" }],
        },
        xAxis: {
          type: "category",
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        grid: {
          top: "37.5px",
          bottom: "1",
          left: "7",
          right: "17px",
          containLabel: true, // grid 区域是否包含坐标轴的刻度标签。(如果true的时候，上下左右可以为0了)
        },
        // grid: {
        //     show: false,
        //     top: 37.5,
        //     // width: '100%',
        //     x: 30,
        //     x2: 17,
        //     height: '76.5%'
        // },
        series: [
          {
            name: "劳务工人",
            type: "bar",
            itemStyle: {
              color: "rgba(0, 199, 255, 1)",
            },
            ...barStyle,
            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(116, 236, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(116, 236, 255, 0.0)",
                  },
                ],
              },
            },
          },
          {
            name: "项目人员",
            type: "bar",
            itemStyle: {
              color: "rgba(255, 165, 0, 1)",
            },
            ...barStyle,
            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255, 165, 0, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 165, 0, 0)",
                  },
                ],
              },
            },
          },
        ],
      }
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.attendance-info {
  height: 339px;
  width: 622px;
  position: relative;
  &-line {
    width: 586px;
    height: 244px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .selector {
    position: absolute;
    top: 17px;
    right: 16px;
  }
  .empty {
    position: absolute;
    left: 0;
    right: 0;
    top: 74px;
    bottom: 0;
    line-height: 220px;
  }
}
</style>
