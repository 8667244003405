<template>
  <div class="container worker-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">工种分布</div>
    </div>
    <div class="worker-info-distribute">
      <div class="worker-info-distribute-age">
        <!-- <v-chart
          v-if="!isEmpty"
          class="chart"
          :option="option"
        /> -->
        <div
          ref="charts"
          v-if="!isEmpty"
        ></div>
        <div
          v-else
          class="notData"
        >
          <img src="../../../../../assets/image/emptyRound.png" />
          <div class="notData-word">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchWorkType } from "@/api"
import { mapGetters } from "vuex"
import Charts from "@/mixins/charts.js"
export default {
  mixins: [Charts],
  data() {
    return {
      eg: 0,
      data: [],
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchWorkType(val).then(({ data }) => {
              this.data = data.sort(this.compare("value"))
              this.initCharts()
            })
          })
        }
      },
    },
  },
  computed: {
    ...mapGetters(["projectId"]),
    isEmpty() {
      return this.option.series[0].data.length === 0
    },
    option() {
      return {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Medium, SourceHanSansCN",
            fontWeight: 500,
          },
          formatter(params) {
            let item1 = params
            return `
                            <p style="margin-bottom: 10px;">${item1.seriesName}<p>
                            <p>${item1.data.name} : ${item1.data.value} (${item1.percent}%)<p>
                        `
          },
        },
        avoidLabelOverlap: true,
        series: [
          {
            name: "工种分布",
            type: "pie",
            radius: ["39%", "55%"],
            avoidLabelOverlap: false, //防止标签重合
            startAngle: "100", //旋转，起始角度
            minAngle: "10", //最小角度
            label: {
              color: "#FFFFFF",
              fontSize: "12px",
              fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
              fontWeight: "400",
              show: true,
              formatter: "{b}{c}人\n {d}%",
            },
            labelLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
              length: 10,
              length2: 15,
            },
            data: this.data,
            itemStyle: {
              color: function (params) {
                let colors = ["#40D5FF", "#169BC1", "#076985"]
                return colors[params.dataIndex % 3]
              },
            },
            visualMap: {
              show: false,
              min: 80,
              max: 600,
              inRange: {
                colorLightness: [0, 1],
              },
            },
          },
        ],
      }
    },
  },
  methods: {
    compare(num) {
      return (obj1, obj2) => {
        let a = obj1[num]
        let b = obj2[num]
        return a - b
      }
    },
  },
  mounted() {
    // let search = window.location.search || ''
    // let query = {}
    // if (search) {
    //     search = search.slice(1).split('&')
    //     search.forEach(s => {
    //         const [k, v] = s.split('=')
    //         query[k] = v
    //     })
    // }
    // if (query.companyId) {
    //     this.request(() => {
    //         fetchHouseholdRegister(query.companyId).then(({data}) => {
    //             if (data.workerNums) {
    //                 this.eg = data.workerNums
    //                 this.data = data.cityProportion.sort(this.compare("value"))
    //                 this.isShow = true
    //             }
    //             else {
    //                 this.isShow = false
    //             }
    //         })
    //     })
    // }
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.worker-info {
  height: 339px;
  width: 348px;
  margin-right: 16px;
  position: relative;
  &-distribute {
    margin: 5px 8px 12px 8px;
    &-age {
      width: 332px;
      height: 266px;
      margin: 0px px;
    }
  }
  .notData {
    width: 72px;
    height: 150px;
    position: absolute;
    top: 141px;
    left: 50%;
    margin-left: -36px;
    &-word {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      height: 16px;
      text-align: center;
      margin-top: 18px;
    }
    img {
      width: 72px;
      height: 72px;
    }
  }
}
</style>
