<template>
  <div class="container pm10">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">PM10</div>
      <div class="selector">
        <time-select @currentValue="currentValue" />
      </div>
    </div>
    <div class="pm10-info">
      <env-charts
        :pmData="pmData"
        :pmLimitData="pmLimitData"
        envName="PM10"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import TimeSelect from "../time-select"
import EnvCharts from "../env-charts"
export default {
  components: {
    TimeSelect,
    EnvCharts,
  },
  props: {
    pmTimeData: {
      type: Object,
      default() {
        return null
      },
    },
    limitMap: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      id: "1",
      pmData: [],
      pmLimitData: 0,
      pm10Data: {},
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    limitMap(val) {
      if (val) {
        this.pmLimitData = val.pm10HighLimit
      } else {
        this.pmLimitData = ""
      }
    },
    pmTimeData(val) {
      if (val) {
        this.pm10Data = val
        if (this.id == "1") {
          this.pmData = val.pm10DayData.rows
        } else {
          this.pmData = val.pm10MonthData.rows
        }
      }
    },
  },
  methods: {
    currentValue(id) {
      this.id = id
      if (this.id == "1") {
        this.pmData = this.pm10Data.pm10DayData.rows
      } else {
        this.pmData = this.pm10Data.pm10MonthData.rows
      }
    },
  },
}
</script>

<style lang="less" scoped>
.pm10 {
  height: 307px;
  position: relative;
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }
  &-info {
    width: 424px;
    height: 228px;
    margin: 7px 16px 16px 16px;
  }
}
</style>
