<template>
  <div class="container data-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title title">
        <div class="title-data">{{ name }}</div>
        实时数据
      </div>
      <div
        class="dialogImg"
        @click="openTowerList"
      >
        <img src="../../../../../assets/image/crane/dialog_record.png" />
      </div>
    </div>
    <div class="data-info-content">
      <div class="data-info-content-gird">
        <item
          name="回转角度"
          :value="Number(rotationAngle)"
          unit="°"
          :unitTop="unitTop"
        />
        <item
          name="当前幅度"
          :value="Number(margin)"
          unit="m"
        />
        <item
          name="吊钩高度"
          :value="Number(height)"
          unit="m"
        />
        <item
          name="当前风速"
          :value="Number(windSpeed)"
          unit="m/s"
        />
        <item
          name="当前吊重"
          :value="Number(loadWeight)"
          unit="kg"
        />
        <item
          name="力矩比例"
          :value="Number(torque)"
          unit="%"
        />
        <item
          name="塔机方向"
          :value="Number(inclination)"
          unit="°"
          :unitTop="unitTop"
        />
        <item
          name="吊绳倍率"
          :value="Number(currentMagnification)"
        />
      </div>
      <div class="v-gaps12"></div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :modal-append-to-body="false"
      width="61.3%"
      @close="refreshData"
    >
      <data-display
        :visible="visible"
        :tableData="tableData"
        :listHeader="header"
        :total="total"
        @handleSizePage="handleSizePage"
        @handleCurrentPage="handleCurrentPage"
      />
    </el-dialog>
  </div>
</template>

<script>
import DataDisplay from "../../display"
import Item from "./item"
import { fetchTowerRecord, fetchTowerList } from "@/api"
import { mapGetters } from "vuex"
export default {
  components: {
    DataDisplay,
    Item,
  },
  data() {
    return {
      id: "",
      headId: "",
      windSpeed: "0", //当前风速
      loadWeight: "0", //当前吊重
      torque: "0", //力矩
      rotationAngle: "0", //回转角度
      currentMagnification: "0", //吊绳倍率
      margin: "0", //当前幅度
      height: "0", //吊钩高度
      inclination: "0", //塔机方向
      unitTop: true,
      name: "",
      title: "",
      visible: false,
      tableData: [],
      header: [
        {
          label: "时间",
          width: 160,
        },
        {
          label: "回转角度（°）",
          width: 90,
        },
        {
          label: "当前幅度（m）",
          width: 97,
        },
        {
          label: "吊钩高度（m）",
          width: 97,
        },
        {
          label: "当前风速（m/s）",
          width: 110,
        },
        {
          label: "当前吊重（kg）",
          width: 90,
        },
        {
          label: "力矩比例（%）",
          width: 97,
        },
        {
          label: "塔机方向（°）",
          width: 90,
        },
        {
          label: "吊绳倍率（倍）",
          width: 98,
        },
      ],
      total: 0,
      pageNum: 1,
      pageSize: 25,
    }
  },
  computed: mapGetters(["currenEquip"]),
  watch: {
    currenEquip: {
      immediate: true,
      handler(val) {
        if (val) {
          this.name = val.name
          this.request(() => {
            return fetchTowerRecord(val.id).then(({ data }) => {
              if (data) {
                Object.assign(this._data, data)
              } else {
                this.rotationAngle = 0
                this.margin = 0
                this.height = 0
                this.windSpeed = 0
                this.loadWeight = 0
                this.torque = 0
                this.inclination = 0
                this.currentMagnification = 0
              }
            })
          })
        }
      },
    },
  },
  methods: {
    handleSizePage(val) {
      this.pageSize = val
      this.tableData = []
      this.getTowerList()
    },
    handleCurrentPage(val) {
      this.pageNum = val
      this.tableData = []
      this.getTowerList()
    },
    getTowerList() {
      fetchTowerList(this.currenEquip.id, this.pageNum, this.pageSize).then(({ data }) => {
        this.towerList(data.page.list)
        this.total = data.page.total
      })
    },
    openTowerList() {
      if (this.currenEquip.name) {
        this.title = this.currenEquip.name + "实时数据"
      } else {
        this.title = "实时数据"
      }
      this.getTowerList()
      this.visible = true
    },
    refreshData() {
      this.tableData = []
    },
    towerList(list) {
      list.forEach((item) => {
        this.tableData.push({
          column: [
            {
              value: item.createTime,
              width: 160,
            },
            {
              value: item.rotationAngle,
              width: 90,
            },
            {
              value: item.margin,
              width: 97,
            },
            {
              value: item.height,
              width: 97,
            },
            {
              value: item.windSpeed,
              width: 110,
            },
            {
              value: item.loadWeight,
              width: 90,
            },
            {
              value: item.torque,
              width: 97,
            },
            {
              value: item.inclination,
              width: 90,
            },
            {
              value: item.currentMagnification,
              width: 98,
            },
          ],
        })
      })
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.data-info {
  height: 344px;
  position: relative;
  /deep/ .el-dialog__body {
    padding: 0px 0px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  /deep/ .el-dialog__header {
    padding: 24px 16px 24px 16px;
    background: #062939;
  }
  /deep/ .el-dialog__title {
    line-height: 10px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
  }
  /deep/ .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: none;
    border-radius: 2px;
    box-shadow: none;
    box-sizing: border-box;
    width: 60%;
    border-top: 3px solid transparent;
    border-color: transparent;
    border-color: rgba(64, 213, 255, 0.3);
  }
  .dialogImg {
    position: absolute;
    top: 21px;
    right: 24px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &-content {
    padding: 0px 16px;
    &-gird {
      margin-top: 18px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .title {
    display: flex;
    &-data {
      max-width: 780px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
