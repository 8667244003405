<template>
  <div class="epidemic-detail">
    <div>
      <ul class="table hd">
        <li class="table-row">
          <div class="table-row__cell time">测温时间</div>
          <div class="table-row__cell name">姓名</div>
          <div class="table-row__cell type">工种类型/项目角色</div>
          <div class="table-row__cell temp">今日体温</div>
          <div class="table-row__cell test">核酸检测情况</div>
          <div class="table-row__cell status">疫苗接种情况</div>
          <div class="table-row__cell health">健康码</div>
          <div class="table-row__cell routing">14天内行程轨迹</div>
        </li>
      </ul>
      <vue-seamless-scroll
        class="warninglist"
        v-if="list && list.length > 11"
        :data="list"
        :class-option="classOption"
      >
        <ul
          v-if="list && list.length"
          class="table"
        >
          <li
            class="table-row"
            v-for="(item, index) in list"
            :key="'machine' + index"
          >
            <div class="table-row__cell time">
              <div>{{ item.temperatureTime }}</div>
            </div>
            <div class="table-row__cell name">{{ item.fullname }}</div>
            <div class="table-row__cell type">{{ item.workType }}</div>
            <div
              class="table-row__cell temp"
              :class="{ 'table-row__cell temp--warning': item.temperature >= 37.3 }"
            >
              {{ item.temperature }}℃
            </div>
            <div class="table-row__cell test">{{ item.nuclein }}</div>
            <div
              class="table-row__cell status"
              :class="{ 'table-row__cell temp--warning': item.vaccine == '未接种' }"
            >
              {{ item.vaccine }}
            </div>
            <div class="table-row__cell health">
              <img
                v-show="item.qrcode == 0"
                src="../../../../../assets/image/epidemic/green_icon.png"
              />
              <img
                v-show="item.qrcode == 1"
                src="../../../../../assets/image/epidemic/yellow_icon.png"
              />
              <img
                v-show="item.qrcode == 2"
                src="../../../../../assets/image/epidemic/red_icon.png"
              />
            </div>
            <div class="table-row__cell routing">
              <marquee-text
                v-if="!isLoading"
                :text="item.route"
              ></marquee-text>
              <span
                class="placeholder-value"
                v-else
                >{{ item.route }}</span
              >
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
      <ul
        v-else-if="list && list.length > 0 && list.length < 12"
        class="table"
      >
        <li
          class="table-row"
          v-for="(item, index) in list"
          :key="'machine' + index"
        >
          <div class="table-row__cell time">
            <div>{{ item.temperatureTime }}</div>
          </div>
          <div class="table-row__cell name">{{ item.fullname }}</div>
          <div class="table-row__cell type">{{ item.workType }}</div>
          <div
            class="table-row__cell temp"
            :class="{ 'table-row__cell temp--warning': item.temperature >= 37.3 }"
          >
            {{ item.temperature }}℃
          </div>
          <div class="table-row__cell test">{{ item.nuclein }}</div>
          <div
            class="table-row__cell status"
            :class="{ 'table-row__cell temp--warning': item.vaccine == '未接种' }"
          >
            {{ item.vaccine }}
          </div>
          <div class="table-row__cell health">
            <img
              v-show="item.qrcode == 0"
              src="../../../../../assets/image/epidemic/green_icon.png"
            />
            <img
              v-show="item.qrcode == 1"
              src="../../../../../assets/image/epidemic/yellow_icon.png"
            />
            <img
              v-show="item.qrcode == 2"
              src="../../../../../assets/image/epidemic/red_icon.png"
            />
          </div>
          <div class="table-row__cell routing">
            <marquee-text
              v-if="!isLoading"
              :text="item.route"
            ></marquee-text>
            <span
              class="placeholder-value"
              v-else
              >{{ item.route }}</span
            >
          </div>
        </li>
      </ul>
      <div
        v-else
        class="empty"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchEpidemicList } from "@/api"
import MarqueeText from "../../marquee-text"
export default {
  components: {
    MarqueeText,
  },
  data() {
    return {
      list: [],
      classOption: {
        singleHeight: 54,
      },
      isLoading: false,
    }
  },
  props: {
    screenId: {
      type: String,
      default: "1",
    },
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          let queryType = 0
          this.request(() => {
            return fetchEpidemicList(val, queryType, 1, 1000).then(({ data }) => {
              this.list = data.page.list
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.warninglist {
  height: 648px;
  overflow: hidden;
}
.epidemic-detail {
  width: 1247px;
  height: 688px;
  background: #0c1828;
  opacity: 0.89;
  .table {
    .table-row {
      display: flex;
      height: 54px;
      align-items: center;
      font-size: 16px;
      color: #6bb4e7;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      // background: linear-gradient(316deg, rgba(48, 242, 255, 0.01) 0%, rgba(13, 157, 255, 0.5) 100%);
      &:nth-child(even) {
        background: #162335;
      }
      &__cell {
        white-space: nowrap;
        overflow: hidden;
        flex: none;
        box-sizing: border-box;
        text-align: center;
        padding-left: 14px;
        &.time {
          width: 160px;
          // display: flex;
          // align-items: center;
          padding-left: 14px;
        }
        &.name {
          width: 120px;
        }
        &.type {
          width: 165px;
        }
        &.temp {
          width: 119px;
          &--warning {
            color: #d0021b;
          }
        }
        &.test {
          width: 145px;
        }
        &.status {
          width: 145px;
        }
        &.health {
          width: 119px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        &.routing {
          width: 252px;
        }
      }
    }
    &.hd {
      .table-row {
        background: linear-gradient(316deg, rgba(48, 242, 255, 0.01) 0%, rgba(13, 157, 255, 0.5) 100%) !important;
        color: #fff !important;
        height: 40px !important;
        &__cell {
          &.team {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .empty {
    height: 648px;
    line-height: 648px;
  }
}
</style>
