<template>
  <div class="auth-error container">
    <div class="auth-error_content">
      <div class="img-box">
        <img class="img-top" src="@/assets/image/empty-top.png"/>
        <img class="img-bottom" src="@/assets/image/empty-bottom.png"/>
      </div>
      <div class="content-title">
        暂未开通网页端视频监控权限~
      </div>
      <div class="content-phone">
        联系电话：400 066 0388
      </div>
      <div class="hover">
        <div class="content-button">
          添加微信开通
        </div>
        <div class="content-QR">
          <img class="QR-BG" src="@/assets/image/QR-BG.png">
          <img class="QR" src="@/assets/image/QR.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "authError",
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.auth-error {
  width: 100%;
  height: 952px;
  display: flex;
  align-items: center;
  justify-content: center;

  &_content {
    width: 590px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -20px;

    .content-title {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #31E3FF;
      line-height: 24px;
      margin-top: 14px;
    }

    .content-phone {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FFFFFF;
      line-height: 28px;
      margin-top: 32px;
    }

    .content-button {
      width: 167px;
      height: 40px;
      background: linear-gradient(159deg, #02A3F3 0%, rgba(49, 227, 255, 0.57) 100%);
      border-radius: 36px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      font-family: PingFangSCRegular;
      color: #FFFFFF;
      line-height: 40px;
      margin-top: 14px;
      cursor: pointer;
    }

    .hover {
      position: relative;

      .content-QR {
        width: 200px;
        height: 200px;
        position: absolute;
        opacity: 0;
        top: 30px;
        left: 50px;

        .QR-BG {
          width: 100%;
          height: 100%;
          transform: rotate(90deg);
        }

        .QR {
          position: absolute;
          top: 32px;
          left: 26px;
          width: 147px;
          height: 147px;
          border-radius: 8px;
        }
      }
    }

    .hover:hover {
      .content-QR {
        opacity: 1;
      }
    }

  }

}

.img-box {
  position: relative;
  width: 244px;
  height: 218px;

  .img-top {
    position: relative;
    width: 224px;
    height: 158px;
    animation: imgBox 5s infinite;
    z-index: 2;
  }

  .img-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 224px;
    height: 108px;
    z-index: 1;
  }
}

@keyframes imgBox {
  0% {
    top: 0px;
  }
  25% {
    top: -7px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: -7px;
  }
  100% {
    top: 0px;
  }
}
</style>
