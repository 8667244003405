<template>
    <div style="width:100%; min-height: 1000px; overflow:hidden;">
        <iframe
            allowfullscreen="true"
            :src="link"
            frameborder="0"
            width="100%"
            height="968px"
        ></iframe>
    </div>
</template>
<script>
export default {
    props: {
        link: {
            type: String,
            default: 'https://xjz.constar-gd.com/#/login'
        }
    }
}
</script>

<style lang="less" scoped>
</style>