<template>
  <div class="counter">
    <div class="counter__title">{{ title }}</div>
    <div class="counter-content">
      <span
        class="counter-content__value"
        @click="openMemberList"
      >
        <count-to
          :start-val="0"
          :end-val="value"
          separator=""
        />
      </span>
      <span class="counter-content__unit">{{ unit }}</span>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :modal-append-to-body="false"
      width="52%"
      @close="refreshData"
    >
      <data-display
        :visible="visible"
        :tableData="tableData"
        :listHeader="tableHeader"
        :memberName="title"
        :total="total"
        @handleSizePage="handleSizePage"
        @handleCurrentPage="handleCurrentPage"
      />
    </el-dialog>
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
import DataDisplay from "../../display"
import { mapGetters } from "vuex"
import { fetchWorkList, fetchTeamList, fetchStaffList } from "@/api"
export default {
  components: {
    CountTo,
    DataDisplay,
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    value: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: "人",
    },
    tableHeader: {
      type: Array,
      default() {
        return []
      },
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 25,
    }
  },
  watch: {},
  mounted() {},
  computed: mapGetters(["projectId"]),
  methods: {
    handleSizePage(val) {
      this.pageSize = val
      this.tableData = []
      this.openMemberList()
    },
    handleCurrentPage(val) {
      this.pageNum = val
      this.tableData = []
      this.openMemberList()
    },
    openMemberList() {
      if (this.id == "1") {
        fetchWorkList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
          this.workList(data.page.list)
          this.total = data.page.total
        })
      } else if (this.id == "2") {
        fetchTeamList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
          this.staffList(data.page.list)
          this.total = data.page.total
        })
      } else if (this.id == "3") {
        fetchStaffList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
          this.workList(data.page.list)
          this.total = data.page.total
        })
      }
      this.visible = true
    },
    refreshData() {
      this.tableData = []
    },
    workList(list) {
      list.forEach((item) => {
        this.tableData.push({
          column: [
            {
              value: item.workerName,
              width: 100,
            },
            {
              value: item.phone,
              width: 120,
            },
            {
              value: item.type ? item.type : "项目人员",
              width: 120,
            },
            {
              value: item.teamName ? item.teamName : "-",
              width: 200,
            },
            {
              value: item.workType,
              width: 120,
            },
            {
              value: item.joinCompanyDate,
              width: 160,
            },
          ],
        })
      })
    },
    staffList(list) {
      list.forEach((item) => {
        this.tableData.push({
          column: [
            {
              value: item.teamName,
              width: 160,
            },
            {
              value: item.workerName,
              width: 100,
            },
            {
              value: item.phone,
              width: 130,
            },
            // {
            //     value: item.type,
            //     width: 100
            // },
            {
              value: item.workType,
              width: 130,
            },
            {
              value: item.num,
              width: 120,
            },
            {
              value: item.joinCompanyDate,
              width: 167,
            },
          ],
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0px 0px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
/deep/ .el-dialog__header {
  padding: 24px 16px 24px 16px;
  background: #062939;
}
/deep/ .el-dialog__title {
  line-height: 10px;
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
/deep/ .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: none;
  border-radius: 2px;
  box-shadow: none;
  box-sizing: border-box;
  width: 60%;
  border-top: 3px solid transparent;
  border-color: transparent;
  border-color: rgba(64, 213, 255, 0.3);
}
.counter {
  position: relative;
  flex: 1;
  padding: 25px 0 25px 48px;
  &__title {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 13px;
  }
  &-content {
    span {
      vertical-align: text-bottom;
    }
    &__value {
      font-size: 30px;
      font-family: Bebas;
      color: #40d5ff;
      letter-spacing: 1px;
      margin-right: 2px;
      cursor: pointer;
    }
    &__unit {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 24px;
    bottom: 24px;
    right: 0;
    width: 1px;
    background: rgba(64, 155, 255, 0.2);
  }
  &:last-child::after {
    display: none;
  }
}
</style>
