<template>
  <div class="epidemic">
    <div class="epidemic-warning">
      <div class="epidemic-warning-header">异常报警</div>
      <div class="epidemic-warning-content">
        <div>
          <ul class="table hd">
            <li class="table-row">
              <div class="table-row__cell time">测温时间</div>
              <div class="table-row__cell name">姓名</div>
              <div class="table-row__cell type">工种类型/项目角色</div>
              <div class="table-row__cell temp">今日体温</div>
              <div class="table-row__cell health">健康码</div>
            </li>
          </ul>
          <vue-seamless-scroll
            class="warninglist"
            v-if="list && list.length > 5"
            :data="list"
            :class-option="classOption"
          >
            <ul
              v-if="list && list.length"
              class="table"
            >
              <li
                class="table-row"
                v-for="(item, index) in list"
                :key="'machine' + index"
              >
                <div class="table-row__cell time">
                  <div>{{ item.temperatureTime }}</div>
                </div>
                <div class="table-row__cell name">{{ item.fullname }}</div>
                <div class="table-row__cell type">{{ item.workType }}</div>
                <div
                  class="table-row__cell temp"
                  :class="{ 'table-row__cell temp--warning': item.temperature >= 37.3 }"
                >
                  {{ item.temperature }}
                </div>
                <div class="table-row__cell health">
                  <img
                    v-show="item.qrcode == 0"
                    src="../../../../../assets/image/epidemic/green_icon.png"
                  />
                  <img
                    v-show="item.qrcode == 1"
                    src="../../../../../assets/image/epidemic/yellow_icon.png"
                  />
                  <img
                    v-show="item.qrcode == 2"
                    src="../../../../../assets/image/epidemic/red_icon.png"
                  />
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
          <ul
            v-else-if="list && list.length > 0 && list.length < 6"
            class="table"
          >
            <li
              class="table-row"
              v-for="(item, index) in list"
              :key="'machine' + index"
            >
              <div class="table-row__cell time">
                <div>{{ item.temperatureTime }}</div>
              </div>
              <div class="table-row__cell name">{{ item.fullname }}</div>
              <div class="table-row__cell type">{{ item.workType }}</div>
              <div
                class="table-row__cell temp"
                :class="{ 'table-row__cell temp--warning': item.temperature >= 37.3 }"
              >
                {{ item.temperature }}
              </div>
              <div class="table-row__cell health">
                <img
                  v-show="item.qrcode == 0"
                  src="../../../../../assets/image/epidemic/green_icon.png"
                />
                <img
                  v-show="item.qrcode == 1"
                  src="../../../../../assets/image/epidemic/yellow_icon.png"
                />
                <img
                  v-show="item.qrcode == 2"
                  src="../../../../../assets/image/epidemic/red_icon.png"
                />
              </div>
            </li>
          </ul>
          <div
            v-else
            class="empty"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <health-code></health-code>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchEpidemicList } from "@/api"
import HealthCode from "./health-code"
export default {
  components: {
    HealthCode,
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          let queryType = 1
          this.request(() => {
            return fetchEpidemicList(val, queryType, 1, 1000).then(({ data }) => {
              this.list = data.page.list
            })
          })
        }
      },
    },
  },
  data() {
    return {
      list: [],
      classOption: {
        singleHeight: 54,
      },
    }
  },
}
</script>

<style lang="less" scoped>
.warninglist {
  height: 270px;
  overflow: hidden;
}
.epidemic-warning {
  width: 623px;
  height: 389px;
  margin-left: 18px;
  background: #0c1828;
  opacity: 0.89;
  &-header {
    width: 290px;
    height: 40px;
    background: linear-gradient(300deg, rgba(13, 157, 255, 0) 0%, rgba(48, 242, 255, 0.68) 100%);
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    padding-left: 24px;
  }
  &-content {
    margin-top: 4px;
    .table {
      .table-row {
        display: flex;
        height: 54px;
        align-items: center;
        font-size: 16px;
        color: #6bb4e7;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        // background: linear-gradient(316deg, rgba(48, 242, 255, 0.01) 0%, rgba(13, 157, 255, 0.5) 100%);
        &:nth-child(even) {
          background: #162335;
        }
        &__cell {
          white-space: nowrap;
          overflow: hidden;
          flex: none;
          box-sizing: border-box;
          text-align: center;
          padding-left: 14px;
          &.time {
            width: 150px;
            // display: flex;
            // align-items: center;
            padding-left: 14px;
          }
          &.name {
            width: 110px;
          }
          &.type {
            width: 155px;
          }
          &.temp {
            width: 119px;
            &--warning {
              color: #d0021b;
            }
          }
          &.health {
            width: 80px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      &.hd {
        .table-row {
          background: linear-gradient(316deg, rgba(48, 242, 255, 0.01) 0%, rgba(13, 157, 255, 0.5) 100%) !important;
          color: #fff !important;
          height: 40px !important;
          &__cell {
            &.team {
              padding-left: 12px;
            }
          }
        }
      }
    }
    .empty {
      height: 270px;
      line-height: 270px;
    }
  }
}
</style>
