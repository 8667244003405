<template>
  <!-- <v-chart
    class="chart"
    :option="option"
  /> -->
  <div
    ref="charts"
    :style="{ height: '100%', width: '100%' }"
  ></div>
</template>

<script>
import * as echarts from "echarts"
export default {
  props: {
    pmData: {
      type: Array,
      default() {
        return []
      },
    },
    pmLimitData: {
      type: Number,
      default: 0,
    },
    envName: {
      type: String,
      default: "",
    },
  },
  watch: {
    pmData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.initCharts()
      },
    },
  },
  computed: {
    option() {
      return {
        title: {
          text: "μg/m³",
          textStyle: {
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFFFFF",
          },
          left: "0px",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#00C7FF",
              type: "solid",
            },
          },
          formatter(params) {
            const item1 = params[0] //一般问题
            return `
                            <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                            <p>${item1.marker}${item1.seriesName} ${item1.data} μg/m³<p>
                        `
          },
        },
        xAxis: {
          type: "category",
          data: this.pmData.map((item) => {
            let a = Object.keys(item)
            return item[a[1]]
          }),
          boundaryGap: false,
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
            // onZero: false,
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        // },
        grid: {
          show: false,
          top: 32,
          // width: '100%',
          x: 34,
          x2: 32,
          // bottom: 20,
          height: "74.6%",
        },
        series: {
          name: `${this.envName}`,
          data: this.pmData.map((item) => {
            let a = Object.keys(item)
            return item[a[0]]
          }),
          type: "line",
          itemStyle: {
            color: "#40D5FF",
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(116, 236, 255, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(116, 236, 255, 0.0)",
                },
              ],
            },
          },
          markLine: {
            symbol: "none", //去掉警戒线最后面的箭头
            silent: true,
            label: {
              position: "end", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
              formatter: this.pmLimitData ? `预\n警\n线\n${this.pmLimitData}` : "",
              color: "#FF0000",
              fontSize: 12,
              width: 100,
              lineHeight: 15,
            },
            data: [
              {
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: this.pmLimitData ? "#FF3939" : "",
                },
                yAxis: this.pmLimitData,
              },
            ],
          },
        },
      }
    },
  },
  data() {
    return {
      pmType: 1,
      type: 1,
      pmName: "PM2.5",
      data: [],
      limitData: 0,
    }
  },
  methods: {
    initCharts() {
      if (!this.myChart) {
        var chartDom = this.$refs.charts
        this.myChart = echarts.init(chartDom, null, {
          devicePixelRatio: window.devicePixelRatio || 1,
        })
      }
      this.myChart.setOption(this.option)
    },
    updateCharts() {
      this.myChart.setOption(this.option)
    },
  },
}
</script>

<style lang="less" scoped></style>
