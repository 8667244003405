<template>
  <div class="container vehicle-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">车辆进出汇总</div>
      <div class="selector">
        <time-select
          @currentType="currentType"
          :currentKey="currentKey"
        />
      </div>
    </div>
    <div class="vehicle-info-content">
      <div class="vehicle-info-content-total">
        <div class="vehicle-info-content-total-word">
          <div class="vehicle-info-content-total-word-center">
            <div class="vehicle-info-content-total-word-center-num">
              <count-to
                :start-val="0"
                :end-val="Number(totalCount)"
              />
            </div>
            <div class="vehicle-info-content-total-word-center-unit">辆</div>
          </div>
          <div class="vehicle-info-content-total-word-tip">累计进出场车辆</div>
        </div>
      </div>
      <div class="wrap">
        <div class="vehicle-info-content-radio">
          <div class="vehicle-info-content-radio-word">入场车辆：</div>
          <div class="vehicle-info-content-radio-num in">
            <count-to
              :start-val="0"
              :decimals="0"
              :end-val="Number(inCount)"
              separator=""
            />
          </div>
        </div>
        <div class="vehicle-info-content-radio">
          <div class="vehicle-info-content-radio-word out">出场车辆：</div>
          <div class="vehicle-info-content-radio-num">
            <count-to
              :start-val="0"
              :decimals="0"
              :end-val="outCount"
              separator=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeSelect from "./time-select"
import CountTo from "@/components/count-to.vue"
import { mapState } from "vuex"
export default {
  components: {
    TimeSelect,
    CountTo,
  },
  props: {},
  data() {
    return {
      inCount: 0,
      outCount: 0,
      totalCount: 0,
      currentKey: "allCount",
    }
  },
  watch: {
    countData(n, o) {
      if (n) {
        this.currentType()
      }
    },
  },
  computed: {
    ...mapState({
      countData: (state) => state.countData,
    }),
  },
  methods: {
    currentType(val) {
      if (val) this.currentKey = val
      if (this.currentKey && this.countData) {
        this.inCount = this.countData[this.currentKey].inCount ? this.countData[this.currentKey].inCount : 0
        this.outCount = this.countData[this.currentKey].outCount ? this.countData[this.currentKey].outCount : 0
        this.totalCount = this.countData[this.currentKey].totalCount ? this.countData[this.currentKey].totalCount : 0
      }
    },
  },
}
</script>

<style lang="less" scoped>
.vehicle-info {
  height: 468px;
  position: relative;
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }
  &-content {
    &-total {
      margin: 34px 127px 0px 126px;
      height: 275px;
      width: 275px;
      background-image: url("../../../../../assets/image/issue/round.gif");
      position: relative;
      &-word {
        width: 150px;
        height: 76px;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        &-center {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          &-num {
            font-size: 56px;
            font-family: Bebas;
            color: #40d5ff;
            padding-right: 2px;
          }
          &-unit {
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
            padding-bottom: 6px;
            padding-right: 2px;
          }
        }
        &-tip {
          font-size: 16px;
          margin-top: 8px;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }
    }
    .wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0px 91px 0px 86px;
    }
    &-radio {
      width: 100%;
      margin-top: 34px;
      padding: 0px 0px 26px 0px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      &-word {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        margin-right: 8px;
        padding-bottom: 3px;
        white-space: nowrap;
      }
      &-num {
        font-size: 34px;
        font-family: Bebas;
        color: #ffa500;
        letter-spacing: 1px;
      }
      .in {
        color: #40d5ff;
      }
      .out {
        margin-left: 19px;
      }
      &-unit {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        padding-bottom: 5px;
      }
    }
  }
}
</style>
