<template>
  <div class="container monitoring-info">
    <div class="screen">
      <div
        class="wrap"
        v-if="monitor"
      >
        <!-- <ezui ref="video" :url="url" :accessToken="accessToken" :height="height" :width="width"></ezui> -->
        <div :style="{ width: width + 'px', height: height + 'px' }">
          <hikvision-video
            :item="item"
            :token="token"
            :typeNum="1"
            :src="url"
            :tips="0"
            autoplay
            poster="@/assets/image/monitoring.gif"
          ></hikvision-video>
        </div>
      </div>
      <div
        class="empty"
        v-else
      >
        <img src="../../../../../assets/image/monitoring.gif" />
      </div>
    </div>
  </div>
</template>

<script>
// import Ezui from '../../../view/Ezui'
import { mapGetters } from "vuex"
import { fetchGetCameraUrl, getTokenCamera } from "@/api"

export default {
  // components: {
  //     Ezui
  // },
  data() {
    return {
      accessToken: "",
      url: "",
      monitor: false,
      width: 936,
      height: 592,
      item: {}, //视频参数
      token: "",
      // screenWidth: 1920,
      // screenHeight: 1080,
      // selectedList: [],//选中播放的设备
      // sysParams:{
      //     appkey:'',
      //     secret:'',
      //     ip: '',
      //     port: 443
      // },//setting
    }
  },
  computed: mapGetters(["currenEquip", "projectId"]),
  watch: {
    currenEquip(val) {
      this.item = val
      this.item.cameraIndexCode = val.relCameraIndexCode
      ///先把播放器释放掉，不然会造成问题
      this.monitor = false
      if (val.relCameraIndexCode) {
        let protocol = "https:" == document.location.protocol ? "hlss" : "hls"
        return fetchGetCameraUrl(val.platform, val.relCameraIndexCode, val.channelNo ? val.channelNo : "", protocol, val.quality, this.projectId).then(({ data }) => {
          if (data && data != null) {
            this.monitor = true
            this.url = data
            // this.$nextTick(() => {
            //     this.$refs['video'].open()
            // })
          } else {
            this.monitor = false
          }
        })
      } else {
        this.monitor = false
      }
    },
  },
  mounted() {
    this.getToken()
  },
  methods: {
    // 获取token
    getToken() {
      getTokenCamera().then((data) => {
        this.token = data.data.accessToken
      })
    },
  },
}
</script>

<style lang="less" scoped>
.monitoring-info {
  height: 592px;
  width: 936px;

  .screen {
    height: 592px;

    .empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;

      img {
        width: 580px;
        height: 396px;
      }
    }
  }
}
</style>
