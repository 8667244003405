<template>
  <div class="container warning">
    <div class="container__content">
      <div class="container__border"></div>
      <div
        class="container__title"
        v-if="moduleContent == 8"
      >
        劳务班组评分排名
      </div>
      <div
        class="container__title"
        v-else-if="moduleContent == 9"
      >
        今日车辆出入明细
      </div>
      <div
        class="container__title"
        v-else-if="moduleContent == 6"
      >
        安全警报
      </div>
    </div>
    <div
      style="margin-top: 6px"
      v-if="moduleContent == 8"
    >
      <rank-list />
    </div>
    <div
      style="margin-top: 6px"
      v-else-if="moduleContent == 9"
    >
      <vehicle-list />
    </div>
    <div
      style="margin-top: 6px"
      v-else-if="moduleContent == 6"
    >
      <ul class="table hd">
        <li class="table-row">
          <div class="table-row__cell team">报警设备</div>
          <div class="table-row__cell number">报警内容</div>
          <div class="table-row__cell score">报警时间</div>
        </li>
      </ul>
      <vue-seamless-scroll
        class="warninglist"
        v-if="list && list.length"
        :data="list"
        :class-option="classOption"
      >
        <ul
          v-if="list && list.length"
          class="table"
        >
          <li
            class="table-row"
            v-for="(item, index) in list"
            :key="'machine' + index"
          >
            <div class="table-row__cell team">
              <div>{{ item.machineName }}</div>
            </div>
            <div class="table-row__cell number">{{ item.contentTitle }}</div>
            <div class="table-row__cell score">{{ item.createTime }}</div>
          </li>
        </ul>
      </vue-seamless-scroll>
      <div
        v-else
        class="empty"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchSaftyAlarm, fetchModuleList } from "@/api"
import RankList from "./rank-list.vue"
import VehicleList from "./vehicle-list.vue"
export default {
  components: {
    RankList,
    VehicleList,
  },
  data() {
    return {
      list: [],
      moduleContent: 0,
      classOption: {
        singleHeight: 42,
      },
    }
  },
  props: {
    screenId: {
      type: String,
      default: "1",
    },
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchSaftyAlarm(val).then(({ data }) => {
              this.list = data
            })
          })
          this.request(() => {
            return fetchModuleList(val, this.screenId).then(({ data }) => {
              this.moduleContent = data[5].moduleContent
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.warninglist {
  height: 336px;
  overflow: hidden;
}
.warning {
  height: 468px;
  .table {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #40d5ff;
    padding: 0 16px;
    .table-row {
      display: flex;
      height: 42px;
      align-items: center;
      background: rgba(6, 41, 57, 1);
      &:nth-child(even) {
        background: rgba(7, 51, 72, 1);
      }
      &__cell {
        white-space: nowrap;
        overflow: hidden;
        flex: none;
        box-sizing: border-box;
        &.team {
          width: 95px;
          display: flex;
          align-items: center;
          padding-left: 12px;
          .rank {
            width: 20px;
            height: 20px;
            line-height: 20px;
            background: url("../../../../../assets/image/ranklist/rank.png");
            background-size: cover;
            background-position: center;
            font-size: 18px;
            font-family: Bebas;
            color: #ffffff;
            text-shadow: 1px 1px 0px #288eff;
            text-align: center;
            margin-right: 8px;
            span {
              display: inline-block;
              line-height: 1;
              transform: scale(0.5);
            }
          }
        }
        &.number {
          width: 132px;
          padding-left: 20px;
          text-align: left;
        }
        &.score {
          flex: 1;
          padding-left: 20px;
          text-align: left;
        }
      }
    }
    &.hd {
      .table-row {
        background: rgba(7, 51, 72, 1) !important;
        color: #fff;
        &__cell {
          &.team {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .empty {
    height: 359px;
    line-height: 359px;
  }
}
</style>
