<template>
  <div class="screen-wrapper">
    <!-- 不要删除，视频播放插件提示安装证书会用到 -->
    <div id="certBar">
      <!-- <cert/> -->
    </div>
    <fullscreen-container v-show="currentId != '1001'">
      <scree-head
        :is-scroll.sync="isScroll"
        @tabsChange="tabsChange"
        v-model="currentId"
      >
        <!-- 数据总揽 -->
        <div v-if="currentId == '2'">
          <div>
            <on-worker />
            <v-gaps :height="16" />
          </div>
        </div>
        <div
          class="flex"
          v-if="currentId == '2'"
        >
          <div class="flex-part">
            <div class="flex-part-itemA">
              <age-distribute />
              <v-gaps :height="16" />
              <attendance />
            </div>
            <div class="flex-part-itemB">
              <work-type />
              <v-gaps :height="16" />
              <safty-education />
              <v-gaps :height="16" />
              <rank-list />
            </div>
          </div>
          <check-work />
          <v-gaps :height="16" />
        </div>
        <!-- 问题整改 -->
        <improvement-issue
          v-else-if="currentId == '3'"
          :currentId="currentId"
        />
        <!-- 视频监控 -->
        <data-video v-else-if="currentId == '4'"></data-video>
        <!-- 起重机械 -->
        <crane-machine v-else-if="currentId == '5'" />
        <!-- 环境监测 -->
        <!-- <env-inspect v-if="currentId == '6'" :currentId="currentId"/> -->
        <env v-else-if="currentId == '6'" />
        <!-- 车辆管理 -->
        <vehicle-administration v-else-if="currentId == '7'" />
        <!-- 防疫大屏 -->
        <epidemic v-else-if="currentId == '8'"></epidemic>
        <data-overall
          v-else-if="currentId == '1'"
          :currentId="currentId"
        />
        <out-link v-show="currentId == '9'"></out-link>
        <!-- 人员定位 -->
        <out-link
          :link="link"
          v-show="currentId == '10'"
        ></out-link>
      </scree-head>
    </fullscreen-container>
    <s-box v-if="currentId == '1001'">
      <scree-head
        :is-scroll.sync="isScroll"
        @tabsChange="tabsChange"
        v-model="currentId"
      >
        <bim />
      </scree-head>
    </s-box>
  </div>
</template>

<script>
import AgeDistribute from "@/view/project/components/menber-management/age-distribute"
import WorkType from "@/view/project/components/menber-management/workType"
import SaftyEducation from "@/view/project/components/menber-management/safty-education"
import Attendance from "@/view/project/components/menber-management/attendance/index"
import HeWeather from "@/view/project/components/he-weather"

import Fullscreen from "@/view/project/components/fullscreen"
import ScreenTabs from "@/view/project/components/screen-tabs.vue"
import CheckWork from "@/view/project/components/menber-management/check-work"
import RankList from "@/view/project/components/menber-management/rank-list"
//数据总揽
import OnWorker from "@/view/project/components/menber-management/on-worker"
//数据总览
import DataOverall from "@/view/project/data-overall.vue"
// 问题整改
import ImprovementIssue from "@/view/project/improvement-issue"
//起重机械
import CraneMachine from "@/view/project/crane-machine"
//环境监测
import EnvInspect from "@/view/project/env-inspect"
import Env from "@/view/project/env"
//视频监控
import DataVideo from "@/view/project/data-video"
//车辆管理
import VehicleAdministration from "@/view/project/vehicle-administration.vue"
//防疫大屏
import Epidemic from "@/view/project/epidemic"
//bim模型
import Bim from "@/view/project/bim/index.vue"
import OutLink from "@/view/project/out-link"

import ScreeHead from "@/view/project/components/header/index.vue"

import VGaps from "@/view/project/components/v-gaps.vue"

import { mapGetters } from "vuex"
import cert from "@/view/project/components/hikvision-video/cert.vue"
import { fetchEditRotateStatus, fetchRotateStatus } from "@/api"

export default {
  components: {
    OnWorker,
    AgeDistribute,
    WorkType,
    SaftyEducation,
    Attendance,
    HeWeather,
    Fullscreen,
    ScreenTabs,
    CheckWork,
    RankList,
    DataOverall,
    ImprovementIssue,
    CraneMachine,
    EnvInspect,
    Env,
    DataVideo,
    VehicleAdministration,
    Epidemic,
    OutLink,
    cert,
    Bim,
    ScreeHead,
    VGaps,
  },
  watch: {
    projectId(val) {
      if (typeof val != "undefined") {
        this.request(() => {
          return fetchRotateStatus(val).then((res) => {
            this.isScroll = res.data.hasRotate == 1
          })
        })
      }
      // 环北部湾
      if (val === "22710551972937728") {
        this.link = "https://53299bq700.51vip.biz/"
      }
      // 环北部湾
      if (val === "22710551972937728") {
        this.link = "https://53299bq700.51vip.biz/"
      }
    },
    isScroll(val) {
      this.request(() => {
        return fetchEditRotateStatus(this.projectId, val ? 1 : 0)
      })
    },
  },
  methods: {
    tabsChange(id) {
      this.currentId = id
    },
  },
  data() {
    return {
      currentId: "1",
      isScroll: false,
      link: "http://47.108.94.49:8098", //默认
    }
  },
  computed: {
    ...mapGetters(["projectId"]),
    name() {
      return [
        {
          id: this.mainInfo.pm10,
          name: "sdadasd",
        },
        {
          id: "",
          name: "sdadasd",
        },
      ]
    },
  },
}
</script>

<style lang="less" scoped>
.screen-wrapper {
  width: 100%;
  min-height: 100%;
  background-color: #061b27 !important;
  background-image: url("../../assets/image/grid.png") !important;
  background-origin: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.screen {
  padding: 16px;

  .header {
    height: 80px;
    width: 1888px;
    background: url("../../assets/image/header-bg.gif");
    background-origin: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    position: relative;

    .rt {
      height: 80px;
      display: flex;
      align-items: center;
    }

    .tt {
      // position: absolute;
      // left: 50%;
      // margin-left: -288px;
    }

    .switch-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: white;

      .switch-title {
        margin-top: 15px;
      }
    }
  }
}

// .grid {
//   display: grid;
//   grid-template-columns: 618px 618px 616px;
//   grid-column-gap: 16px;
// }
.flex {
  display: flex;
  width: 1888px;

  &-part {
    // display: flex;
    margin-right: 16px;

    &-itemA {
      display: flex;
    }

    &-itemB {
      display: flex;
      margin-top: 16px;
    }
  }
}
</style>
