<template>
  <div class="grid">
    <div class="grid-top">
      <issue-general :overview="overview" />
      <quality-issue :typeItem="typeItem" />
      <safety-issue :typeItem="typeItem" />
    </div>
    <v-gaps :height="16" />
    <div class="grid-bottom">
      <leftover-problem />
      <issue-trend />
    </div>
  </div>
</template>
<script>
import IssueGeneral from "@/view/project/components/issue-improve/issue-general/index"
import QualityIssue from "@/view/project/components/issue-improve/quality-issue"
import SafetyIssue from "@/view/project/components/issue-improve/safety-issue"
import LeftoverProblem from "@/view/project/components/issue-improve/leftover-problem"
import IssueTrend from "@/view/project/components/issue-improve/issue-trend"
import VGaps from "@/view/project/components/v-gaps"
import { fetchOverview } from "@/api"
import { mapGetters } from "vuex"
export default {
  components: {
    IssueGeneral,
    QualityIssue,
    SafetyIssue,
    LeftoverProblem,
    IssueTrend,
    VGaps,
  },
  props: {
    currentId: {
      type: String,
      default: "",
    },
  },
  computed: mapGetters(["projectId", "currenData"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.id = val
          this.request(() => {
            return fetchOverview(val, this.queryCycle).then(({ data }) => {
              this.overview = data.overview
              this.typeItem = data.typeItem
            })
          })
        }
      },
    },
    currenData: {
      immediate: true,
      handler(val) {
        if (val != null) {
          this.queryCycle = val
          this.request(() => {
            return fetchOverview(this.id, val).then(({ data }) => {
              this.overview = data.overview
              this.typeItem = data.typeItem
            })
          })
        }
      },
    },
    currentId: {
      immediate: true,
      handler(val) {
        if (val == "3") {
          this.queryCycle = 0
          return fetchOverview(this.id, this.queryCycle).then(({ data }) => {
            this.overview = data.overview
            this.typeItem = data.typeItem
          })
        }
      },
    },
  },
  data() {
    return {
      overview: {}, //问题概况
      typeItem: [], //问题情况
      queryCycle: 0,
      id: "",
    }
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.grid {
  &-top {
    display: grid;
    grid-template-columns: 390px 733px 733px;
    grid-column-gap: 16px;
  }
  &-bottom {
    display: grid;
    grid-template-columns: 936px 936px;
    grid-column-gap: 16px;
  }
}
</style>
