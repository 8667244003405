<template>
  <div class="container work-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">今日考勤信息</div>
    </div>
    <div class="attendance-gauge">
      <div class="attendance-gauge-content">
        <gauge
          :max="totalBuilderNumber"
          :value="builderNumber"
          :color="builderColor"
          title="劳务工人：出勤"
          desc="当前在场劳务工人"
        />
        <div class="h-gaps24"></div>
        <gauge
          :max="totalEmpolyeeNumber"
          :value="empolyeeNumber"
          :color="empolyeeColor"
          title="项目人员：出勤"
          desc="当前在场项目人员"
          title-text-color="rgba(255, 165, 0, 1)"
        />
      </div>
    </div>
    <list :data="records" />
  </div>
</template>

<script>
import Gauge from "./gauge"
import List from "./list"
import VGaps from "../../v-gaps"
import { mapGetters } from "vuex"
import { fetchTodayAttendance } from "@/api"
export default {
  components: {
    Gauge,
    List,
    VGaps,
  },
  data() {
    return {
      totalBuilderNumber: 0,
      totalEmpolyeeNumber: 0,
      empolyeeNumber: 0,
      builderNumber: 0,
      records: [],
      columnData: [],
      records: [],
      builderColor: ["#56D6C7", "#409BFF", "#40D5FF"],
      empolyeeColor: ["#FFEC00", "#FFA500", "#FFA500"],
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchTodayAttendance(val).then(({ data }) => {
              this.records = data.Attendance
              this.builderNumber = data.workerNums
              this.totalBuilderNumber = data.totalBuilderNumber
              this.empolyeeNumber = data.PersonnelNum
              this.totalEmpolyeeNumber = data.totalStaffNumber
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.work-info {
  height: 694px;
  width: 616px;
  position: relative;
  &-line {
    width: 594px;
    height: 247px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .attendance-gauge {
    display: flex;
    width: 508px;
    margin: 27px 54px 33px 54px;
    &-content {
      display: flex;
    }
  }
}
</style>
