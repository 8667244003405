<template>
    <div class="container safty-info">
        <div class="container__content title">
            <div class="container__border"></div>
            <div class="container__title">今日安全报警</div>
        </div>
        <list :data="records"/>
    </div>
</template>

<script> 
import List from './list'
import { mapGetters } from 'vuex'
import { fetchWarningList } from '@/api'
export default {
    components: {
        List
    },
    data () {
        return {
            records: []
        }
    },
    computed: mapGetters(['projectId']),
    watch: {
        projectId: {
            immediate: true,
            handler (val) {
                if (val) {
                    this.request(() => {
                        return fetchWarningList(val).then(({data}) => {
                            if (data && data.length) {
                                this.records = data
                            } else {
                                this.records = []
                            }
                        })
                    })
                }
            }
        }
    },
    destroyed() {
        
    }
}
</script>

<style lang="less" scoped>
.safty-info {
    height: 570px;
    .title {
        margin-bottom: 18px;
    }
}
</style>

