<template>
  <div class="container__menber on-worker-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">在册人员情况</div>
    </div>
    <div class="on-worker-info-content">
      <div class="on-worker-info-content-gird">
        <div
          class="on-worker-info-content-gird-list"
          v-for="(item, index) in workerList"
          :key="index"
        >
          <div
            class="on-worker-info-content-gird-list-value"
            @click="openMemberList(item, index)"
          >
            <count-to
              :start-val="0"
              :end-val="item.value"
            />
          </div>
          <div class="on-worker-info-content-gird-list-name">{{ item.name }}</div>
        </div>
      </div>
      <div class="v-gaps12"></div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :modal-append-to-body="false"
      width="52%"
      @close="refreshData"
    >
      <data-display
        :visible="visible"
        :tableData="tableData"
        :listHeader="header"
        :memberName="title"
        :total="total"
        @handleSizePage="handleSizePage"
        @handleCurrentPage="handleCurrentPage"
      />
    </el-dialog>
  </div>
</template>

<script>
import DataDisplay from "../../display"
import CountTo from "@/components/count-to.vue"
import { mapGetters } from "vuex"
import { fetchRegisteredNumber, fetchWorkList, fetchTeamList, fetchStaffList, fetchSubPackList, fetchTotalList } from "@/api"
export default {
  components: {
    DataDisplay,
    CountTo,
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchRegisteredNumber(val).then(({ data }) => {
              this.workerList = data
            })
          })
        }
      },
    },
  },
  data() {
    return {
      tableData: [],
      title: "",
      visible: false,
      header: [],
      workerList: [
        {
          name: "在册总人数",
          value: 0,
        },
        {
          name: "在册项目人员",
          value: 0,
        },
        {
          name: "在册劳务工人",
          value: 0,
        },
        {
          name: "在册班组数量",
          value: 0,
        },
        {
          name: "专业分组",
          value: 0,
        },
        {
          name: "劳务分组",
          value: 0,
        },
      ],
      tableHeader: [
        {
          label: "姓名",
          width: 100,
        },
        {
          label: "联系电话",
          width: 120,
        },
        {
          label: "人员类型",
          width: 120,
        },
        {
          label: "所属班组",
          width: 200,
        },
        {
          label: "工种类型",
          width: 120,
        },
        {
          label: "入场时间",
          width: 160,
        },
      ],
      teamTableHeader: [
        {
          label: "班组名称",
          width: 160,
        },
        {
          label: "班组负责人",
          width: 100,
        },
        {
          label: "联系电话",
          width: 130,
        },
        // {
        //     label: '班组类型',
        //     width: 100
        // },
        {
          label: "工种类型",
          width: 130,
        },
        {
          label: "班组人数",
          width: 120,
        },
        {
          label: "入场时间",
          width: 167,
        },
      ],
      total: 0,
      pageNum: 1,
      pageSize: 25,
      currenIndex: 0,
    }
  },
  methods: {
    handleSizePage(val) {
      this.pageSize = val
      this.tableData = []
      if (this.currenIndex == 0) {
        this.getTotalList()
      } else if (this.currenIndex == 2) {
        //在册劳务工人
        this.getWorkList()
      } else if (this.currenIndex == 3) {
        //在册班组数量
        this.getTeamList()
      } else if (this.currenIndex == 1) {
        //在册项目人员
        this.getStaffList()
      } else if (this.currenIndex == 4) {
        let subcontractType = 1
        this.getSubPackList(subcontractType)
      } else if (this.currenIndex == 5) {
        let subcontractType = 0
        this.getSubPackList(subcontractType)
      }
    },
    handleCurrentPage(val) {
      this.pageNum = val
      this.tableData = []
      if (this.currenIndex == 0) {
        //在册总人数
        this.getTotalList()
      } else if (this.currenIndex == 2) {
        //在册劳务工人
        this.getWorkList()
      } else if (this.currenIndex == 3) {
        //在册班组数量
        this.getTeamList()
      } else if (this.currenIndex == 1) {
        //在册项目人员
        this.getStaffList()
      } else if (this.currenIndex == 4) {
        //专业分包
        let subcontractType = 1
        this.getSubPackList(subcontractType)
      } else if (this.currenIndex == 5) {
        //劳务分包
        let subcontractType = 0
        this.getSubPackList(subcontractType)
      }
    },
    getTotalList() {
      fetchTotalList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
        this.header = this.tableHeader
        this.workList(data.page.list)
        this.total = data.page.total
      })
    },
    getWorkList() {
      fetchWorkList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
        this.header = this.tableHeader
        this.workList(data.page.list)
        this.total = data.page.total
      })
    },
    getTeamList() {
      fetchTeamList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
        this.header = this.teamTableHeader
        this.staffList(data.page.list)
        this.total = data.page.total
      })
    },
    getStaffList() {
      fetchStaffList(this.projectId, this.pageNum, this.pageSize).then(({ data }) => {
        this.header = this.tableHeader
        this.workList(data.page.list)
        this.total = data.page.total
      })
    },
    getSubPackList(subcontractType) {
      fetchSubPackList(this.projectId, subcontractType, this.pageNum, this.pageSize).then(({ data }) => {
        this.header = this.teamTableHeader
        this.staffList(data.page.list)
        this.total = data.page.total
      })
    },
    openMemberList(item, index) {
      this.title = item.name
      this.currenIndex = index
      if (index == 0) {
        this.getTotalList()
      } else if (index == 2) {
        //在册劳务工人
        this.getWorkList()
      } else if (index == 3) {
        //在册班组数量
        this.getTeamList()
      } else if (index == 1) {
        //在册项目人员
        this.getStaffList()
      } else if (index == 4) {
        let subcontractType = 1
        this.getSubPackList(subcontractType)
      } else if (index == 5) {
        let subcontractType = 0
        this.getSubPackList(subcontractType)
      }
      this.visible = true
    },
    refreshData() {
      this.tableData = []
    },
    workList(list) {
      list.forEach((item) => {
        this.tableData.push({
          column: [
            {
              value: item.workerName,
              width: 100,
            },
            {
              value: item.phone,
              width: 120,
            },
            {
              value: item.type ? item.type : "项目人员",
              width: 120,
            },
            {
              value: item.teamName ? item.teamName : "-",
              width: 200,
            },
            {
              value: item.workType,
              width: 120,
            },
            {
              value: item.joinCompanyDate,
              width: 160,
            },
          ],
        })
      })
    },
    staffList(list) {
      list.forEach((item) => {
        this.tableData.push({
          column: [
            {
              value: item.teamName,
              width: 160,
            },
            {
              value: item.workerName,
              width: 100,
            },
            {
              value: item.phone,
              width: 130,
            },
            // {
            //     value: item.type,
            //     width: 100
            // },
            {
              value: item.workType,
              width: 130,
            },
            {
              value: item.num,
              width: 120,
            },
            {
              value: item.joinCompanyDate,
              width: 167,
            },
          ],
        })
      })
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0px 0px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
/deep/ .el-dialog__header {
  padding: 24px 16px 24px 16px;
  background: #062939;
}
/deep/ .el-dialog__title {
  line-height: 10px;
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
/deep/ .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: none;
  border-radius: 2px;
  box-shadow: none;
  box-sizing: border-box;
  width: 60%;
  border-top: 3px solid transparent;
  border-color: transparent;
  border-color: rgba(64, 213, 255, 0.3);
}
.on-worker-info {
  width: 1888px;
  height: 242px;
  &-content {
    &-gird {
      padding: 0 104px;
      display: flex;
      &-list {
        margin-right: 120px;
        background-image: url("../../../../../assets/image/worker.gif");
        &-value {
          width: 180px;
          height: 114px;
          // background: linear-gradient(360deg, rgba(64, 213, 255, 0.2) 0%, rgba(64, 213, 255, 0) 100%);
          font-size: 40px;
          font-family: Bebas;
          color: #ffffff;
          line-height: 114px;
          letter-spacing: 1px;
          text-align: center;
          cursor: pointer;
        }
        &-name {
          width: 180px;
          height: 46px;
          // background-image: url('../../../assets/image/worker.png');
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          line-height: 46px;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }
  }
}
</style>
