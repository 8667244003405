<template>
  <div class="marquee-text" >
    <span class="marquee-text__content"
        ref="text"
        :class="{'marquee-text__content--scrollable': scrollable}"
        :style="scrollable ? styles : {}"
    >{{text}}<span v-if="scrollable">{{text}}</span></span>
  </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ''
        },
        speed: {
            type: [Number, String],
            default: '8s'
        }
    },
    data () {
        return {
            scrollable: false
        }
    },
    computed: {
        styles () {
            return {
                animationDuration: typeof this.speed === 'number' ? `${this.speed}ms` : this.speed
            }
        }
    },
    watch: {
        text: {
            immediate: false,
            handler () {
                this.init()
            }
        }
    },
    methods: {
        init () {
            this.scrollable = false
            this.$nextTick(() => {
                const dom = this.$refs.text
                const parent = dom.parentElement
                if (!parent) this.scrollable = false
                else this.scrollable = parent.scrollWidth > parent.clientWidth
            })
        }
    },
    mounted () {
        this.init()  
    }
}
</script>

<style scoped>
.marquee-text {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.marquee-text:hover .marquee-text__content {
  animation-play-state: paused;
}
.marquee-text__content {
  display: inline-block;
}
.marquee-text__content--scrollable {
    animation-name: marquee-text-animation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
@keyframes marquee-text-animation {
    0% { transform:translateX(0); }
    100% { transform:translateX(-50%); }
}
</style>