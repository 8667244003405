<template>
  <div
    class="select"
    :class="{ 'select--expanded': visible }"
    v-click-outside="onClickOutside"
  >
    <div
      class="select-input"
      @click="onClick"
    >
      <input
        :value="displayLabel"
        placeholder="请选择"
        readonly
      />
      <img
        class="icon"
        src="../../../../../assets/image/arrow-bottom.png"
      />
    </div>
    <ul class="list scrollbar">
      <li
        class="list-item"
        v-for="(item, index) in countSelectBoxData"
        :key="'info' + index"
        :class="{ 'list-item--active': currentKey == item.key }"
        @click="onSelect(item)"
      >
        <span>{{ item.label }}</span>
      </li>
    </ul>
    <div class="border lt"></div>
    <div class="border rt"></div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside"
import { mapState } from "vuex"
export default {
  props: {
    currentKey: {
      type: String,
      default: "allCount",
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapState({
      countSelectBoxData: (state) => state.countSelectBoxData,
    }),
    displayLabel() {
      let index = -1
      if (this.countSelectBoxData && this.countSelectBoxData.length) index = this.countSelectBoxData.findIndex((it) => it.key === this.currentKey)
      return index == -1 ? `` : `${this.countSelectBoxData[index].label}`
    },
  },
  methods: {
    onClick() {
      if (this.countSelectBoxData && this.countSelectBoxData.length) {
        this.visible = !this.visible
      }
    },
    onSelect(item) {
      this.$emit("currentType", item.key)
      this.visible = false
    },
    onClickOutside() {
      this.visible = false
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>
<style lang="less" scoped>
.select {
  height: 36px;
  background: rgba(#02a3f3, 0.2);
  box-sizing: border-box;
  width: 114px;
  position: relative;
  padding: 0 14px;
  cursor: pointer;

  .select-label {
    position: absolute;
    left: -75px;
    top: 10px;
    color: white;
  }

  &::before {
    position: absolute;
    content: "";
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(#cdf8ff, 0.5);
  }

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#cdf8ff, 0.5);
  }

  &-input {
    height: 100%;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      flex: 1;
      user-select: none;
      background: none;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #40d5ff;
      cursor: pointer;
      text-overflow: ellipsis;
      /*去除阴影*/
      box-shadow: none;
      /*聚焦input的蓝色边框*/
      outline: none;
      /*textarea 禁止拖拽*/
      resize: none;
      /*去除边框*/
      border: none;
      /*常用于IOS下移除原生样式*/
      -webkit-appearance: none;
      /*点击高亮的颜色*/
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &::placeholder {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #667f8b;
      }
    }

    .icon {
      flex: none;
      width: 18px;
      height: 18px;
      transition: 0.3s;
    }
  }

  .list {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    background: #0c1828;
    visibility: hidden;
    box-sizing: border-box;
    opacity: 0;
    transition: 0.3s;
    padding: 6px 0;
    transform: translateY(35px);
    max-height: 187px;
    overflow: auto;

    &-item {
      padding: 0 14px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #40d5ff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: transparent;

      &:hover {
        background: #3c4e66;
        color: white;
      }

      &.list-item--active {
        color: #fff;
        background: #3c4e66;
      }
    }
  }

  &.select--expanded {
    .list {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
    }

    .icon {
      transform: rotate(180deg);
    }
  }
}

.border {
  position: absolute;
  content: "";
  width: 1px;
  top: 0;
  bottom: 0;
  background: rgba(#cdf8ff, 0.5);

  &.lt {
    left: 0;
  }

  &.rt {
    right: 0;
  }
}
</style>
