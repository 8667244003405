<template>
    <div class="env-item">
        <div class="env-item-name">{{ label }}</div>
        <div class="env-item-unit">{{ unit }}</div>
        <div class="env-item-value">{{ value }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: 0
        },
        unit: {
            type: String,
            default: '天'
        }
    }
}
</script>

<style lang="less" scoped>
.env-item {
    width: 150px;
    height: 28px;
    background: #07425E;
    margin-bottom: 4px;
    line-height: 28px;
    &-name {
        float: left;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        padding-left: 12px;
    }
    &-value {
        float: right;
        font-size: 14px;
        font-family: Bebas;
        color: #FFFFFF;
        padding-right: 6px;
    }
    &-unit {
        float: right;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        padding-right: 12px;
    }
}
</style>