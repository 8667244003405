<template>
    <div class="table-list">
        <div>
            <ul class="table hd">
                <li class="table-row">
                    <div class="table-row__cell name">报警设备</div>
                    <div class="table-row__cell content">报警内容</div>
                    <div class="table-row__cell warningTime">报警时间</div>
                </li>
            </ul>
        </div>
        <vue-seamless-scroll class="recordlist"
            v-if="data && data.length"
            :data="data"
            :class-option="classOption">
            <ul  class="table">
                <li class="table-row" v-for="item in data" :key="item.id">
                    <div class="table-row__cell name">{{item.machineName}}</div>
                    <div class="table-row__cell content">{{item.contentTitle}}</div>
                    <div class="table-row__cell warningTime">{{item.createTime}}</div>
                </li>
            </ul>
        </vue-seamless-scroll>
        <div v-else class="empty">
            暂无数据
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            classOption: {
                singleHeight: 44,
            }
        }
    }
}
</script>

<style lang="less" scoped>
.recordlist {
    height: 440px;
    overflow: hidden;
}
.empty {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 440px;
    text-align: center;
}
.table-list {
    margin: 0px 16px;
}
.table {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #40D5FF;
    .table-row {
        display: flex;
        height: 44px;
        align-items: center;
        background: rgba(7, 51, 72, 1);
        padding: 0 12px;
        &:nth-child(odd) {
            background: rgba(6, 41, 57, 1);
        }
        &__cell {
            white-space: nowrap;
            overflow: hidden;
            flex: none;
            &.name {
                width: 117px;
            }
            &.content {
                width: 188px;
                padding-left: 24px;
            }
            &.warningTime {
                width: 121px;
                padding-left: 24px;
            }
        }
    }
    &.hd {
        .table-row {
            background: rgba(7, 51, 72, 1)!important;
            color: #fff;
        }
    }
}
</style>