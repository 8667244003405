<template>
  <div class="container__menber safty-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">安全教育</div>
    </div>
    <div class="safty-info-content">
      <div class="safty-info-content-total">
        <div class="safty-info-content-total-word">
          <div class="safty-info-content-total-word-center">
            <div class="safty-info-content-total-word-center-num">
              <count-to
                :start-val="0"
                :end-val="workerNums"
              />
            </div>
            <div class="safty-info-content-total-word-center-unit">人</div>
          </div>
          <div class="safty-info-content-total-word-tip">在册人员总数</div>
        </div>
      </div>
      <div class="safty-info-content-img">
        <img
          ref="gif"
          src="../../../../../assets/image/line.gif"
          alt=""
        />
      </div>
      <div class="safty-info-content-list">
        <div
          v-for="(item, index) in trainList"
          :key="'trainList' + index"
          class="safty-info-content-list-train"
        >
          <div class="safty-info-content-list-train-name">{{ item.name }}</div>
          <div class="safty-info-content-list-train-unit">{{ item.unit }}</div>
          <div class="safty-info-content-list-train-value">
            <count-to
              v-if="item.name == '完成培训占比'"
              :start-val="0"
              :decimals="2"
              :end-val="Number(item.value)"
            />
            <count-to
              v-else
              :start-val="0"
              :end-val="Number(item.value)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
import "@/plugins/super-gif"
import "@/plugins/rubbable-gif"
import { mapGetters } from "vuex"
import { fetchSafetyTraining } from "@/api"
export default {
  components: {
    CountTo,
  },
  data() {
    return {
      workerNums: 0,
      trainList: [
        {
          name: "完成培训",
          value: 0,
          unit: "人",
        },
        {
          name: "待培训",
          value: 0,
          unit: "人",
        },
        {
          name: "完成培训占比",
          value: 0.0,
          unit: "%",
        },
      ],
      time: 8890, //ms
    }
  },
  mounted() {
    this.initLibgif(this.time) //初始gif
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchSafetyTraining(val).then(({ data }) => {
              this.workerNums = data.workerNums
              this.trainList[0].value = data.completeNum
              this.trainList[1].value = data.incompleteNum
              if (data.completeNum == 0 && data.workerNums == 0) {
                this.trainList[2].value = 0.0
              } else {
                this.trainList[2].value = ((data.completeNum / data.workerNums) * 100).toFixed(2)
              }
            })
          })
        }
      },
    },
  },
  methods: {
    initLibgif(time) {
      var rub = new SuperGif({ gif: this.$refs.gif, progressbar_height: 0, loop_delay: time, auto_play: false })
      rub.load(function () {
        rub.play()
      })
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.safty-info {
  height: 339px;
  width: 533px;
  margin-right: 16px;
  &-content {
    margin: 5px 8px 12px 8px;
    display: flex;
    width: 517px;
    height: 266px;
    &-total {
      margin: 29px 0px 29px 18px;
      height: 211px;
      width: 211px;
      background-image: url("../../../../../assets/image/round.gif");
      background-size: 100% 100%;
      position: relative;
      &-word {
        width: 100px;
        height: 60px;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        &-center {
          display: flex;
          line-height: 36px;
          justify-content: center;
          &-num {
            font-size: 32px;
            font-family: Bebas;
            color: #40d5ff;
          }
          &-unit {
            font-size: 18px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
            padding-top: 5px;
          }
        }
        &-tip {
          font-size: 14px;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }
    }
    &-img {
      margin-top: 33px;
      height: 211px;
      width: 101px;
    }
    &-list {
      width: 174px;
      margin-top: 37px;
      &-train {
        background-image: url("../../../../../assets/image/safty.png");
        background-size: 100% 100%;
        width: 174px;
        height: 46px;
        margin-top: 9px;
        margin-bottom: 24px;
        line-height: 46px;
        &-name {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          margin-left: 12px;
          float: left;
        }
        &-value {
          font-size: 16px;
          font-family: Bebas;
          color: #40d5ff;
          float: right;
          margin-right: 6px;
        }
        &-unit {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          float: right;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
