<template>
  <div class="container env">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">环境质量汇总</div>
      <div class="selector">
        <time-select @currentType="currentType" />
      </div>
    </div>
    <div class="env-info">
      <div class="env-info-qualty">
        <div class="env-info-qualty-title">空气质量</div>
        <item
          :label="excellentLable"
          :value="excellent"
          :unit="qualtyUnit"
        />
        <item
          :label="goodLable"
          :value="good"
          :unit="qualtyUnit"
        />
        <item
          :label="lightPollutionLable"
          :value="lightPollution"
          :unit="qualtyUnit"
        />
        <item
          :label="moderatePollutionLable"
          :value="moderatePollution"
          :unit="qualtyUnit"
        />
        <item
          :label="heavyPollutionLable"
          :value="heavyPollution"
          :unit="qualtyUnit"
        />
        <item
          :label="seriousPollutionLable"
          :value="seriousPollution"
          :unit="qualtyUnit"
        />
      </div>
      <div class="env-info-border"></div>
      <div class="env-info-voice">
        <div class="env-info-voice-title">噪声</div>
        <item
          :label="standardLable"
          :value="standard"
          :unit="qualtyUnit"
        />
        <item
          :label="unStandardLable"
          :value="unStandard"
          :unit="qualtyUnit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchEnvironmentSummary } from "@/api"
import Item from "./item"
import TimeSelect from "./time-select"
export default {
  components: {
    TimeSelect,
    Item,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      excellentLable: "优秀",
      excellent: 0,
      goodLable: "良好",
      good: 0,
      lightPollutionLable: "轻度污染",
      lightPollution: 0,
      moderatePollutionLable: "中度污染",
      moderatePollution: 0,
      heavyPollutionLable: "重度污染",
      heavyPollution: 0,
      seriousPollutionLable: "严重污染",
      seriousPollution: 0,
      standardLable: "达标",
      standard: 0,
      unStandardLable: "不达标",
      unStandard: 0,
      qualtyUnit: "天",
      type: "year",
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val && this.id) {
          return fetchEnvironmentSummary(val, this.type, this.id).then(({ data }) => {
            if (data) {
              Object.assign(this._data, data)
            }
          })
        }
      },
    },
    id: {
      immediate: true,
      handler(val) {
        if (val && this.projectId) {
          return fetchEnvironmentSummary(this.projectId, this.type, val).then(({ data }) => {
            if (data) {
              Object.assign(this._data, data)
            }
          })
        }
      },
    },
  },
  methods: {
    currentType(type) {
      this.type = type
      return fetchEnvironmentSummary(this.projectId, this.type, this.id).then(({ data }) => {
        if (data) {
          Object.assign(this._data, data)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.env {
  height: 307px;
  position: relative;
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }
  &-info {
    display: flex;
    margin: 17px 35px 12px 34px;
    &-qualty {
      width: 150px;
      padding-right: 35px;
      &-title {
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #073348;
        background-image: url("../../../../../assets/image/env/allover.png");
        margin-bottom: 4px;
      }
    }
    &-border {
      width: 1px;
      height: 222px;
      background: #409bff;
      opacity: 0.2;
    }
    &-voice {
      width: 150px;
      padding-left: 35px;
      &-title {
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #073348;
        background-image: url("../../../../../assets/image/env/allover.png");
        margin-bottom: 4px;
      }
    }
  }
}
</style>
