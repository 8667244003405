<template>
  <div class="container equip">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">设备列表</div>
    </div>
    <div
      class="equip-info scrollbar"
      v-if="equiplist && equiplist.length"
    >
      <div
        v-for="(item, index) in equiplist"
        :key="item.warnTimes"
      >
        <div
          @click="currenEquip(item)"
          class="equip-info-list"
          :class="{ 'equip-info-list--warning': item.safyWarning, 'equip-info-list--current': item.id == currenId }"
        >
          <div
            class="equip-info-list-left"
            v-if="item.status == '在线'"
            @mouseenter="enter(index)"
            @mouseleave="leave()"
          >
            <img src="@/assets/image/crane/onCrane.gif" />
          </div>
          <div
            class="equip-info-list-left"
            v-else
            @mouseenter="enter(index)"
            @mouseleave="leave()"
          >
            <img src="@/assets/image/crane/offCrane.png" />
          </div>
          <div
            class="equip-info-list-right"
            v-if="item.status == '在线'"
          >
            <div class="equip-info-list-right-onequip">
              <div class="equip-info-list-right-onequip-equipName">
                <div class="equip-info-list-right-onequip-equipName-name">{{ item.name }}</div>
                <div class="equip-info-list-right-onequip-equipName-online">在线</div>
                <div
                  class="equip-info-list-right-onequip-equipName-current"
                  v-if="item.id == currenId"
                >
                  当前设备
                </div>
              </div>
              <div class="equip-info-list-right-onequip-equipNumber">设备编号：{{ item.indexCode }}</div>
              <div class="equip-info-list-right-onequip-warning">
                累计报警次数：
                <div class="equip-info-list-right-onequip-warning-times">{{ item.recordCount }}</div>
                次
              </div>
            </div>
            <div class="equip-info-list-right-line"></div>
            <div class="equip-info-list-right-onOperator">
              <div class="equip-info-list-right-onOperator-name">姓名：{{ item.operatorName }}</div>
              <div class="equip-info-list-right-onOperator-phone">联系电话：{{ item.operatorMobile }}</div>
            </div>
            <div
              class="equip-info-list-right-line"
              v-if="item.safyWarning"
            ></div>
            <div
              class="equip-info-list-right-tip"
              v-if="item.safyWarning"
            >
              安全警告：{{ item.warningReason }}
            </div>
            <div
              class="display"
              v-show="seen && index == current"
            >
              <div class="display-info">
                <p>设备信息</p>
                <p>设备名称：{{ item.name }}</p>
                <p>塔吊前臂长：{{ item.majorArm }}</p>
                <p>塔吊后臂长：{{ item.balanceArm }}</p>
                <p>塔吊高度：{{ item.height }}</p>
              </div>
            </div>
          </div>
          <div
            class="equip-info-list-right"
            v-else
          >
            <div class="equip-info-list-right-offequip">
              <div class="equip-info-list-right-offequip-equipName">
                <div class="equip-info-list-right-offequip-equipName-name">{{ item.name }}</div>
                <div class="equip-info-list-right-offequip-equipName-offline">离线</div>
                <div
                  class="equip-info-list-right-offequip-equipName-current"
                  v-if="item.id == currenId"
                >
                  当前设备
                </div>
              </div>
              <div class="equip-info-list-right-offequip-equipNumber">设备编号：{{ item.indexCode }}</div>
              <div class="equip-info-list-right-offequip-warning">
                累计报警次数：
                <div class="equip-info-list-right-offequip-warning-times">{{ item.recordCount }}</div>
                次
              </div>
            </div>
            <div class="equip-info-list-right-line"></div>
            <div class="equip-info-list-right-offOperator">
              <div class="equip-info-list-right-offOperator-name">姓名：{{ item.operatorName }}</div>
              <div class="equip-info-list-right-offOperator-phone">联系电话：{{ item.operatorMobile }}</div>
            </div>
            <div
              class="display"
              v-show="seen && index == current"
            >
              <div class="display-info">
                <p>设备信息</p>
                <p>设备名称：{{ item.name }}</p>
                <p>塔吊前臂长：{{ item.majorArm }}</p>
                <p>塔吊后臂长：{{ item.balanceArm }}</p>
                <p>塔吊高度：{{ item.height }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import { fetchMachineList } from "@/api"
import { mapGetters } from "vuex"
import { SET_CURREN_EQUIP } from "@/store"
export default {
  data() {
    return {
      classOption: {
        singleHeight: 188,
      },
      equiplist: [],
      currenId: null,
      seen: false,
      current: 0,
    }
  },
  methods: {
    currenEquip(val) {
      if (val.id == this.equiplist[0].id) {
        this.currenId = val.id
      } else {
        this.currenId = val.id
      }
      this.$store.dispatch(SET_CURREN_EQUIP, {
        currenEquip: val,
      })
    },
    enter(index) {
      this.seen = true
      this.current = index
    },
    leave() {
      this.seen = false
      this.current = null
    },
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchMachineList(val).then(({ data }) => {
              if (data && data.length) {
                this.equiplist = data
                if (this.currenId == null) {
                  this.currenId = data[0].id
                  this.$store.dispatch(SET_CURREN_EQUIP, {
                    currenEquip: data[0],
                  })
                }
              } else {
                this.equiplist = []
                this.currenId = ""
                this.$store.dispatch(SET_CURREN_EQUIP, {
                  currenEquip: [],
                })
              }
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.equiplist {
  overflow: hidden;
  margin-top: 18px;
  height: 867px;
}
.empty {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 867px;
  text-align: center;
}
.equip {
  height: 952px;
  .equip-info {
    height: 890px;
    overflow: auto;
    &-list {
      width: 358px;
      margin: 0px 16px;
      margin-bottom: 16px;
      display: flex;
      background: #073348;
      position: relative;
      &.equip-info-list--warning {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: url("../../../../../assets/image/crane/warning.png");
          background-position: center;
          background-size: cover;
          animation: flash 1.6s;
          animation-iteration-count: infinite;
          animation-fill-mode: forwards;
          z-index: 1;
        }
      }
      &.equip-info-list--current {
        width: 352px;
        border-left: 3px solid rgba(64, 213, 255, 0.3);
        border-right: 3px solid rgba(64, 213, 255, 0.3);
      }
      &-left {
        width: 40px;
        padding: 16px 12px 0px 16px;
        .img {
          width: 40px;
          height: 40px;
        }
      }
      &-right {
        width: 290px;
        position: relative;
        .display {
          position: absolute;
          top: 16px;
          left: 0px;
          width: 175px;
          height: 120px;
          background: #061b27;
          opacity: 0.9;
          &-info {
            padding: 10px 13px;
            height: 12px;
            font-size: 12px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #ffffff;
            p {
              padding-bottom: 10px;
            }
          }
        }
        &-onequip {
          height: 102px;
          &-equipName {
            height: 18px;
            padding-top: 16px;
            display: flex;
            width: 280px;
            &-name {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #40d5ff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 180px;
            }
            &-online {
              width: 34px;
              height: 18px;
              margin-left: 4px;
              background: #40d5ff;
              border-radius: 2px;
              font-size: 12px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #073348;
              line-height: 18px;
              text-align: center;
            }
            &-current {
              height: 18px;
              width: 58px;
              margin-left: 4px;
              font-size: 12px;
              background: #ffffff;
              border-radius: 2px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #073348;
              line-height: 18px;
              text-align: center;
            }
          }
          &-equipNumber {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40d5ff;
            padding-top: 16px;
            width: 260px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-warning {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40d5ff;
            padding-top: 12px;
            display: flex;
            &-times {
              color: #ffffff;
              padding-right: 4px;
            }
          }
        }
        &-offequip {
          height: 102px;
          &-equipName {
            height: 18px;
            padding-top: 16px;
            display: flex;
            width: 280px;
            &-name {
              font-size: 18px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #ffffff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 180px;
            }
            &-offline {
              width: 34px;
              height: 18px;
              margin-left: 4px;
              background: #ffa500;
              border-radius: 2px;
              font-size: 12px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #073348;
              line-height: 18px;
              text-align: center;
            }
            &-current {
              height: 18px;
              width: 58px;
              margin-left: 4px;
              font-size: 12px;
              background: #ffffff;
              border-radius: 2px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #073348;
              line-height: 18px;
              text-align: center;
            }
          }
          &-equipNumber {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            padding-top: 16px;
            width: 280px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-warning {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            padding-top: 12px;
            display: flex;
            &-times {
              color: #40d5ff;
              padding-right: 4px;
            }
          }
        }
        &-line {
          width: 274px;
          height: 1px;
          background: #409bff;
          opacity: 0.2;
        }
        &-tip {
          height: 14px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffa500;
          padding-bottom: 12px;
          padding-top: 16px;
        }
        &-onOperator {
          &-name {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40d5ff;
            padding-top: 14px;
          }
          &-phone {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40d5ff;
            padding-top: 10px;
            padding-bottom: 16px;
          }
        }
        &-offOperator {
          &-name {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            padding-top: 14px;
          }
          &-phone {
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ffffff;
            padding-top: 10px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}
@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
