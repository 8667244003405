<template>
  <div class="container leftover-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">遗留待整改问题</div>
    </div>
    <div class="leftover-info-content">
      <div class="leftover-info-content-issue">
        <div
          class="leftover-info-content-issue-part"
          v-for="(item, index) in issueNumList"
          :key="'leftOver' + index"
        >
          <img :src="item.icon" />
          <div class="leftover-info-content-issue-part-name">{{ item.name }}</div>
          <div class="leftover-info-content-issue-part-value">{{ item.value }}</div>
          <div class="leftover-info-content-issue-part-unit">{{ item.unit }}</div>
        </div>
      </div>
      <list :data="records" />
    </div>
  </div>
</template>

<script>
import List from "./list"
import overtimeChange from "@/assets/image/issue/overtimeChange.png"
import waitChange from "@/assets/image/issue/waitChange.png"
import { fetchAwaitRectifyList } from "@/api"
import { mapGetters } from "vuex"
export default {
  components: {
    List,
  },
  data() {
    return {
      issueNumList: [
        {
          name: "待整改问题：",
          value: 0,
          unit: "个",
          icon: waitChange,
        },
        {
          name: "逾期未整改：",
          value: 0,
          unit: "个",
          icon: overtimeChange,
        },
      ],
      records: [],
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchAwaitRectifyList(val).then(({ data }) => {
              this.records = data.list
              this.issueNumList[0].value = data.extra.total
              this.issueNumList[1].value = data.extra.overdueQty
            })
          })
        }
      },
    },
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.leftover-info {
  height: 468px;
  &-content {
    padding: 18px 16px 4px 16px;
    &-issue {
      height: 66px;
      display: flex;
      &-part {
        display: flex;
        width: 200px;
        height: 66px;
        align-items: center;
        margin-right: 16px;
        background-image: url("../../../../../assets/image/issue/iconBackground.png");
        img {
          width: 20px;
          height: 20px;
          margin-left: 18px;
        }
        &-name {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          margin-left: 8px;
          padding-top: 2px;
        }
        &-value {
          height: 24px;
          font-size: 18px;
          font-family: Bebas;
          color: #ffffff;
          padding-top: 3px;
        }
        &-unit {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          margin-left: 2px;
          padding-top: 2px;
        }
      }
    }
  }
}
</style>
