<template>
  <!-- <v-chart class="chart" :option="option"/> -->
  <div
    ref="charts"
    :style="{ height: '100%', width: '100%' }"
  ></div>
</template>
<script>
import * as echarts from "echarts"
export default {
  props: {
    womanNumber: {
      type: Array,
      default() {
        return []
      },
    },
    manNumber: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    manNumber: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  computed: {
    option() {
      return {
        legend: {
          right: "5px",
          top: "-5px",
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          textStyle: {
            fontSize: 10,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFF",
          },
          data: [{ name: "男" }, { name: "女" }],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          formatter(params) {
            if (params.length > 1) {
              const item1 = params[0] //劳务工人
              const item2 = params[1] //项目人员
              return `
                                <p style="margin-bottom: 10px">${item2.axisValueLabel}<p>
                                <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data}<p>
                                <p>${item2.marker}${item2.seriesName} ${item2.data}<p>
                            `
            } else if ((params.length = 1)) {
              const item1 = params[0] //劳务工人
              return `
                                <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                                <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data}<p>
                            `
            } else {
              return {
                axisPointer: {
                  type: "none",
                },
              }
            }
          },
        },
        grid: {
          top: "15px",
          left: "0px",
          right: "21px",
          bottom: "1px",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "category",
          data: this.manNumber.map((item) => {
            let a = Object.keys(item)
            return item[a[0]]
          }),
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "男",
            data: this.manNumber.map((item) => {
              let a = Object.keys(item)
              return item[a[1]]
            }),
            type: "bar",
            barWidth: 6,
            showBackground: false,
            itemStyle: {
              color: "#40D5FF",
            },
          },
          {
            name: "女",
            type: "bar",
            barWidth: 6,
            data: this.womanNumber.map((item) => {
              let a = Object.keys(item)
              return item[a[1]]
            }),
            showBackground: false,
            itemStyle: {
              color: "#FBA401",
            },
          },
        ],
      }
    },
  },
  methods: {
    initCharts() {
      this.$nextTick(() => {
        if (!this.myChart) {
          var chartDom = this.$refs.charts
          this.myChart = echarts.init(chartDom, null, {
            devicePixelRatio: window.devicePixelRatio || 1,
          })
        }
        this.myChart.setOption(this.option)
      })
    },
    updateCharts() {
      this.myChart.setOption(this.option)
    },
  },
}
</script>

<style lang="less" scoped></style>
