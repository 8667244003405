<template>
  <div class="main">
    <div class="inspact-data">
      <div style="display: flex">
        <dv-title>实时监测数据详情</dv-title>
        <div class="spray-equip">
          <spray-equip />
        </div>
        <div class="equip">
          <div class="equip-tip">当前设备</div>
          <equip-select
            v-model="equip"
            :data="equipList"
            @equipChange="equipChange"
          />
        </div>
      </div>
      <div class="inspact-data-detail">
        <inspect-data
          :mainInfo="mainInfo"
          :limitMap="limitMap"
        ></inspect-data>
      </div>
    </div>
    <div class="chart">
      <div class="chart-flying">
        <div style="display: flex">
          <dv-title v-if="chartType == 'line'">扬尘曲线图</dv-title>
          <dv-title v-if="chartType == 'bar'">扬尘柱状图</dv-title>
          <div class="chart-flying-logo">
            <img
              v-if="chartType == 'line'"
              title="切换为柱状图"
              @click="changeChartType"
              src="../../assets/image/env/line.png"
            />
            <img
              v-if="chartType == 'bar'"
              title="切换为曲线图"
              @click="changeChartType"
              src="../../assets/image/env/bar.png"
            />
          </div>
        </div>
        <div>
          <flying-dust
            :chartType="chartType"
            :pmTimeData="pmTimeData"
            :limitMap="limitMap"
          ></flying-dust>
        </div>
      </div>
      <div class="chart-voice">
        <div style="display: flex">
          <dv-title v-if="voiceChartType == 'bar'">噪声柱状图</dv-title>
          <dv-title v-if="voiceChartType == 'line'">噪声曲线图</dv-title>
          <div class="chart-voice-logo">
            <img
              v-if="voiceChartType == 'line'"
              title="切换为柱状图"
              @click="changeVoiceChartType"
              src="../../assets/image/env/line.png"
            />
            <img
              v-if="voiceChartType == 'bar'"
              title="切换为曲线图"
              @click="changeVoiceChartType"
              src="../../assets/image/env/bar.png"
            />
          </div>
        </div>
        <div>
          <voice-chart
            :voiceChartType="voiceChartType"
            :voiceTimeData="voiceTimeData"
            :limitMap="limitMap"
          ></voice-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SprayEquip from "@/view/project/components/env-inspect/env/spray-equip"
import InspectData from "@/view/project/components/env-inspect/env/inspectdata"
import DvTitle from "@/view/project/components/env-inspect/widgets/title"
import FlyingDust from "@/view/project/components/env-inspect/widgets/flyingdust"
import VoiceChart from "@/view/project/components/env-inspect/widgets/voicechart"
import EquipSelect from "@/view/project/components/env-inspect/env/equipselect"
import ws from "websocketjs" //导入websocketjs
import { mapGetters } from "vuex"
import { fetchFindDeviceForComBox } from "@/api"
import { v4 as uuidv4 } from "uuid"
export default {
  name: "App",
  components: {
    SprayEquip,
    InspectData,
    DvTitle,
    FlyingDust,
    VoiceChart,
    EquipSelect,
  },
  data() {
    return {
      chartType: "line",
      voiceChartType: "bar",
      ws: null,
      mainInfo: null,
      pmTimeData: {},
      voiceTimeData: {},
      limitMap: {},
      serialNo: "", // 设备编号
      equipList: [],
      equip: "",
      id: "",
      project: "",
    }
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.project = val
          // this.close()
          fetchFindDeviceForComBox(val).then(({ data }) => {
            if (data && data.length) {
              this.id = data[0].id
              this.equipList = data
              this.$nextTick(() => {
                this.connectWs(val)
              })
            } else {
              this.id = 0
              this.equipList = []
              this.$nextTick(() => {
                this.connectWs(val)
              })
            }
          })
        }
      },
    },
  },
  methods: {
    connectWs(val) {
      console.log(val)
      //   this.ws = new ws(`ws://192.168.0.112:89/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, '')}`)
      // this.ws = new ws(`ws://192.168.1.231:8083/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, '')}`)
      this.close()
      this.ws = new ws(`${process.env.VUE_APP_WS_PATH}/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, "")}`)
      this.ws.onmessage = this.onWsmessage
      this.ws.onopen = this.openWS
      this.ws.onclose = this.closeWs
    },
    close() {
      if (this.ws != null) {
        this.ws.close()
        this.ws = null
      }
    },
    closeWs(e) {
      console.log(e)
    },
    onWsmessage(msg) {
      let data = {}
      if (msg.data) data = JSON.parse(msg.data)
      if (data.mainInfo != undefined) this.mainInfo = data.mainInfo
      if (data != undefined) this.pmTimeData = data
      if (data != undefined) this.voiceTimeData = data
      if (data.limitMap != undefined && data.limitMap != null) this.limitMap = data.limitMap
    },
    openWS() {
      if (this.ws)
        this.ws.send(
          JSON.stringify({
            msg: this.projectId,
          })
        )
    },
    findDeviceForComBox() {
      let search = window.location.search || ""
      let query = {}
      if (search) {
        search = search.slice(1).split("&")
        search.forEach((s) => {
          const [k, v] = s.split("=")
          query[k] = v
        })
      }
      if (query.projectId) {
        fetchFindDeviceForComBox(query.projectId).then(({ data }) => {
          this.id = data[0].id
          this.equipList = data
        })
      }
    },
    equipChange(id) {
      this.id = id
      this.connectWs(this.project)
    },
    changeChartType() {
      if (this.chartType == "bar") {
        this.chartType = "line"
      } else {
        this.chartType = "bar"
      }
    },
    changeVoiceChartType() {
      if (this.voiceChartType == "bar") {
        this.voiceChartType = "line"
      } else {
        this.voiceChartType = "bar"
      }
    },
  },
  created() {
    console.log("创建")
  },
  beforeDestroy() {
    console.log("关闭")
    this.close()
  },
}
</script>

<style lang="less" scoped>
.main {
  float: left;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  .inspact-data {
    width: 1888px;
    height: 390px;
    // background-image: linear-gradient(to bottom,rgba(116,236,255,0.1) 0%,  rgba(64,213,255, 0.1) 100%);
    border-top: 3px solid rgba(64, 213, 255, 0.3);
    // border-radius: 0px 0px 16px 16px;
    margin-bottom: 16px;
    position: relative;
    background: #062939;
    .spray-equip {
      display: flex;
      position: absolute;
      top: 19px;
      left: 260px;
      background: #073348;
      border-radius: 2px;
      display: flex;
    }
    .equip {
      display: flex;
      position: absolute;
      top: 19px;
      right: 20px;
      &-tip {
        width: 80px;
        height: 20px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #40d5ff;
        line-height: 35px;
        margin-right: 12px;
      }
      select {
        width: 280px;
        height: 36px;
        color: #fff;
        font-size: 20px;
        outline: none;
        background: none;
        padding-left: 8px;
        line-height: 30px;
        border: 1px solid transparent;
        border-image: linear-gradient(#57d5c7, #409cfe) 3;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        // background: url(../assets/images/arrow.png) no-repeat scroll 255px center transparent;
      }
      option {
        color: turquoise;
        background: violet;
      }
      option:hover {
        background: wheat;
      }
    }
    &-detail {
      div {
        height: calc(100% - 24px);
        box-sizing: border-box;
        color: #fff;
      }
    }
  }
  .chart {
    display: flex;
    &-flying {
      width: 936px;
      height: 540px;
      //   background-image: linear-gradient(to bottom,rgba(116,236,255,0.1) 0%,  rgba(64,213,255, 0.1) 100%);
      border-top: 3px solid rgba(64, 213, 255, 0.3);
      //   border-radius: 0px 0px 16px 16px;
      margin-right: 16px;
      background: #062939;
      &-logo {
        margin-left: 16px;
        margin-top: 24px;
        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
    }
    &-voice {
      width: 936px;
      height: 537px;
      // background: linear-gradient(360deg, #74ECFF 0%, #40D5FF 100%);
      //   background-image: linear-gradient(to bottom,rgba(116,236,255,0.1) 0%,  rgba(64,213,255, 0.1) 100%);
      border-top: 3px solid rgba(64, 213, 255, 0.3);
      //   border-radius: 0px 0px 16px 16px;
      background: #062939;
      &-logo {
        margin-left: 16px;
        margin-top: 24px;
        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
