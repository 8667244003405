<template>
    <div>
        <div class="container-dialog">
            <div class="container-dialog-body">
                <div class="table-list">
                    <div>
                        <ul class="table hd">
                            <li class="table-row">
                                <div v-for="(item, index) in listHeader" :key="'tableHeader' + index">
                                    <div class="table-row__cell" :style="`width: ${item.width}px`">{{ item.label }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="tableData && tableData.length" class="table-data scrollbar">
                        <ul  class="table">
                            <li class="table-row" v-for="(list, index) in tableData" :key="'tableData' + index">
                                <template>
                                    <div  v-for="(item, index) in list.column" :key="'column' + index">
                                        <div class="table-row__cell" :style="`width: ${item.width}px`">{{ item.value }}</div>
                                    </div>
                                </template>
                            </li>
                        </ul>
                    </div>
                    <div v-else class="empty">
                        暂无数据
                    </div>
                </div>
            </div>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 25, 50, 100, 200]"
                    :page-size="25"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
            default () {
                return []
            }
        },
        listHeader: {
            type: Array,
            default () {
                return []
            }
        },
        total: {
            type: Number,
            default: 0
        },
        width: {
            type: String,
            default: '55%'
        }
    },
    data () {
        return {
            currentPage: 1,
        }
    },
    watch: {
    },
    computed: {
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.$emit('handleSizePage', val)
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.$emit('handleCurrentPage', val)
        },
    },
    mounted () {
    }
}
</script>

<style lang="less" scoped>
    .container-dialog {
        background: #062939;
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 16px;
            &__title {
                font-size: 18px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;  
            }
            &__icon {
                width: 18px;
                height: 18px;
                display: block;
                cursor: pointer;
                transition: .4s;
                &:hover {
                    transform: rotate(180deg);
                }
            }
        }
        &-body {
            padding-bottom: 24px;
        }
    }
    .empty {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 460px;
        text-align: center;
    }
    .table-list {
        padding: 0px 16px;
        .table-data {
            box-sizing: border-box;
            overflow: auto;
            height: 460px;
        }
    }
    .table {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #40D5FF;
        .table-row {
            display: flex;
            height: 46px;
            align-items: center;
            background: rgba(7, 51, 72, 1);
            &:nth-child(odd) {
                background: rgba(6, 41, 57, 1);
            }
            &__cell {
                white-space: nowrap;
                overflow: hidden;
                flex: none;
                padding: 0 12px;
            }
        }
        &.hd {
            .table-row {
                background: rgba(7, 51, 72, 1)!important;
                color: #fff;
            }
        }
    }
    .pagination {
        padding: 8px 16px 16px 16px;
        /deep/ .el-pagination__total {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40D5FF;
            height: 32px;
            line-height: 32px;
        }
        /deep/ .el-pagination .el-select .el-input {
            width: 97px;
            margin: 0 4px;
        }
        /deep/ .el-pagination .el-select .el-input .el-input__inner {
            border-radius: 2px;
            height: 32px;
            cursor: pointer;
            line-height: normal;
            font-size: 14px;
            -webkit-appearance: none;
            background-color: #0C4D6C;
            background-image: none;
            border: 1px solid #0C4D6C;
            box-sizing: border-box;
            color: #40D5FF;
            display: inline-block;
            outline: 0;
            padding: 0 15px;
            text-align: inherit;
            transition: border-color .2s cubic-bezier(.645,.045,.355,1);
            width: 97px;
        }
        /deep/ .el-select .el-input .el-select__caret {
            color: #40D5FF;
            font-size: 14px;
            transition: transform .3s;
            transform: rotateZ(180deg);
            cursor: pointer;
        }
        /deep/ .el-input__icon {
            height: 95%;
            width: 25px;
            text-align: center;
        }
        /deep/ .el-pager li.active {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
        }
        /deep/ .el-pager li {
            padding: 0 4px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            background: #062939;
            font-size: 14px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            box-sizing: border-box;
            text-align: center;
            color: #40D5FF;
        }
        /deep/ .el-pagination .btn-prev {
            width: 32px;
            height: 32px;
            background: center center no-repeat #062939;
            background-size: 16px;
            cursor: pointer;
            margin: 0;
            color: #40D5FF;
        }
        /deep/ .el-pagination .btn-next {
            width: 32px;
            height: 32px;
            background: center center no-repeat #062939;
            background-size: 16px;
            cursor: pointer;
            margin: 0;
            color: #40D5FF;
        }
        /deep/ .el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon {
            display: block;
            font-size: 7px;
            font-weight: 700;
        }
        /deep/ .el-pagination button:disabled {
            width: 32px;
            height: 32px;
            color: #40D5FF;
            background-color: #062939;
            cursor: not-allowed;
        }
            /deep/ .el-select-dropdown {
                position: absolute;
                z-index: 3001;
                background: rgba(6, 41, 57, 0.7) !important;
                border: 1px solid #40d5ff;
                border-radius: 4px;
                box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
                box-sizing: border-box;
                padding: 6px 0;
                box-sizing: border-box;
                transition: 0.3s;
            }
            /deep/ .el-select-dropdown__item {
                padding: 0 14px;
                height: 35px;
                line-height: 35px;
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #40D5FF;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                background: transparent;
            }
            /deep/ .el-select-dropdown__item.selected {
                color: #fff;
                background: #073348;
            }
        /deep/ .el-pagination__jump {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #40D5FF;
        }
        /deep/ .el-pagination__editor.el-input {
            width: 46px;
        }
        /deep/ .el-pagination__editor.el-input .el-input__inner {
            height: 30px;
            background: #0C4D6C;
            border: 1px solid #0C4D6C;
            border-radius: 2px;
            color: #40D5FF;
        }
        /deep/ li .el-select-dropdown {
            position: absolute;
            z-index: 1001;
            border: 1px solid #40d5ff;
            border-radius: 4px;
            background: rgba(6, 41, 57, 0.7);
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            box-sizing: border-box;
            margin: 5px 0;
        }
        /deep/ .el-select-dropdown__item.selected {
            color: #FFFFFF;
            font-weight: 700;
        }
        /deep/ .el-select-dropdown__item {
            color: #409EFF;
            font-weight: 700;
        }
    }

</style>