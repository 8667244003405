<template>
  <div class="container worker-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">工人年龄、性别分布</div>
    </div>
    <div class="worker-info-word">
      <div class="worker-info-word-age">
        <img src="../../../../../assets/image/ageIcon.png" />
        <div class="worker-info-word-age-discribe">年龄分布</div>
      </div>
      <div class="worker-info-word-sex">
        <img src="../../../../../assets/image/sexIcon.png" />
        <div class="worker-info-word-sex-discribe">性别分布</div>
      </div>
    </div>
    <div class="worker-info-distribute">
      <div class="worker-info-distribute-age">
        <age
          v-if="isShow"
          :womanNumber="womanNumber"
          :manNumber="manNumber"
        />
        <div
          v-else
          class="empty"
        >
          暂无数据
        </div>
      </div>
      <div class="worker-info-distribute-sex">
        <div class="worker-info-distribute-sex-man">
          <div style="display: flex">
            <div class="worker-info-distribute-sex-man-name">男性：</div>
            <div class="worker-info-distribute-sex-man-value">
              <count-to
                :start-val="0"
                :end-val="manTotal"
              />
            </div>
            <div
              class="worker-info-distribute-sex-man-name"
              style="margin-left: 2px; margin-top: 20px"
            >
              人
            </div>
          </div>
          <div style="display: flex; margin-top: 10px">
            <div class="worker-info-distribute-sex-man-name">占比：</div>
            <div class="worker-info-distribute-sex-man-value">
              <count-to
                :start-val="0"
                :decimals="2"
                :end-val="Number(manNumProp)"
                separator=""
              />
            </div>
            <div
              class="worker-info-distribute-sex-man-name"
              style="margin-left: 2px; margin-top: 20px"
            >
              %
            </div>
          </div>
        </div>
        <div class="worker-info-distribute-sex-line"></div>
        <div class="worker-info-distribute-sex-woman">
          <div style="display: flex">
            <div class="worker-info-distribute-sex-woman-name">女性：</div>
            <div class="worker-info-distribute-sex-woman-value">
              <count-to
                :start-val="0"
                :end-val="womanTotal"
              />
            </div>
            <div
              class="worker-info-distribute-sex-woman-name"
              style="margin-left: 2px; margin-top: 20px"
            >
              人
            </div>
          </div>
          <div style="display: flex; margin-top: 10px">
            <div class="worker-info-distribute-sex-woman-name">占比：</div>
            <div class="worker-info-distribute-sex-woman-value">
              <count-to
                :start-val="0"
                :decimals="2"
                :end-val="Number(womanNumProp)"
                separator=""
              />
            </div>
            <div
              class="worker-info-distribute-sex-woman-name"
              style="margin-left: 2px; margin-top: 20px"
            >
              %
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Age from "./age"
import CountTo from "@/components/count-to"
import { fetchAgeGroup } from "@/api"
import { mapGetters } from "vuex"
export default {
  components: {
    Age,
    CountTo,
  },
  data() {
    return {
      womanNumber: [],
      manNumber: [],
      manTotal: 0,
      womanTotal: 0,
      manNumProp: 0,
      womanNumProp: 0,
      isShow: true,
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.request(() => {
            return fetchAgeGroup(val).then(({ data }) => {
              if (data.man || data.woman) {
                this.womanNumber = data.woman
                this.manNumber = data.man
                this.isShow = true
              } else {
                this.isShow = false
              }
              this.womanNumber = data.woman
              this.manNumber = data.man
              this.manTotal = data.sexNum[0].num
              this.womanTotal = data.sexNum[1].num
              if (data.sexNum[0].num == 0) {
                this.manNumProp = 0.0
              } else {
                this.manNumProp = ((data.sexNum[0].num / data.ageNum) * 100).toFixed(2)
              }
              if (data.sexNum[1].num == 0) {
                this.womanNumProp = 0.0
              } else {
                this.womanNumProp = ((data.sexNum[1].num / data.ageNum) * 100).toFixed(2)
              }
            })
          })
        }
      },
    },
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.worker-info {
  height: 339px;
  width: 618px;
  margin-right: 16px;
  &-word {
    display: flex;
    &-age {
      display: flex;
      width: 345px;
      height: 40px;
      margin-left: 20px;
      margin-right: 49px;
      img {
        padding: 12px 4px 12px 12px;
      }
      &-discribe {
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        padding: 12px 0px 12px 0px;
      }
    }
    &-sex {
      display: flex;
      width: 184px;
      height: 40px;
      img {
        padding: 12px 4px 12px 12px;
      }
      &-discribe {
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        padding: 12px 0px 12px 0px;
      }
    }
  }
  &-distribute {
    display: flex;
    margin-top: 10px;
    &-age {
      width: 345px;
      height: 217px;
      margin-left: 20px;
      margin-right: 49px;
    }
    &-sex {
      width: 202px;
      height: 217px;
      &-man {
        height: 104px;
        &-name {
          margin-left: 12px;
          margin-top: 16px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
        &-value {
          margin-left: 12px;
          height: 43px;
          font-size: 32px;
          font-family: Bebas;
          color: #40d5ff;
          line-height: 43px;
          letter-spacing: 1px;
        }
      }
      &-line {
        width: 170px;
        height: 1px;
        background: #409bff;
        opacity: 0.2;
      }
      &-woman {
        height: 112px;
        margin-top: 15px;
        &-name {
          margin-left: 12px;
          margin-top: 16px;
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
        &-value {
          margin-left: 12px;
          height: 43px;
          font-size: 32px;
          font-family: Bebas;
          color: #ffa500;
          line-height: 43px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>
