<template>
    <div class="wrapper">
        <canvas ref="canvas" width="235" height="235"></canvas>
        <div class="tip" v-if="value > threshold">{{ data.tip }}</div>
        <div class="displayV">
            <div class="num">{{ value}}</div>
            <div class="unit">{{ data.unit}}</div>
        </div>
        <div class="word" :style="`background: ${titleColor}`"><div class="title" :style="`color: ${wordColor}`">{{ data.title }}</div></div>
    </div>
</template>
<script>
import { Gauge } from './gauge.js'
export default { 
    props: {
        value: {
            type: String,
            default: '0'
        },
        threshold: {
            type: Number,
            default: 0
        },
        data: {
            type: Object
        }
    },
    computed: {
        titleColor () {
            if (this.value > this.threshold) return '#F15050'
            else return '#FFFFFF'
        },
        wordColor () {
            if (this.value > this.threshold) return '#FFFFFF'
            else return this.data.color
        }
    },
    watch: {
        threshold (val) {
            if (this.g) {
                this.g.updateThreshold(val)
            }
        },
        value (val) {
            if (this.g) {
                this.g.update(val)
            }
        }
    },
    mounted () {
        this.g = new Gauge ({
            value: this.value,
            threshold: this.threshold,
            data: this.data
        })
        this.g.mount(this.$refs.canvas)
        
    },
}

</script>
<style lang="less" scoped>
.wrapper {
    position: relative;
    .tip {
        position:absolute; top:26%;left:50%; transform:translate(-50%,-50%); font-size: 28px; text-align: center; font-weight: bold; color: #FF3939;
    }
    .displayV {
        position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display: flex; align-items: baseline;
        .num {
            color: #FFFFFF; font-size: 34px;
        }
        .unit {
            color: #FFFFFF; font-size: 17px;
        }
    }
    .word {
        position:absolute;top:100%;left:50%;transform:translate(-50%,-40px);font-size:20px;width: 116px; height: 46px; border-radius: 27px;background: #F15050;
        .title {
            font-size: 29px; font-family: SourceHanSansCN-Bold, SourceHanSansCN; font-weight: bold; line-height: 46px; text-align: center; 
        }
    }
}
</style>