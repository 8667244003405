<template>
  <!-- <v-chart class="chart" :option="option"/> -->
  <div ref="charts"></div>
</template>

<script>
import charts from "@/mixins/charts"
export default {
  mixins: [charts],
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.initCharts()
      },
    },
  },
  computed: {
    option() {
      let barStyle = {
        barWidth: 10,
        barGap: 0,
        barCategoryGap: 28,
        clip: false,
        // tooltip: {
        //     backgroundColor: 'rgba(6, 27, 39, .9)',
        //     borderWidth: 0,
        //     textStyle: {
        //         fontSize: 12,
        //         color: '#fff'
        //     }
        // }
      }
      return {
        title: {
          text: "出勤人数统计",
          left: 0,
          top: 0,
          textStyle: {
            color: "#fff",
            fontSize: 14,
            fontWeight: 400,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
          },
        },
        legend: {
          show: true,
          right: 0,
          top: 0,
          icon: "circle",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: "#fff",
            fontSize: 10,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
          },
        },
        grid: {
          show: false,
          top: 42,
          // width: '100%',
          // x: 0,
          x2: 0,
          height: "67%",
        },

        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              width: "20",
            },
          },
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          formatter(params) {
            if (params.length > 1) {
              const item1 = params[0] //劳务工人
              const item2 = params[1] //项目人员
              return `
                                <p style="margin-bottom: 10px">${item2.axisValueLabel}<p>
                                <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[1]}<p>
                                <p>${item2.marker}${item2.seriesName} ${item2.data[2]}<p>
                            `
            } else if ((params.length = 1)) {
              const item1 = params[0] //劳务工人
              if (params[0].seriesName == "劳务工人") {
                return `
                                    <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                                    <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[1]}<p>
                                `
              } else {
                return `
                                    <p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                                    <p style="margin-bottom: 10px">${item1.marker}${item1.seriesName} ${item1.data[2]}<p>
                                `
              }
            } else {
              return {
                axisPointer: {
                  type: "none",
                },
              }
            }
          },
        },
        dataset: {
          source: [["日期", "劳务工人", "项目人员"], ...this.data],
        },
        xAxis: {
          type: "category",
          boundaryGap: ["0%", "0%"],
          nameGap: 0,
          axisLabel: {
            fontSize: 10,
            color: "rgba(255,255,255, 1)",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.2,
              type: "solid",
            },
            onZero: false,
          },
        },
        yAxis: {
          axisLabel: {
            fontSize: 10,
            color: "rgba(255,255,255, 1)",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.2,
              type: "solid",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#fff",
              opacity: 0.2,
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            ...barStyle,
            itemStyle: {
              color: "#40D5FF",
            },
          },
          {
            type: "bar",
            ...barStyle,
            itemStyle: {
              color: "#FBA401",
            },
          },
        ],
      }
    },
  },
}
</script>
