<template>
  <div class="grid">
    <div
      class="grid-item"
      v-if="isAuth"
    >
      <monitor :projId="id" />
    </div>
    <auth-error v-else></auth-error>
  </div>
</template>
<script>
import Monitor from "@/view/project/components/data-video/monitor"
import { fetchCheckAuthorized } from "@/api"
import { mapGetters } from "vuex"
import AuthError from "@/view/project/components/authError"

export default {
  name: "App",
  components: {
    Monitor,
    AuthError,
  },
  data() {
    return {
      id: "",
      isAuth: true,
    }
  },
  computed: mapGetters(["projectId"]),
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.id = val
        }
      },
    },
  },
  created() {
    this.request(() => {
      return fetchCheckAuthorized(this.projectId).then((res) => {
        this.isAuth = res.data.check
      })
    })
  },
}
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 1888px;
  grid-column-gap: 16px;
}
</style>
