<template>
  <div class="grid">
    <div class="grid-item">
      <equip-list />
    </div>
    <div class="grid-item">
      <tower-crane-monitoring />
      <v-gaps :height="16" />
      <crane-data />
    </div>
    <div class="grid-item">
      <history-warning-statistic />
      <v-gaps :height="16" />
      <safty-warning />
    </div>
  </div>
</template>
<script>
import EquipList from "@/view/project/components/crane-machine/equip-list"
import TowerCraneMonitoring from "@/view/project/components/crane-machine/tower-crane-monitoring"
import CraneData from "@/view/project/components/crane-machine/crane-data"
import HistoryWarningStatistic from "@/view/project/components/crane-machine/history-warning-statistic"
import SaftyWarning from "@/view/project/components/crane-machine/safty-warning"
import VGaps from "@/view/project/components/v-gaps"
export default {
  components: {
    EquipList,
    TowerCraneMonitoring,
    CraneData,
    HistoryWarningStatistic,
    SaftyWarning,
    VGaps,
  },
}
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 390px 936px 530px;
  grid-column-gap: 16px;
}
</style>
