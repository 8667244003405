<template>
  <div class="container trend-info">
    <div class="container__content">
      <div class="container__border"></div>
      <div class="container__title">车辆进出趋势</div>
      <div class="selector">
        <!-- <data-select v-model="dataTime" @currentType="currentType"/> -->
        <data-select
          @currentType="currentType"
          :currentKey="currentKey"
        />
      </div>
    </div>
    <div class="trend-info-line">
      <!-- <v-chart class="chart" :option="option"/> -->
      <div
        ref="charts"
        :style="{ height: '100%', width: '100%' }"
      ></div>
      <!-- <div v-show="isEmpty" class="empty">暂无数据</div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import DataSelect from "./data-select"
import { mapGetters } from "vuex"
export default {
  components: {
    DataSelect,
  },
  props: {},
  data() {
    return {
      dataType: 7,
      data: [],
      dataTime: "",
      id: "",
      currentKey: "sevenDayChartData",
      currentData: [],
    }
  },
  watch: {
    chartData(n, o) {
      if (n) this.currentType(this.currentKey)
    },
  },
  methods: {
    currentType(val) {
      if (val) this.currentKey = val
      if (this.chartData && this.chartData[this.currentKey].rows) {
        this.currentData = this.chartData[this.currentKey].rows
      } else {
        this.currentData = []
      }
      this.initCharts()
    },
    initCharts() {
      if (!this.myChart) {
        var chartDom = this.$refs.charts
        this.myChart = echarts.init(chartDom, null, {
          devicePixelRatio: window.devicePixelRatio || 1,
        })
      }
      this.myChart.setOption(this.option)
    },
    updateCharts() {
      this.myChart.setOption(this.option)
    },
  },
  computed: {
    isEmpty() {
      return this.option.series[0].data.length === 0
    },
    ...mapGetters(["chartData"]),
    option() {
      return {
        title: {
          text: "数量",
          textStyle: {
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFFFFF",
          },
          left: "0px",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(6, 27, 39, 0.9)",
          borderWidth: 0,
          borderRadius: 4,
          padding: 10,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 12,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#40D5FF",
              type: "solid",
            },
          },
        },
        legend: {
          right: "5px",
          top: "-5px",
          itemHeight: 10,
          itemWidth: 10,
          icon: "circle",
          textStyle: {
            fontSize: 10,
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: 400,
            color: "#FFF",
          },
          data: [{ name: "进场" }, { name: "出场" }],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.currentData.map((item) => {
            let a = Object.keys(item)
            return item[a[2]]
          }),
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
            // onZero: false,
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN-Regular, SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        grid: {
          top: "37.5px",
          bottom: "0",
          left: "0",
          right: "17px",
          containLabel: true, // grid 区域是否包含坐标轴的刻度标签。(如果true的时候，上下左右可以为0了)
        },
        series: [
          {
            name: "进场",
            type: "line",
            itemStyle: {
              color: "rgba(0, 199, 255, 1)",
            },
            data: this.currentData.map((item) => {
              let a = Object.keys(item)
              return item[a[0]]
            }),
            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(116, 236, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(116, 236, 255, 0.0)",
                  },
                ],
              },
            },
          },
          {
            name: "出场",
            type: "line",
            itemStyle: {
              color: "rgba(255, 165, 0, 1)",
            },
            data: this.currentData.map((item) => {
              let a = Object.keys(item)
              return item[a[1]]
            }),
            areaStyle: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255, 165, 0, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 165, 0, 0)",
                  },
                ],
              },
            },
          },
        ],
      }
    },
  },
}
</script>

<style lang="less" scoped>
.trend-info {
  height: 356px;
  width: 100%;
  position: relative;
  &-line {
    width: 100%;
    height: 290px;
    padding: 8px 20px 16px 27px;
    box-sizing: border-box;
  }
  .selector {
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
  }
  .empty {
    position: absolute;
    left: 0;
    right: 0;
    top: 74px;
    bottom: 0;
    line-height: 290px;
  }
}
</style>
