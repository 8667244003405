<template>
  <div class="ailse">
    <div class="current">
      <div class="current-member">
        <div class="current-member-display">
          <div class="current-member-display-num">
            <count-to
              :start-val="0"
              :end-val="stepInBuilderNum"
              separator=""
            />
          </div>
          <div class="current-member-display-unit">人</div>
        </div>
        <div class="current-member-title">当前在场</div>
      </div>
      <div class="current-list">
        <list
          :data="stepInBuilderList"
          :tableRowHeight="38"
        />
      </div>
    </div>
    <div class="border"></div>
    <div class="overtime">
      <div class="overtime-member">
        <div class="current-member-display">
          <div class="overtime-member-display-num">
            <count-to
              :start-val="0"
              :end-val="stepInBuilderOverTimeNum"
              separator=""
            />
          </div>
          <div class="current-member-display-unit">人</div>
        </div>
        <div class="current-member-title">超时未离场</div>
      </div>
      <div class="overtime-list">
        <list
          :data="stepInBuilderOverTimeList"
          :tableRowHeight="37"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import List from "./list.vue"
import CountTo from "@/components/count-to.vue"
import { v4 as uuidv4 } from "uuid"
import ws from "websocketjs" //导入websocketjs
export default {
  components: {
    List,
    CountTo,
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  data() {
    return {
      stepInBuilderList: [],
      stepInBuilderOverTimeList: [],
      stepInBuilderNum: 0,
      stepInBuilderOverTimeNum: 0,
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.close()
          this.connectWs(val)
        }
      },
    },
  },
  methods: {
    connectWs(val) {
      console.log(val)
      // this.ws = new ws(`ws://192.168.0.143:89/hero/websocket/security_${val}_${uuidv4().replace(/-/g, '')}`)
      // this.ws = new ws(`ws://192.168.0.143:89/hero/websocket/security_${val}_${uuidv4().replace(/-/g, '')}`)
      this.close()
      this.ws = new ws(`${process.env.VUE_APP_WS_PATH}/hero/websocket/security_${val}_${uuidv4().replace(/-/g, "")}`)
      this.ws.onmessage = this.onWsmessage
      this.ws.onopen = this.openWS
      this.ws.onclose = this.closeWs
    },
    close() {
      if (this.ws != null) {
        this.ws.close()
        this.ws = null
      }
    },
    closeWs(e) {
      console.log(e)
    },
    onWsmessage(msg) {
      let data = {}
      if (msg.data) data = JSON.parse(msg.data)
      if (data.stepInBuilderList != undefined) this.stepInBuilderList = data.stepInBuilderList
      if (data.stepInBuilderOverTimeList != undefined) this.stepInBuilderOverTimeList = data.stepInBuilderOverTimeList
      this.stepInBuilderNum = data.stepInBuilderNum
      this.stepInBuilderOverTimeNum = data.stepInBuilderOverTimeNum
    },
    openWS() {
      if (this.ws)
        this.ws.send(
          JSON.stringify({
            msg: this.projectId,
          })
        )
    },
  },
  beforeDestroy() {
    this.close()
  },
  destroyed() {},
}
</script>
<style lang="less" scoped>
.ailse {
  padding: 6px 16px 13px 16px;
  .current {
    display: flex;
    &-member {
      width: 236px;
      height: 200px;
      &-display {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 44px;
        &-num {
          font-size: 56px;
          font-family: Bebas;
          color: #40d5ff;
          line-height: 74px;
          letter-spacing: 1px;
        }
        &-unit {
          font-size: 18px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          margin-left: 2px;
          padding-top: 40px;
        }
      }
      &-title {
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .border {
    width: 768px;
    height: 1px;
    background: #409bff;
    opacity: 0.2;
    margin-bottom: 10px;
  }
  .overtime {
    display: flex;
    &-member {
      width: 236px;
      height: 195px;
      &-display {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        &-num {
          font-size: 56px;
          font-family: Bebas;
          color: #ffa500;
          line-height: 74px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>
