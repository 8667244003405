<template>
  <div class="item">
    <div class="item-value">
      <div class="item-value-num">
        <count-to
          :start-val="0"
          :end-val="value"
        />
      </div>
    </div>
    <div class="item-img"><img src="../../../../../assets/image/epidemic/epidemic_bg.png" /></div>
    <div class="item-name">{{ name }}</div>
    <img
      src="../../../../../assets/image/epidemic/epidemic_bottom.png"
      style="width: 210px; height: 7px"
    />
  </div>
</template>

<script>
import CountTo from "@/components/count-to.vue"
export default {
  components: {
    CountTo,
  },
  props: {
    value: {
      type: Number,
      default: "0",
    },
    name: {
      type: String,
      default: "名称",
    },
    unit: {
      type: String,
      default: "",
    },
    unitTop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  margin: 0px 66px;
  &-value {
    margin-bottom: -18px;
    display: flex;
    font-size: 58px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1px;
    align-items: center;
    justify-content: center;
    -webkit-line-clamp: 1;
    background-image: -webkit-linear-gradient(bottom, #12f6ff, #02a3f3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-img {
    text-align: center;
    img {
      width: 169px;
      height: 64px;
    }
  }
  &-name {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: -8px;
  }
}
</style>
