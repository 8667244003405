<template>
  <div class="tree-container">
    <template v-if="data[0].children && data[0].children.length">
      <input
        id="video-dropdown-input"
        v-if="filterable"
        style="color: #40d5ff !important"
        placeholder="请输入关键字进行过滤"
        v-model="keyword"
      />
      <div class="tree-wrapper scrollbar">
        <el-tree
          :style="fiexdHeight ? { height: filterable ? 'calc(100% - 82px)' : '100%', overflowY: 'auto', whiteSpace: 'no-wrap' } : {}"
          :data="data"
          ref="tree"
          style="max-height: 100%; overflow: auto; text-overflow: ellipsis"
          :empty-text="emptyText"
          :props="options"
          :load="load"
          :lazy="lazy"
          :check-on-click-node="checkOnClickNode"
          :node-key="nodeKey"
          :highlight-current="highlightCurrent"
          :default-expand-all="defaultExpandAll"
          :show-checkbox="showCheckbox"
          :default-checked-keys="defaultCheckedKeys"
          :default-expanded-keys="defaultExpandedKeys"
          :get-checked-keys="true"
          :filter-node-method="__filter"
          :expand-on-click-node="expandOnClickNode"
          :indent="indent"
          :current-node-key="currentNodeKey"
          :accordion="accordion"
          :allowDrag="__allowDrag"
          :allowDrop="__allowDrop"
          :draggable="draggable"
          @current-change="onCurrentChange"
          @node-drag-end="onNodeDropEnd"
          @node-contextmenu="onNodeContextMenu"
          @node-click="onNodeClick"
          @check-change="onCheckChange"
          @check="onCheck"
          @node-expand="onNodeExpand"
          @node-collapse="onNodeCollapse"
          :render-content="renderContent"
        >
          <!-- <tree-node slot-scope="{node}" :disabled="node.data[options.disabled]" :options="options" v-model="node.data[options.label]"></tree-node> -->
        </el-tree>
      </div>
      <div class="btn-wrap">
        <!-- <el-button class="btn" @click.native="toResetCheck()">清空</el-button> -->
        <el-button
          class="btn"
          @click.native="toVideoPlay()"
          >确定</el-button
        >
      </div>
    </template>
    <div
      v-else
      class="tree-wrapper"
    >
      <div class="tree-none">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tree",
  props: {
    data: {
      // 展示数据
      type: Array,
      default() {
        return []
      },
    },
    nodeKey: {
      type: String,
      default: "id",
    },
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    props: {
      // 配置项
      type: Object,
      default() {
        return {}
      },
    },
    load: Function, // 懒加载函数
    lazy: Boolean, // 是否开启懒加载
    highlightCurrent: {
      // 是否高亮当前节点
      type: Boolean,
      default: false,
    },
    expandOnClickNode: Boolean,
    defaultExpandAll: Boolean, // 是否默认展开所有节点
    showCheckbox: Boolean, // 节点是否可以被选择（多选）
    currentNodeKey: [String, Number], // 当前选中节点key
    defaultCheckedKeys: {
      // 默认勾选的节点的key数组
      type: Array,
      default() {
        return []
      },
    },
    checkOnClickNode: {
      type: Boolean,
      default: false,
    },
    defaultExpandedKeys: {
      type: Array,
      default() {
        return []
      },
    },
    filterable: {
      // 是否可过滤（搜索）
      type: Boolean,
      default: true,
    },
    filterNodeMethod: Function, // 节点过滤函数
    accordion: Boolean, // 是否每次只打开同一级树节点
    indent: {
      // 相邻节点间的水平缩颈
      type: Number,
      default: 16,
    },
    draggable: {
      // 是否开启拖拽节点功能
      type: Boolean,
      default: false,
    },
    allowDrag: Function, // 节点是否能够被拖拽 Function (node) 默认情况下一级不允许拖拽
    allowDrop: Function, // 节点是否能够被放置，默认情况下只允许同级别的节点被放置
    fiexdHeight: Boolean,
  },
  data() {
    return {
      keyword: "",
      tree: null,
      defaultProps: {
        label: "label",
        children: "children",
        disabled: "disabled",
        isLeaf: "isLeaf",
      },
      options: {},
    }
  },
  watch: {
    keyword(newVal, oldVal) {
      if (this.$refs.tree && oldVal !== newVal) {
        this.$refs.tree.filter(this.keyword)
      }
    },
  },
  methods: {
    __initData(data) {
      if (!data) return
      for (let i = 0; i < data.length; ++i) {
        this.$set(data[i], "__edit", false)
        if (data[this.options.children]) this.__initData(data[this.options.children])
      }
    },
    __filter(value, data, node) {
      if (this.filterNodeMethod && typeof this.filterNodeMethod === "function") {
        return this.filterNodeMethod(value, data, node)
      } else {
        return this.__defaultFilterNodeMethod(value, data)
      }
    },
    __defaultFilterNodeMethod(value, data) {
      if (!value) return true
      return this.props.label ? (typeof this.props.label !== "function" ? data[this.props.label].indexOf(value) !== -1 : data[this.props.label()].indexOf(value) !== -1) : data.label.indexOf(value) !== -1
    },
    __allowDrag(node) {
      if (this.allowDrag && typeof this.allowDrag === "function") {
        return this.allowDrag(node)
      } else return this.__defaultAllowDrag(node)
    },
    __defaultAllowDrag(node) {
      // 默认情况下，一级节点不允许拖拽
      return node.level !== 1
    },
    __allowDrop(draggingNode, dropNode, type) {
      if (this.allowDrop && typeof this.allowDrop === "function") {
        return this.allowDrop(draggingNode, dropNode, type)
      } else return this.__defaultAllowDrop(draggingNode, dropNode, type)
    },
    __defaultAllowDrop(draggingNode, dropNode, type) {
      // 默认情况下，不允许非同级元素交换顺序且不允许交换到下级，并且限制在同一个层级下
      return draggingNode.level === dropNode.level && draggingNode.parent === dropNode.parent && type !== "inner"
    },
    onCurrentChange(data, node) {
      // 叶子节点
      let leaf = !data[this.options.children] || !data[this.options.children].length ? node : null
      this.$emit("current-change", data, node, leaf)
    },
    onNodeDropEnd(prev, next, type, e) {
      if (next) {
        // 如果后台需要改变节点顺序，那么可以通过next.parent.children获取到拖拽后的节点
        this.$emit("node-drop-success", prev, next, type, e)
      } else {
        this.$emit("node-drop-failed", prev, type, e)
      }
    },
    onNodeClick(o, node, vm) {
      this.$emit("node-click", o, node, vm)
    },
    onNodeContextMenu(e, o, node, vm) {
      this.$emit("node-contextmenu", e, o, node, vm)
    },
    onCheckChange(o, checked, childrenChecked) {
      this.$emit("check-change", o, checked, childrenChecked)
    },
    onCheck(node, state) {
      this.$emit("check", node, state)
    },
    onNodeExpand(o, node, vm) {
      this.$emit("node-expand", o, node, vm)
    },
    onNodeCollapse(o, node, vm) {
      this.$emit("node-collapse", o, node, vm)
    },
    getCurrentNode() {
      // element ui bug???
      let nodeData = this.$refs.tree.getCurrentNode()
      if (nodeData) {
        return this.getNode(nodeData[this.nodeKey])
      }
      return null
    },
    getNode(key) {
      return this.$refs.tree.getNode(key)
    },
    setCurrentKey(nodeKey) {
      this.$refs.tree.setCurrentKey(nodeKey)
    },
    /**
     * @description 追加节点，不能添加新的根节点，想要添加新的根节点可以通过直接改变data做到
     * @param {Object} node 父节点
     * @param {Object} newNode 新节点
     */
    append(node, newNode) {
      if (node && node.data && node.data.children && newNode) {
        node.data.children.push(newNode)
      } else if (node && node.data && newNode) {
        this.$set(node.data, "children", [newNode])
      }
    },
    /**
     * @description 移除节点
     * @param {Object} node 待移除的节点
     */
    remove(node) {
      if (node) {
        const parent = node.parent
        const data = node.data
        const children = parent.data.children || parent.data
        const index = children.findIndex((d) => d[this.nodeKey] === data[this.nodeKey])
        children.splice(index, 1)
      }
    },
    onClick(node) {
      node.data.__edit = true
    },
    setCheckedKeys(keys, leafOnly) {
      if (this.$refs.tree) this.$refs.tree.setCheckedKeys(keys, leafOnly)
    },
    toVideoPlay() {
      if (this.$refs.tree) this.$emit("playVideo", this.$refs.tree.getCheckedNodes())
      this.$emit("onClickOutside")
    },
    toResetCheck() {
      this.keyword = ""
      if (this.$refs.tree) this.$refs.tree.setCheckedKeys([])
      this.$emit("playVideo", this.$refs.tree.getCheckedNodes())
    },
    renderContent(h, { node, data, store }) {
      if (node.level == 1) {
        return (
          <span style=' display: flex; align-items: center; font-size: 14px; width: 100%; min-width: 292px'>
            <span>
              <span>{node.label}</span>
            </span>
          </span>
        )
      } else
        return (
          <span style='flex: 1; display: flex; align-items: center; justify-content: space-between; font-size: 14px; padding-right: 8px;min-width: 292px'>
            <span>{node.label}</span>
          </span>
        )
    },
  },
  mounted() {
    this.options = Object.assign({}, this.defaultProps, this.props)
    this.tree = this.$refs.tree
  },
}
</script>

<style lang="less">
.tree-container {
  min-width: 380px;
}

#video-dropdown-input {
  height: 36px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 10px;
  background: #062939 !important;
  border: 1px solid #40d5ff !important;

  &::placeholder {
    color: #40d5ff !important;
  }

  outline: none; // 去除选中状态边框
}

.tree-wrapper {
  background: #062939 !important;
  max-height: 180px;
  overflow: auto;
  text-overflow: ellipsis;
}

.tree-none {
  font-size: 14px;
  line-height: 24px;
  color: #40d5ff !important;
  text-align: center;
  width: 100%;
  height: 50px;
  padding: 14px 0;
  box-sizing: border-box;
  min-width: 300px;
  white-space: nowrap;
}

.el-tree {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 30px;
  background: #062939 !important;
  color: #40d5ff !important;
  cursor: pointer;
  text-overflow: ellipsis;
}

.btn-wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}

.tree-container .btn {
  height: 36px;
  width: 40%;
  background: #062939 !important;
  border-radius: 0 !important;
  border: 1px solid #40d5ff !important;
  color: #40d5ff !important;
  cursor: pointer;
}
</style>
