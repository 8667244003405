<template>
  <div class="grid">
    <div class="grid-item">
      <inout-sum />
      <v-gaps :height="16" />
      <inout-detail />
    </div>
    <div class="grid-item">
      <monitor />
      <v-gaps :height="16" />
      <inout-trends />
    </div>
    <div class="grid-item">
      <in-infor />
      <v-gaps :height="16" />
      <out-infor />
    </div>
  </div>
</template>
<script>
import InoutSum from "@/view/project/components/vehicle/inout-sum/index"
import InoutDetail from "@/view/project/components/vehicle/inout-detail/index"
import Monitor from "@/view/project/components/vehicle/monitor/index"
import InoutTrends from "@/view/project/components/vehicle/inout-trends/index"
import InInfor from "@/view/project/components/vehicle/in-infor/index"
import OutInfor from "@/view/project/components/vehicle/out-infor/index"
import VGaps from "@/view/project/components/v-gaps"
import { SET_COUNT_DATA, SET_COUNT_SELECT, SET_RECORD_LIST, SET_CHART_DATA, SET_CHART_SELECT, SET_IN_INFO, SET_OUT_INFO } from "@/store"
import { mapGetters } from "vuex"
import { v4 as uuidv4 } from "uuid"
import ws from "websocketjs" //导入websocketjs
export default {
  name: "App",
  components: {
    VGaps,
    InoutSum,
    InoutDetail,
    Monitor,
    InoutTrends,
    InInfor,
    OutInfor,
  },
  data() {
    return {
      ws: null,
      project: "",
    }
  },
  computed: {
    ...mapGetters(["projectId"]),
  },
  watch: {
    projectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.project = val
          this.close()
          this.connectWs()
        }
      },
    },
  },
  methods: {
    connectWs() {
      //   this.ws = new ws(`ws://192.168.0.143:89/hero/websocket/environmentalData_${val}_${this.id}_${uuidv4().replace(/-/g, '')}`)
      // this.ws = new ws(`ws://192.168.1.230:8090/hero/websocket/carData_${this.projectId}_${uuidv4().replace(/-/g, '')}`)
      this.close()
      this.ws = new ws(`${process.env.VUE_APP_WS_PATH}/hero/websocket/carData_${this.projectId}_${uuidv4().replace(/-/g, "")}`)
      this.ws.onmessage = this.onWsmessage
      this.ws.onopen = this.openWS
      this.ws.onclose = this.closeWs
    },
    close() {
      if (this.ws != null) {
        this.ws.close()
        this.ws = null
      }
    },
    closeWs(e) {},
    onWsmessage(msg) {
      let data = {}
      if (msg.data) data = JSON.parse(msg.data)
      if (data.countData !== undefined) this.$store.dispatch(SET_COUNT_DATA, data)
      if (data.countSelectBoxData !== undefined) this.$store.dispatch(SET_COUNT_SELECT, data)
      if (data.recordList !== undefined) this.$store.dispatch(SET_RECORD_LIST, data)
      if (data.chartData !== undefined) this.$store.dispatch(SET_CHART_DATA, data)
      if (data.chartDataSelectBoxData !== undefined) this.$store.dispatch(SET_CHART_SELECT, data)
      if (data.inInfo !== undefined) this.$store.dispatch(SET_IN_INFO, data)
      if (data.outInfo !== undefined) this.$store.dispatch(SET_OUT_INFO, data)
    },
    openWS() {
      if (this.ws)
        this.ws.send(
          JSON.stringify({
            msg: this.projectId,
          })
        )
    },
  },
  beforeDestroy() {
    this.close()
  },
  destroyed() {},
}
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 616px 800px 440px;
  grid-column-gap: 16px;
}
</style>
