<template>
  <div class="v-gaps" :style="{
    height: `${height}px`
  }"></div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="less" scoped>
.v-gaps {
  flex: none;
}
</style>