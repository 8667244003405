<template>
    <div class="table-list">
        <div>
            <ul class="table hd">
                <li class="table-row">
                    <div class="table-row__cell time">考勤时间</div>
                    <div class="table-row__cell type">进/出场</div>
                    <div class="table-row__cell workName">姓名</div>
                    <div class="table-row__cell team">班组名称</div>
                    <div class="table-row__cell position">考勤设备</div>
                </li>
            </ul>
        </div>
        <vue-seamless-scroll class="recordlist"
            v-if="data && data.length > 7"
            :data="data"
            :class-option="classOption">
            <ul  class="table">
                <li class="table-row" v-for="(item, index) in data" :key="'attendance' + index">
                    <div class="table-row__cell time">{{item.atteTime}}</div>
                    <div class="table-row__cell type">{{item.type}}</div>
                    <div class="table-row__cell workName">{{item.workName}}</div>
                    <div class="table-row__cell team">
                        <marquee-text v-if="!isLoading" :text="item.teamName"></marquee-text>
                        <span>{{ item.teamName }}</span>
                    </div>
                    <div class="table-row__cell position">{{item.position}}</div>
                </li>
            </ul>
        </vue-seamless-scroll>
        <ul v-if="data && data.length < 8 && data.length >0"  class="table">
            <li class="table-row" v-for="item in data" :key="item.id">
                <div class="table-row__cell time">{{item.atteTime}}</div>
                <div class="table-row__cell type">{{item.type}}</div>
                <div class="table-row__cell workName">{{item.workName}}</div>
                <div class="table-row__cell team">
                    <marquee-text v-if="!isLoading" :text="item.teamName"></marquee-text>
                    <span>{{ item.teamName }}</span>
                </div>
                <div class="table-row__cell position">{{item.position}}</div>
            </li>
        </ul>
        <div v-else class="empty">
            暂无数据
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarqueeText from '../../marquee-text'
export default {
    components: {
        MarqueeText
    },
    props: {
        data: {
            type: Array,
            default () {
                return []
            }
        }
    },
    // computed: mapGetters(['isLoading']),
    data () {
        return {
            classOption: {
                singleHeight: 44,
            },
            isLoading: false
        }
    }
}
</script>

<style lang="less" scoped>
.recordlist {
    height: 308px;
    overflow: hidden;
}
.empty {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 285px;
    text-align: center;
}
.table-list {
    margin: 0px 16px;
}
.table {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #40D5FF;
    .table-row {
        display: flex;
        height: 44px;
        align-items: center;
        background: rgba(7, 51, 72, 1);
        padding: 0 12px;
        &:nth-child(odd) {
            background: rgba(6, 41, 57, 1);
        }
        &__cell {
            white-space: nowrap;
            overflow: hidden;
            flex: none;
            &.time {
                width: 128px;
            }
            &.type {
                width: 41px;
                padding-left: 24px;
            }
            &.workName {
                width: 50px;
                padding-left: 24px;
            }
            &.team {
                width: 125px;
                padding-left: 24px;
            }
            &.position {
                width: 135px;
                padding-left: 24px;
            }
        }
    }
    &.hd {
        .table-row {
            background: rgba(7, 51, 72, 1)!important;
            color: #fff;
        }
    }
}
</style>